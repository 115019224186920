.CancelSubscriptionFormContainer .preloadGraphics {
  display: none;
}

.CancelSubscriptionProgressForm {
  text-align: center; 
}

.CancelSubscriptionProgressForm h4 {
  font-size: 1.25em;
  margin-bottom: 0.25em;
}

.CancelSubscriptionProgressForm .featureIcon {
  margin: 1em auto 3em;
}
