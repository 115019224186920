.billing-charge-list-item .f-description {
  width: 100%;
}

.billing-charge-list-item.u-error {
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}

.billing-charge-list-item + .billing-charge-list-item.item-error {
  background-color: var(--white);
}

.ChargesPanel .Loading {
  margin: 2em auto;
}

.ChargesPanel .invoiceTable {
  border-collapse: collapse;
  padding: 1px;
  text-align: right;
  font-size: 14px;
}

.ChargesPanel .invoiceTable .qty {
  text-align: center;
  min-width: 50px;
}

.ChargesPanel .invoiceTable tr {
  color: var(--grey-700);
  border-bottom: 1px solid #f0f0f0;
}

.ChargesPanel .invoiceTable thead tr th {
  font-size: 11px;
  color: var(--grey-400);
  font-weight: lighter;
}

.ChargesPanel .invoiceTable tbody tr td,
.ChargesPanel .invoiceTable tbody tr th {
  padding: 10px 5px !important;
  white-space: nowrap;
}

.ChargesPanel .invoiceTable tr td:first-child,
.ChargesPanel .invoiceTable tr th:first-child {
  text-align: left;
}

.ChargesPanel .invoiceTable .products .deliveryTag {
  margin-left: 16px;
}

.ChargesPanel .invoiceTable .taxes tr td:first-child,
.ChargesPanel .invoiceTable .total tr td:first-child {
  text-align: right;
}

.ChargesPanel .invoiceTable .total {
  font-family: "AbelPro-Bold";
}

.ChargesPanel .invoiceTable .total tr {
  border-bottom: none;
}