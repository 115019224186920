@import "../../shared/styles/mq.scss";

.module {
  height: 1px;
  width: 100%;
  border-bottom: solid 1px var(--grey-200);

  @include mq_narrow {
    margin: 16px 0 40px 0;
  }
}

.sections {
  margin: var(--sg-spacing-biggest) 0;

  @include mq_narrow {
    margin: var(--sg-spacing-small) 0 calc(var(--sg-spacing-small) * 2) 0;
  }
}
.big {
  margin: var(--sg-spacing-big) 0;
}
.normal {
  margin: var(--sg-spacing-normal) 0;
}
.small {
  margin: var(--sg-spacing-small) 0;
}
.smaller {
  margin: var(--sg-spacing-smaller) 0;
}
.tiny {
  margin: var(--sg-spacing-tiny) 0;
}

.firstOrLast {
  border-bottom: none;
  margin-bottom: 0;
}

.splitLine {
  display: flex;
  gap: 18px;

  align-items: center;
}