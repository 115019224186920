.module {
  --flag-height-smaller: 15px;
  --flag-height-smaller-plus-gap: 16.5px;
  --flag-height-small: 25px;
  --flag-height-small-plus-gap: 27.5px;
  --flag-height-big: 30px;
  --flag-height-big-plus-gap: 33px;

  background: url(/assets/flags/jurisdiction-flags.png) no-repeat;
  display: inline-block;
  position: relative;
  background-size: 100% auto;
  background-size: cover;
  background-position-x: 0;

  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px rgba(24, 24, 24, 16);
}

.smaller {
  width: 21px;
  height: var(--flag-height-smaller);
}

.small {
  width: 35px;
  height: var(--flag-height-small);
}

.big {
  width: 42px;
  height: var(--flag-height-big);
}

:global(.alpine-water-gmbh) {
  &.smaller {
    background-position-y: calc(-1 * var(--flag-height-smaller-plus-gap));
  }
  &.small {
    background-position-y: calc(-1 * var(--flag-height-small-plus-gap));
  }
  &.big {
    background-position-y: calc(-1 * var(--flag-height-big-plus-gap));
  }
}

:global(.alpine-water-schweiz-gmbh) {
  &.smaller {
    background-position-y: calc(-2 * var(--flag-height-smaller-plus-gap));
  }
  &.small {
    background-position-y: calc(-2 * var(--flag-height-small-plus-gap));
  }
  &.big {
    background-position-y: calc(-2 * var(--flag-height-big-plus-gap));
  }
}

:global(.hallstein-water-canada-inc) {
  &.smaller {
    background-position-y: calc(-3 * var(--flag-height-smaller-plus-gap));
  }
  &.small {
    background-position-y: calc(-3 * var(--flag-height-small-plus-gap));
  }
  &.big {
    background-position-y: calc(-3 * var(--flag-height-big-plus-gap));
  }
}

:global(.alpine-water-uk) {
  &.smaller {
    background-position-y: calc(-4 * var(--flag-height-smaller-plus-gap));
  }
  &.small {
    background-position-y: calc(-4 * var(--flag-height-small-plus-gap));
  }
  &.big {
    background-position-y: calc(-4 * var(--flag-height-big-plus-gap));
  }
}

:global(.hallstein-water-sg) {
  &.smaller {
    background-position-y: calc(-5 * var(--flag-height-smaller-plus-gap));
  }
  &.small {
    background-position-y: calc(-5 * var(--flag-height-small-plus-gap));
  }
  &.big {
    background-position-y: calc(-5 * var(--flag-height-big-plus-gap));
  }
}