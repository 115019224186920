.module {
  cursor: pointer;
  // In case the icon is inside a flex container
  flex-shrink: 0;
  flex-grow: 0;
}

.inFlow {
  vertical-align: sub;
  margin: 0 12px;
  margin-bottom: 3px;
}

.notClickable {
  cursor: unset;
}