@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";

.ShopPage {
  @include mq_narrow {
    .divider {
      margin: var(--sg-spacing-small) 0 0 0;
    }
  }

  .products {
    padding-bottom: var(--sg-spacing-normal);
  }

  h1 {
    @include sgTextLarge;
    color: var(--primary-blue-700);
    text-align: center;
    margin: 5px 0 30px 0;
  }

  .alerts {
    @include mq_notNarrow {
      margin-top: calc(-1 * var(--sg-spacing-tiny));
    }
    
    div.Tip {
      margin-bottom: var(--sg-spacing-small);
    }
  }

  button.backToShop {
    @include sgSubpanelTitle;
    color: var(--secondary-gold-600);

    svg {
      vertical-align: middle;
      margin-bottom: 2px;
      margin-right: 7px;
      .svg-shape {
        fill: var(--secondary-gold-600);
      }
    }
  }
}

.ShopPage .grid {
  display: grid;
  gap: var(--sg-spacing-small);
}
.ShopPage .water .grid {
  grid-template-columns: 1fr 1fr;

  @include mq_narrow {
    grid-template-columns: 1fr;
  }
}
.ShopPage .nonWater .grid {
  grid-template-columns: 1fr 1fr 1fr;

  @include mediaQueryMaxWidth(1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @include mediaQueryMaxWidth(600px) {
    grid-template-columns: 1fr;
  }
}
