
@import "./sgForm.scss";

.module {
  position: relative;
  text-align: left;
  border-radius: 4px;

  @include sgInput;
  height: var(--select-height) !important;
  padding: 0;

  .fullInputArea {
    cursor: pointer;
  }
}

.module.normalSize {
  --select-height: 56px;
  --item-height: 57px;

  .fullInputArea {
    @include sgInputPadding;
    padding-top: 18px;

    svg.icon {
      // Move arrow a bit
      margin-top: -4px;
    }
  }

  .item {
    @include sgFontNormal;
    font-size: 18px;
    @include sgInputLineHeight;
    @include sgInputPadding;
    height: var(--item-height);
  }
}
.module.smallSize {
  // Used in header (country and locale selector)
  --select-height: 40px;
  --item-height: 57px;

  .fullInputArea {
    // Input. Smaller padding to have a height of 24px
    padding: 8px 10px;

    .value {
      @include sgFontBold;
      @include sgTextSmall;
      margin: 0;
      height: 24px;

      span.compensateScaling {
        // Even less space between flag and text
        margin-left: -49px !important;
      }
    }
  }

  .item {
    font-size: 16px;
    @include sgInputLineHeight;
    @include sgInputPadding;
    height: var(--item-height);
  }
}
.module.smallerSize {
  // Used in footer for locale switcher
  --select-height: 24px;
  --item-height: 28px;

  border-color: var(--grey-200);

  .fullInputArea {
    // Input. Smaller padding to have a height of 24px
    padding: 3px 0 2px 11px;

    svg.icon {
      // Move arrow a bit
      margin-top: -5px;
    }
    .value {
      @include sgTextFootnote;
      color: var(--grey-400);
    }
  }

  .item {
    @include sgTextFootnote;
    height: var(--item-height);
    padding: 7px 11px;
  }
}

.control {
  display: flex;
  align-items: center;
}
.noControl .icon {
  display: none;
}

// TODO work around placeholder not working on select elements
.control::placeholder {
  @include sgInputPlaceholder;
}

.module.focus,
.module:hover,
.module.selecting {
  @include sgInputAccentBorder;

  .fullInputArea {
    .icon :global(.svg-shape) {
      fill: var(--primary-blue-700);
    }
    .value {
      color: var(--primary-blue-700);
    }
  }
}

.module.focus {
  @include sgInputAccentBorder;
  @include sgInputAccentOutline;
  @include sgInputAccentColor;

  &.withOptionnalInput input {
    border-left-color: var(--primary-blue-700);
  }
}

.module.error {
  @include sgInputErrorBorder;

  &.withOptionnalInput input {
    border-left-color: var(--accent-red-600);
  }
}
.module.error.focus {
  @include sgInputErrorOutline;
}

.module.selecting {
  @include sgInputAccentBorder;
  @include sgInputAccentOutline;
  @include sgInputAccentColor;

  &.descending:not(.ascending) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.ascending:not(.descending) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

// Changes specifics to dark backgrounds
.module.darkBackground {
  color: var(--white); // For label and help/error texts

  @include sgInputTransparent;
  padding: 0;

  &:hover {
    border-color: inherit; // No border on hover for dark backgrounds
  }

  &.focus {
    @include sgInputAccentBorder;
    @include sgInputAccentOutlineTransparent;
  }
  &.error.focus {
    @include sgInputErrorOutlineTransparent;
  }
  &.error {
    @include sgInputErrorBorder;
  }

  .control.selecting {
    @include sgInputAccentBorder;
    @include sgInputAccentOutlineTransparent;
    @include sgInputAccentColor;
  }
  .dropdown {
    @include sgInputAccentBorder;
    @include sgInputAccentOutlineTransparent;
    @include sgInputAccentColor;
  }
}

.value {
  display: flex;
  gap: 8px;
  color: var(--primary-blue-700);
  width: 100%;
}

.icon {
  align-items: center;
  justify-content: center;

  :global(.svg-shape) {
    fill: var(--grey-400);
  }
}

.curtain {
  position: fixed;
  z-index: 500;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.dropdown {
  position: absolute;
  color: var(--primary-blue-700);
  
  @include sgInputAccentBorder;
  @include sgInputAccentOutline;
  @include sgInputAccentColor;

  background-color: var(--white);
  overflow-y: auto;

  .descending & {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .descending:not(.ascending) & {
    // Override box-shadow to avoid top-box-shadow above value
    // Shadow colour is defined in the sgInputAccentOutline
    box-shadow:
      -5px 0px 0 0px var(--primary-blue-200),
      5px 0px 0 var(--primary-blue-200),
      -5px 5px 0 0px var(--primary-blue-200),
      5px 5px 0 var(--primary-blue-200);
  }
  .ascending & {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .ascending:not(.descending) & {
    // Override box-shadow to avoid bottom-box-shadow abpve value
    // Shadow colour is defined in the sgInputAccentOutline
    box-shadow:
      -5px 0px 0 0px var(--primary-blue-200),
      5px 0px 0 var(--primary-blue-200),
      -5px -5px 0 0px var(--primary-blue-200),
      5px -5px 0 var(--primary-blue-200);
  }
}

.item {
  --items-border-color: var(--primary-blue-700);
  cursor: pointer;
  border-bottom: 1px solid var(--items-border-color);
  
  color: var(--primary-blue-700);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.centered {
    // used in locale switcher within header
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dropdown .item:hover {
  background-color: var(--primary-blue-100);
}

.item.active,
.item:active {
  background-color: var(--primary-blue-400);
}

.item:last-child {
  border-bottom: none;
}

.item.preferredValue {
  background-color: var(--primary-blue-200);
  
  & + .item.normalValue {
    position: relative;
    &::before {
      position: absolute;
      width: 100%;
      content: "";
      left: 0px;
      top: 0px;
      border-top: 3px solid var(--items-border-color);
    }
  }
}

.module.withOptionnalInput {
  // Input area divided between the control on the left, and the optional input
  position: relative;
  --control-width: 125px; // Enough space for flag and short title

  .value {
    // Control width a lot smaller
    width: 35px;
  }

  svg {
    // Bit smaller arrow icon
    margin-top: -2px;
    width: 20px;
    height: 20px;
  }

  .fullInputArea {
    cursor: inherit;
  }

  .control {
    cursor: pointer;
    width: calc(var(--control-width) - 16px); // Minus parent's left padding
    padding-right: var(--sg-spacing-tiny);
    height: 20px; // Otherwise too big because of scaling
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    display: flex;

    .value {
      flex-grow: 1;
      white-space: nowrap;

      span.compensateScaling {
        margin-top: 2px; // align with input as inputs are all off centered
        margin-left: -52px !important;
        display: inline-block;
        position: absolute; // Fixes safari bug where value was not showing
        width: 40px;
        text-align: center;
      }
    }
  }

  input {
    @include sgInput;
    width: calc(100% - var(--control-width));
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 1px solid var(--grey-200);
    height: 54px;

    &::placeholder {
      @include sgInputPlaceholder;
    }
  }
}

.module.withSpritePath {
  .control {
    .value {
      // width: 30px;
      height: 20px;
      display: inline-block;
      
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      margin-top: -1px; // Center flag

      .image {
        display: inline-block;
        vertical-align: top;
      }
      span.compensateScaling {
        margin-left: -40px;
      }
    }
  }

  .image {
    // Width and height are defined in the FlagsSprites, but in retina size
    // So we need to downscale them here so they are 28x20 like the design
    transform: scale(0.333);
    transform-origin: top left;
  }

  .item {
    // force height as image downscaling fucks up the item size as well
    height: 56px;
    position: relative;
    padding-left: 60px; // leave space for image on the left

    .image {
      // Compensate items padding
      position: absolute;
      margin-top: -2px;
      margin-left: -42px;
    }
  }
}

.module.withSpritePath.withOptionnalInput .value {
  color: var(--grey-900);
}
