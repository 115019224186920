@import "../styles/sg.scss";
@import "../styles/mq.scss";


.module {
  display: flex;
  align-items: center;
  button {
    margin-bottom: 0 !important; // Useful in website as buttons have margin-bottom's
  }

  @include mq_narrow {
    // Wrap and full width button
    flex-wrap: wrap;

    :global(.info) {
      flex-basis: 100%;
      margin-bottom: var(--sg-spacing-small);
    }
    
    button {
      flex-basis: 100%;
    }
  }

  @include mq_notNarrow {
    // Change order + spacing
    button {
      margin-right: var(--sg-spacing-small);
      order: 1;
    }
    :global(.info) {
      // margin-bottom: 5px;
      order: 2;
    }
  }

  :global(.price) {
    @include sgFontBold;
  }
}
