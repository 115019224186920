@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.module {
  border-radius: 4px;
  background-color: var(--accent-red-200);
  padding: var(--sg-spacing-small);
  @include mq_notNarrow {
    padding: var(--sg-spacing-normal) var(--sg-spacing-big);
  }

  width: 100%;

  color: var(--accent-red-600);
  p {
    color: var(--accent-red-600) !important;
  }
}

.module .message {
  @include sgTextSmall;
  margin-bottom: 0;

  svg {
    @include mq_narrow {
      margin-bottom: var(--sg-spacing-tiny);
    }
  }

  p {
    margin-bottom: 0;

    & + p {
      margin-bottom: var(--sg-spacing-normal);
    }
  }
}

.message + .actions {
  margin-top: 12px;
  @include mq_notNarrow {
    margin-top: var(--sg-spacing-small);
  }

  button + button {
    @include mq_narrow {
      margin-top: var(--sg-spacing-small);
    }
    @include mq_notNarrow {
      margin-left: var(--sg-spacing-small);
    }
  }
}

.clickDetails {
  cursor: pointer;
  color: var(--primary-blue-700);
}

.title {
  @include sgTextLead;
  margin-bottom: var(--sg-spacing-tiny);
  display: none;
}

.withTitle .title {
  display: block;
}