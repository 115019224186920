@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";


.module {
  .inputAndButton {
    display: flex;
  }

  :global(.field) {
    flex: 1 1 100%;
    margin: 0 var(--sg-spacing-small) 0 0 !important;
  }
}

.small {
  input {
    @include sgTextSmall(false);
    padding-top: 2px;
    padding-bottom: 0px;
    height: 40px;
    line-height: 40px;
  }

  .submitButton {
    margin-top: 22px;
    width: 140px;
  }
}

.module.inline .inputAndButton {
  flex-direction: row;
  align-items: start;
  gap: var(--sg-spacing-small);

  .submitButton {
    margin: 0;
    min-width: 110px;
    width: initial;
  }
}

@include mediaQueryMaxWidth(1400px) {
  .module {
    .inputAndButton {
      flex-direction: column;
    }
    :global(.field) {
      margin: 0 !important;
    }
    .submitButton {
      margin-top: var(--sg-spacing-tiny);
      width: 100%;
    }
  }
}