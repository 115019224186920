@import "../shared/styles/mq.scss";

.module {
  // Default text style in this page
  line-height: 1.67;
  color: var(--grey-900);
}

.panels {
  --padding-inline: var(--sg-spacing-normal);
  @include mq_narrow {
    --padding-inline: 0;
  }

  max-width: calc(1250px + (2 * var(--padding-inline)));
  padding-inline: var(--padding-inline);
  margin-inline: auto;
}