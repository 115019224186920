.module {
  user-select: none;
  opacity: 0.5;
}

:root {
  // Colors
  --light-state-on: var(--accent-green-200);
  --dark-state-on: var(--accent-green-600);

  --light-state-off: var(--grey-200);
  --dark-state-off: var(--grey-300);

  --light-undecided: var(--grey-400);
  --dark-undecided: var(--grey-100);

  // Sizes
  --whole-width: 46px;
  --button-radius: 20px;
}

.loadingBg {
  width: 36px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg {
  position: relative;
  width: var(--whole-width);
  height: 24px;
  border: 1px solid var(--dark-undecided);
  background-color: var(--light-undecided);
  border-radius: 20px;
  transition: background-color 0.3s ease-in-out;
}

.submitting {
  filter: brightness(90%);
}

.toggle {
  position: absolute;
  top: 1px;
  left: calc(((var(--whole-width) - var(--button-radius)) / 2) - 1px); // Undecided => middle
  background-color: var(--dark-undecided);
  width: var(--button-radius);
  height: var(--button-radius);
  border-radius: var(--button-radius);
  transition: left 0.2s ease-in-out;
}

.active {
  cursor: pointer;
  opacity: 1;
}

.on {
  .bg {
    background-color: var(--light-state-on);
    border-color: var(--dark-state-on);
  }

  .toggle {
    left: 22px;
    background-color: var(--dark-state-on);
  }
} 

.off {
  .bg {
    background-color: var(--light-state-off);
    border-color: var(--dark-state-off);
  }

  .toggle {  
    left: 2px;
    background-color: var(--dark-state-off);
  }
}