@import "../styles/mq.scss";
@import "../styles/sg.scss";


.module {
  display: flex;
  flex-direction: column;

  --default-gap: var(--sg-spacing-small);
  @include mq_notNarrow {
    --default-gap: var(--sg-spacing-normal);
  }
  @include putInCenter;

  border-radius: 4px;
  
  background-color: var(--grey-100);
  
  @include mq_notNarrow {
    flex: 1 1 var(--flex-basis-water);
  }
  &:global(.dispensersTag) {
    // Dispensers can be displayed in thinner boxes,
    // allowing three in a row if the viewport is wide enough.
    @include mediaQueryMaxWidth(700px) {
      // One dispenser per line
      flex: 0 1 100%;
    }
    @include mediaQueryBetween(700px, 1100px) {
      // Same as water: 2 per line
      flex: 0 1 var(--flex-basis-water);
    }
    @include mediaQueryMinWidth(1200px) {
      // 3 per line
      flex: 0 1 var(--flex-basis-dispensers);
    }
  
  }
  white-space: normal;

  .images {
    padding: var(--default-gap) var(--default-gap) 0 var(--default-gap);

    // If we don't want empty space around the image (so no borders either, and
    // no border radius below).
    &.noSpaceAround {
      padding: 0;

      div {
        // Overwrite PrismicImages rules:
        border: none !important;

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    width: 100%;
  }
  &.listing .images div {
    // Overwrite PrismicImages rules:
    --image-padding: 0px;
  }

  & > main {
    padding: var(--default-gap);

    flex-grow: 1;
    display: flex;
    flex-direction: column;

    header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: var(--sg-spacing-tiny);

      h2 {
        @include sgFontNormal;
        @include sgTextLarge;
        text-transform: none;
        letter-spacing: -1.16px;
        color: var(--primary-blue-700);
        margin: 0;
      }
      .subtitle {
        @include sgTextSmall;
        margin-bottom: 0;
        color: var(--grey-700);
      }
    }

    section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text {
        flex-grow: 1;
        
        margin-bottom: var(--sg-spacing-tiny);
        @include mq_notNarrow {
          margin-bottom: var(--sg-spacing-small);
        }

        p {
          margin: 0;

          & + p {
            margin-top: var(--sg-spacing-tiny);
          }
        }
      }

      .keywords {
        --spacing: 4px;
        @include mq_notNarrow {
          --spacing: 8px;
        }

        // Useful when tags wrap
        line-height: calc(24px + var(--spacing));
        span {
          vertical-align: top; // as line height is > to status tag height
          margin-right: var(--spacing);
        }

        margin-bottom: 12px; // + spacing provided by the line-height
      }

      .additionnalInfo {
        display: none;
      }
    }
  }
}

// In wide mode the image is on top but we have lots of horizontal space for
// the text and the options below, so we limit that a bit
:global(.productWideMode).module {
  main {
    width: 100%;
    max-width: 700px;

    header h2 {
      font-size: 45px;
    }
  }
}

.module:global(.dispensersTag) {
  header h2 {
    @include sgTextLead;
    margin: 0;
  }
}

// In wider mode, the image is displayed on the right, and the padding is much
// bigger. This is primarily used on specific product pages.
:global(.productWiderMode).module,
:global(.productWidestMode).module {
    padding: 50px 82px; // Minimum vertical padding
  min-height: 80vh; // But we want it to take most of the above the fold space
  @include mediaQueryMinHeight(1000px) {
    min-height: 800px;
  }
  // Display image on the right
  display: flex;
  flex-direction: row-reverse;
  gap: var(--sg-spacing-biggest);

  .images + main {
    margin-top: 0; // As images are on the right
  }

  & > main {
    flex-shrink: 0;
    flex-basis: 55%;

    header h2 {
      @include sgTitle3;
      text-transform: none;
    }

    .additionnalInfo {
      display: block;
    }
  }
}

.option {
  height: 56px;
  padding: 0 var(--sg-spacing-small);
  border-radius: 4px;
  margin-bottom: var(--sg-spacing-small);

  border: solid 1px var(--grey-200);
  background-color: var(--white);

  .optionInner {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;

    .description {
      flex-grow: 1;
      white-space: wrap;

      .title {
        @include sgFontBold;
        @include sgTextNormal;
        line-height: 17px;
        margin: 8px 0 0 0;
        color: var(--primary-blue-700);
      }
      .subtitle {
        margin: 5px 0;
        @include sgSubpanelTitle;
        line-height: 15px;
      }
    }

    .price {
      flex: 0 1;
      white-space: nowrap;
      @include sgFontBold;
      @include sgTextNormal;
      margin: 0;
      color: var(--grey-900);
    }
  }
}
// Specifics for thin mode
@include mq_narrow {
  .option {
    height: auto;
    padding: var(--sg-spacing-tiny) var(--sg-spacing-small);
    cursor: pointer;

    .optionInner {
      height: auto;
      flex-wrap: wrap;

      .description {
        width: 100%;
      }
      .price {
        margin-top: -5px;
      }
    }
  }
}
