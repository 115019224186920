.ProductTable {
  line-height: 1.2em;  
}

.ProductTable .header {
  font-size: 14px;  
  padding: 15px 0px 5px;
}

.ProductTable .header .title {
  font-family: "AbelPro-Bold";
  display: inline-block;
}

.ProductTable .header .frequency {
  display: inline-block;
  margin-left: 0.5em;
}

.ProductTable.u-with-subscription .CartList {
  margin-bottom: 1em;
}

.ProductTable .Table.mode-table thead td {
  padding: 0px 0px;
}
.ProductTable .Table.mode-table tbody td {
  padding: 10px 0px;
}

.ProductTable .f-subtotal {
  text-align: right;
  width: 75px;
}
.ProductTable .f-total {
  text-align: right;
  width: 20%;
  min-width: 75px;
}

.ProductTable .f-price,
.ProductTable .f-quantity,
.ProductTable .f-button {
  text-align: center;
  width: 20%;
  min-width: 75px;
}