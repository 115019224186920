@import "./styles/sg.scss";

.Table {
  width: 100%;
  font-size: 13px;
  text-align: left;
}

.Table table {
  width: 100%;
  border-collapse: collapse;
}

.Table thead {
  white-space: nowrap;

  @include sgSubpanelTitle;
}

.Table ul {
  list-style-type: none;
  padding-left: 0px;
  margin: 0px;
}


.Table .item {
  cursor: pointer;
  background-color: #fff;
  width: 100%;
  height: 40px;
  margin-bottom: 1px;
  user-select: none;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

.Table.mode-list .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.Table.mode-table td {
  // padding: 5px 20px;
}

.Table.mode-table tbody tr td {
  padding-bottom: var(--sg-spacing-smaller);
  border-bottom: 1px solid #eee;
}
.Table.mode-table tbody tr:first-child td {
  padding-top: var(--sg-spacing-smaller);
}

.Table.mode-table td.u-nowrap {
  white-space: nowrap;
}

.Table .item.u-selected {
  border-color: var(--primary-blue-700);
  margin: 5px 0px;
  box-shadow: 0px 3px 3px rgba(100,100,100,0.1);
}

.Table .item .u-selectable {
  user-select: all;
}


.shipping-list-item .field {
  flex: 1 1 33%;
}

.shipping-list-item .f-availability {
  text-align: center;
}
.shipping-list-item .f-warehouse {
  text-align: right;
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;  
}
