@import "./styles/sg.scss";

.module {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4em;

  .localeSwitcher {
    margin-bottom: var(--sg-spacing-small);
  }

  button {
    letter-spacing: 0.083em;
    text-transform: uppercase;

    &.smaller {
      @include sgFontBold;
      cursor: pointer;
      font-size: 11px;
      text-decoration: none;
      outline: none;
      border: none;
    }
  }
}