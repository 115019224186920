
@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";

.module {
  --testimonialsSideDimension: 600px;

  width: 100%;
  min-height: 100vh;
  
  background-color: var(--primary-blue-800);
  color: var(--white);
}

.bgImage {
  @include sgBackgroundWholePage;

  &.bgImageLoaded {
    @include sgBackgroundWholePageLoaded;
  }
}

// Container for both the forms on the left and the testimonials on the right
.parentContainer {
  z-index: 0; // Because of absolute LoadBackgroundImage
  &.withTestimonials {
    width: 1440px;
  }
  max-width: 100vw;
  margin: auto;
}

.logo {
  display: block;
  margin: auto;
  margin-bottom: var(--sg-spacing-normal);
}

.container {
  padding: 0;
  width: 100%;
  @include mq_notNarrow {
    max-width: 430px;
  }
  margin: 90px 0;

  .withTestimonials & {
    margin-left: 164px; // cf. design
  }
}

.testimonials {
  display: none;
}
@include mediaQueryMinWidth(1100px) {
  .testimonials {
    display: block;
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 4px;
    padding: 20px 25px;

    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.31);
    border: solid 1px rgba(255, 255, 255, 0.12);
    background-color: rgba(138, 153, 167, 0.274);

    width: var(--testimonialsSideDimension);
    height: var(--testimonialsSideDimension);
    
    // Sticky centered vertically
    position: fixed;
    transform: translateX(800px); // cf. design
    top: calc(50% - (var(--testimonialsSideDimension) / 2)); // Vertical alignment

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
    }

    // We need this inner div so the Testimonials component can use
    // it as a basis for its position.
    .testimonialsInner {
      position: relative;
      height: 100%;
      
      overflow: hidden;

      // Tighter quotes
      :global(p.quote) {
        font-size: 20px;
        line-height: 28px;
        margin-top: 1em;
      }
    }
  }
}

.preloadGraphics {
  display: none;
}