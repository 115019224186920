
@import "./sgForm.scss";

.module {
  color: var(--grey-700);
  // Margin bottom: normal so inputs are not cramped on top of one another
  @include sgMarginBottomNormal;

  position: relative;
}

.rightIcon {
  position: absolute;
  top: 38px;
  right: var(--sg-spacing-small);
}

.label {
  display: block;
  text-align: left;
  @include sgFontBold;
  @include sgTextTiny;
}

.input {
  @include sgInput;
}

.input::placeholder {
  @include sgInputPlaceholder;
}

.input:hover {
  @include sgInputAccentBorder;
}

.input:focus {
  @include sgInputAccentBorder;
  @include sgInputAccentOutline;
  @include sgInputAccentColor;
}

.input.error {
  @include sgInputErrorBorder;
}
.input.error:focus {
  @include sgInputErrorOutline;
}

.inactive {
  pointer-events: none;
}

// Changes specifics to dark backgrounds
.module.darkBackground {
  color: var(--white); // For label and help/error texts

  .input {
    border-color: transparent;
  }
  .input:hover {
    border-color: transparent !important;
  }
  .input:focus {
    @include sgInputAccentBorder;
    @include sgInputAccentOutlineTransparent;
  }
  .input.error:focus {
    @include sgInputErrorOutlineTransparent;
  }
  .input.error {
    @include sgInputErrorBorder;
  }
}

.hasComment {
  margin-bottom: 4px !important;

  .comment {
    margin-top: 6px;
    @include sgFontNormal;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
  }
}