@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";

.module {
  width: 100%;
}

.name {
  @include sgFontBold;
  display: inline;
}

.type {
  display: none;
}

.showType {
  .type {
    margin-left: var(--sg-spacing-tiny);
    @include sgFontBold;
    display: inline;
    text-transform: uppercase;
    background-color: var(--grey-200);
    border-radius: 6px;
    padding: 3px 8px 2px 8px;
    font-size: 10px;
    color: var(--grey-400);
    vertical-align: top;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
}