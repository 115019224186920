
@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";

.module {
  width: 100%;
  min-height: 100vh;
  
  background-color: var(--primary-blue-800);
  color: var(--white);
}

.bgImage {
  @include sgBackgroundWholePage;

  &.bgImageLoaded {
    @include sgBackgroundWholePageLoaded;
  }
}

.container {
  z-index: 0; // Because of absolute LoadBackgroundImage
  width: 450px;
  padding: 90px 24px;
}
@include mq_narrow {
  .container {
    width: 100%;
  }
}
