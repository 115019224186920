
.Calendar {
  --calendar-border: 1px solid var(--grey-200);
}

.Calendar .row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.Calendar .row-middle {
  align-items: center;
}

.Calendar .col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.Calendar .col-start {
  justify-content: flex-start;
  text-align: left;
}

.Calendar .col-center {
  justify-content: center;
  text-align: center;
}

.Calendar .col-end {
  justify-content: flex-end;
  text-align: right;
}

.Calendar {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: var(--calendar-border);

  padding: var(--sg-spacing-normal);
  padding-top: 0;
}

.Calendar .header {
  padding: 1.5em 0;

  color: var(--grey-900);
  font-size: 16px;
  line-height: 1.38;
}

.Calendar .header .icon {
  border-radius: 4px; 
  border: 1px solid var(--grey-200);

  &.left {
    padding-right: 1px;
  }
  &.right {
    padding-left: 1px;
  }
}

.Calendar .header .icon:hover {
  transition: .25s ease-out;
  color: #01B7CE;
}


.Calendar .days {
  color: var(--grey-400);
  font-size: 14px;
  line-height: 1.33;
  padding: .75em 0;
}

.Calendar .body .cell {
  position: relative;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.25s ease-out;
}

.Calendar .body .selected {
  color: var(--white);
  background-color: var(--primary-blue-700);
}

.Calendar .body .row {
  // by default: border bottom and left on all cells
  .cell {
    border-left: var(--calendar-border);
    border-bottom: var(--calendar-border);

    &:last-child {
      border-right: var(--calendar-border);
    }
  }
  // For first row only, we need a border top as well
  &:first-child .cell {
    border-top: var(--calendar-border);

    // Except for dates from the month prior
    &.differentMonth {
      border-top: none;
      border-left: none;
    }
  }
  // For last row, we need to disable bottom and margins for dates
  // from the next month
  &:last-child {
    .cell.differentMonth {
      border-bottom: none;
      border-left: none;
      border-right: none;
    }
    :not(.cell.differentMonth) + .cell.differentMonth {
      border-left: var(--calendar-border);
    }
  }
}

.Calendar .body .cell {
  padding: 2px;
  display: flex;
  align-items: flex-end;
}

.Calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700;
}

.Calendar .body .cell.unavailable {
  /* holiday */
  background-color: var(--grey-100);
  pointer-events: none;
}

.Calendar .body .cell .event {
  font-size: 10px;
  line-height: 10px;
  color: #555;
}

.Calendar .body .row .differentMonth {
  pointer-events: none;

  // Hide when not in current month
  background-color: var(--white);
  color: var(--white);
  // border: none;
}

.Calendar.u-weekends-disabled .weekday-1,
.Calendar.u-weekends-disabled .weekday-7 {
  color: var(--grey-400);
  pointer-events: none;
}

.Calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}