@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";

.module p {
  color: var(--grey-900);
}

.module {
  --accounts-page-max-width: 1250px;

  :global(.discount-banner) {
    max-width: var(--accounts-page-max-width);
    margin-bottom: var(--sg-spacing-normal);
  }
}

.panels {
  --padding-inline: var(--sg-spacing-normal);
  @include mq_narrow {
    --padding-inline: 0;
  }

  max-width: calc(var(--accounts-page-max-width) + (2 * var(--padding-inline)));
  padding-inline: var(--padding-inline);
  margin-inline: auto;

  margin-bottom: 80px;
}

.priceIncreaseBanner {
  background-color: #777;
  border-radius: 4px;
  padding: var(--sg-spacing-normal);
  text-align: center;

  p {
    color: var(--white);
  }

  button {
    width: 280px;
    max-width: 100%;
  }
}


.anchor {
  // Because the header is sticky we need to shift that much more
  --account-anchor-shift: 150px;

  padding-top: var(--account-anchor-shift);
  margin-top: calc(-1 * var(--account-anchor-shift));
}

.inner {
  padding: 0 var(--sg-spacing-normal);
}

.mq-sm .inner {
  padding: 15px;
}

.ProductList {
  list-style: none;
  padding-left: 0px; 
  border-top: 1px solid #ddd;
}

.ProductList .item {
  padding: 5px 0px;
  border-bottom: 1px solid #ddd;
}

.ProductList .quantity {
  display: inline-block;
  width: 35px;
  font-family: "AbelPro-Bold";
}
.ProductList .quantity:after {
  content: "x";
}

.helptext {
  color: #777;
  font-size: 13px;
  line-height: 1.5em; 
}

.alerts {
  margin-top: calc(-1 * var(--sg-spacing-small));

  @include mq_narrow {
    margin-top: calc(-1 * var(--sg-spacing-tiny));
  }
}