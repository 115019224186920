

.buttons {
  margin-top: var(--sg-spacing-tiny);
}

.cardAdded {
  display: flex;
  padding: var(--sg-spacing-big);
  justify-content: center;
}