@import "../styles/sg.scss";
@import "../styles/mq.scss";

.module {
  --selected-color: var(--secondary-gold-600);
  border-bottom: 1px solid var(--grey-100);

  padding: 0;

  .submenuInner {
    flex-wrap: nowrap;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    height: var(--header-submenu-height);
    @include horizontalScroll;
    width: fit-content;
    max-width: 100%;
    margin: auto;
    margin-bottom: -1px; // So the borders coincide with the header border

    a {
      @include sgTextSmall;
      margin: 0 var(--sg-spacing-big) 0 var(--sg-spacing-big);
      display: inline-block;
      text-decoration: none;
      white-space: nowrap;
      padding: 14px 10px;
      border-bottom: 1px solid transparent;
  
      color: var(--grey-700);
      &:global(.selected) {
        color: var(--selected-color);
  
        border-bottom-color: var(--selected-color);
        border-radius: 0;
      }
    }

    .divider {
      --divider-height: 40px;
      --height-diff: calc(var(--header-submenu-height) - var(--divider-height));

      display: inline-block;
      height: var(--divider-height);
      // Bottom margin dependent on submenu height
      margin-bottom: calc(var(--height-diff) / 2);
      border-left: 1px solid var(--grey-100);
    }
  }
}

.module.spaceEvenly .submenuInner {
  width: 100%;
  justify-content: space-evenly;
}