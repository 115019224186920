@import "../../../shared/styles/sg.scss";
@import "../../../shared/styles/mq.scss";

.module {
  &.visible {
    display: block;
  }
  &.invisible {
    display: none;
  }

  p.info {
    margin-top: 14px;
    color: var(--grey-500);
  }
}