@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";

.module {
  --padding-top: 0px;
  --subpanel-title-height: 45px;

  // panels are too packed for viewports below 800px
  // Redefine narrow-cutoff but unfortunately this doesn't cascade
  $narrow-cutoff: 850px !global;

  --anchor-offset: calc(var(--header-height) + 32px); // Mobile
  @include mq_notNarrow {
    --anchor-offset: calc(var(--header-height) + (var(--has-desktop-header) * 92px) + (var(--has-mobile-header) * 92px));
  }

  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: var(--sg-spacing-normal);

  &.fullWidthSubpanels {
    flex-wrap: wrap;

    .subpanels {
      flex: 1 0 100%;

      .actions {
        margin-top: 0;
      }
    }
  }

  // padding: 0px 80px;

  @include mediaQueryMaxWidth(1000px) {
    padding: 0 40px;
  }
  @include mq_narrow {
    align-items: stretch;
    flex-wrap: wrap;
    padding: 0;
  }

  padding-top: calc(var(--padding-top) + var(--anchor-offset)) !important;
  margin-top: calc(-1 * var(--anchor-offset));
}

.labels {
  flex: 1 0;
  flex-basis: 40%;

  @include mq_narrow {
    flex-basis: 100%;
  }

  &>* {
    margin-right: 100px;

    @include mq_narrow {
      margin-right: 0;
    }
  }
}

.title {
  @include sgTextLead;
  color: var(--grey-900);
  margin: 0;
  margin-bottom: var(--sg-spacing-tiny);
}
.subtitle {
  @include sgTextSmall;
  color: var(--grey-700);

  @include mq_narrow {
    margin: 10px 0;
  }
  @include mq_notNarrow {
    max-width: 330px;
  }
}

.subpanels {
  flex: 1 1;
  flex-basis: 60%;
  max-width: 100%;

  &.unique p {
    // Align with left subtitle
    @include sgTextSmall;
    color: var(--grey-700);
    margin: 0;
    margin-top: var(--subpanel-title-height);

    @include mq_narrow {
      margin: 10px 0;
    }
  }
}

// ============================================================================
// Subpanels share the above styles and specify some more:
.subpanel {
  display: flex;

  @include mq_narrow {
    // Elements should stack on top of each other
    width: 100%;
    display: block;
  }
  
  .title {
    @include sgSubpanelTitle;
    margin-bottom: 0;
  }

  .labels > * {
    margin-right: 30px;
    @include mq_narrow {
      margin-right: 0;
    }
  }

  .content {
    margin-bottom: 16px;
    max-width: 400px;

    @include mq_narrow {
      max-width: unset;
    }
  }

  .actions {
    flex: 0 1;

    // Account for button paddings
    margin-top: calc(var(--subpanel-title-height) - 10px);
    margin-left: 10px;

    & > div {
      float: right;

      @include mq_narrow {
        float: unset;
      }
    }

    @include mq_narrow {
      margin: 0;
      float: unset;
    }
  }
}

.subpanel + .subpanel {
  margin-top: 35px;
  border-top: solid 1px var(--grey-200);
  padding-top: 25px;

  @include mq_narrow {
    margin-top: 24px;
  }
}
