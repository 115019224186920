.module {
  p {
    margin: 0;

    & + p {
      margin-top: 8px;
    }
  }

  a {
    text-decoration: none;
  }
}