.App {
  width: 100%;
  overflow-y: visible; /* needs to be visible for checkout sticky to work */
  position: relative;
}
.App.clientSection {
  /* NB: in the client section however, the height must expand to this
  container's children height for the mobile behaviours like address bar
  auto-hiding to work correctly. */
  min-height: 100vh;
  height: max-content;
  height: -webkit-max-content;
}

.App.u-development::after {
  border-top: 2px solid #FB2804;
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 11;
}

.App > .SystemVersion {
  user-select: none;
  position: absolute;
  z-index: 50;
  top: 0px;
  right: 125px;
  background-color: #FB2804;
  color: #fff;
  padding: 1px 5px 0px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}