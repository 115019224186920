@import "../styles/mq.scss";
@import "../styles/sg.scss";

.module {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  
  z-index: 1;
  @include putInCenter;

  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    opacity: 0.9;
    border-radius: 4px;
  }
}