@import "../../shared/styles/sg.scss";

// This global style is automatically given to the CommandWindow. We need to
// remove its default padding in order to handle it locally for the backgrounds
:global(.kind-PlaceOrderWindow) {
  :global(.inner) > :global(.content) {
    padding: 0 !important;
  }
}

.module {
  color: var(--grey-900);
  border-radius: 4px;

  flex-grow: 1;
  @include putInCenter;

  // Custom display for those messages, with icon below the title
  // :global(.smallMessage) {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
    
  //   & > * {
  //     order: 1;
  //   }
  //   & > svg {
  //     margin-top: 0;
  //     margin-bottom: var(--sg-spacing-normal);
  //   }
  //   & > h1 {
  //     margin-top: 0;
  //     order: 0;
  //   }
  // }
  & > :global(.smallMessage) {
    padding: var(--sg-spacing-normal);

    @include mq_notNarrow {
      padding: var(--sg-spacing-bigger) var(--sg-spacing-biggest);
    }

    button {
      width: initial;
    }
  }

  .darkBackground {
    background-color: var(--primary-blue-800);
    background-image: url("/assets/hallstein/quality-seal-ghost.svg");
    background-size: contain;
    background-repeat: repeat;
    color: var(--white);
    background-position: 50%;
    background-size: 250px;
    border-radius: 4px;

    color: var(--white);

    :global(.sg-guide-header-title) {
      color: var(--white);
    }

    @include mq_narrow {
      // Small messages have a tweak to apply margins in narrow screens
      width: 100% !important;
      margin-inline: 0 !important;
      border-radius: 0;

      button {
        width: initial !important;
      }
    }
  }

  .logoMark {
    background-image: url("/assets/hallstein/hallstein-logo-mark-white.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 90px;
    height: 90px;
    margin-inline: auto;
    margin-bottom: var(--sg-spacing-small);
  }
}