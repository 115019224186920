@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.module {
  --major-spacing: var(--sg-spacing-normal);
  --neutral-spacing: var(--sg-spacing-small);
  --minor-spacing: var(--sg-spacing-tiny);
  --inactive-opacity: 0.25;
  @include mq_narrow {
    // There is less height constraint in mobile
    --major-spacing: var(--sg-spacing-small);
    --minor-spacing: var(--sg-spacing-small);
  }

  background-color: var(--grey-100);
  padding: 0;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  // Generic for all children
  & > div {
    padding: var(--minor-spacing) var(--major-spacing);

    & + div {
      border-top: 1px solid var(--grey-200);
    }

    &:last-child {
      // Make sure last child sticks to the end
      margin-top: auto;
    }
  }
}

// ------------------------------------
.module .title {
  padding-top: var(--minor-spacing);
  padding-bottom: var(--minor-spacing);
  display: flex;
  gap: var(--sg-spacing-tiny);
  justify-content: space-between;

  .text {
    display: inline;
    align-items: center;
    overflow: hidden;

    span {
      @include sgTextFootnote;
      color: var(--grey-400);
      margin-right: var(--sg-spacing-tiny);

      text-overflow: ellipsis;
      overflow: hidden;
    }
    svg {
      :global(.svg-shape) {
        fill: var(--grey-400);
      }
      margin-bottom: -3px;
      margin-top: -3px;
    }
  }
}

// ------------------------------------
.module .products {
  padding-top: var(--neutral-spacing);

  .product {
    background-color: var(--white);
    border-radius: 4px;
    padding: var(--sg-spacing-smaller) var(--sg-spacing-small);

    display: flex;
    align-items: center;
    gap: var(--sg-spacing-small);

    .image {
      height: 64px;
      width: 64px;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-origin: content-box;
    }
    .label {
      @include sgTextNormal(false);
      @include sgFontBold;
    }
  }

  .alone .product {
    flex-direction: column;
    padding: var(--sg-spacing-small);
    gap: 4px;

    .image {
      width: 118px;
      height: 118px;
    }
  }

  .product + .product,
  .product + .pricing {
    margin-top: var(--minor-spacing);
  }

  .pricing {
    @include sgTextNormal(false);
    line-height: 22px;

    .surcharge {
      @include sgTextSmall(false);
      color: var(--grey-500);
    }
  }
}
.module.inactive .products {
  opacity: var(--inactive-opacity);
}

// ------------------------------------
.module .address {
  line-height: 20px;
  @include sgTextSmall(false);

  :global(.Address) {
    color: var(--grey-500);

    :global(.address) > div:not(:first-child) {
      &::before {
        content: ", " !important;
      }
    }
  }
}
.module.inactive .address {
  opacity: var(--inactive-opacity);
}

// ------------------------------------
.module .nextDelivery {
  div:global(.deliveryDateInfo) {
    display: flex;
    justify-content: space-between;
  
    @include sgFontBold;

    span:last-child {
      // Status tag
      margin-top: -2px;
    }

    & + div {
      margin-top: 2px;

      @include sgFontNormal;
    }
  }
}
.module.inactive .nextDelivery {
  opacity: var(--inactive-opacity);
}

// ------------------------------------
.module .buttons {
  padding-top: var(--major-spacing);
  padding-bottom: var(--major-spacing);
  opacity: 1; // Always full opacity
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--minor-spacing);

  button {
    padding-inline: 8px !important; // Otherwise buttons get too big
  }
}
.module.tight .buttons {
  grid-template-columns: repeat(1, 1fr);
  
}


// ------------------------------------
// TEMPORARY END OF LIFE STYLING
.eol {
  .title {
    @include sgSubpanelTitle;
    padding-top: 0;
    padding-bottom: var(--sg-spacing-tiny);
    color: var(--primary-blue-700);
  }

  .productTrans {
    background-color: var(--primary-blue-700);
    color: var(--white);
    padding: 10px;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;

    display: flex;

    &.tight {
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;
    }
    &.large {
      flex-direction: row;
      justify-content: space-between;
    }
    
    .description {
      .info {
        @include sgTextFootnote;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}