@import "../styles/sg.scss";

.module {
  border-radius: 3px;

  @include sgFontBold;
  font-size: 11px;
  letter-spacing: 1.33px;

  text-transform: uppercase;

  white-space: nowrap;
  display: inline-block; // So they wrap in their container
  vertical-align: middle;
  height: fit-content; // otherwise it expands with the neighbours

  // Theme darker shade is defined in each one
  color: var(--darker-shade);
  &.alt {
    background-color: var(--darker-shade);
  }
}

.normalSize {
  line-height: 13px;
  padding: 6px 5px 5px;
  // => height 24px

  svg {
    width: 16px;
    height: 16px;

    margin-top: -3px;
    margin-bottom: -4px;
    margin-right: 5px;

    path {
      fill: var(--darker-shade);
    }
  }
  &.alt svg path {
    fill: var(--white);
  }
}

.smallSize {
  font-size: 12px;
  line-height: 12px;
  padding: 1px 5px 0px;
  // => height 13px
}

// THEMES
 
.beige {
  --darker-shade: var(--tertiary-beige-600);
  background-color: var(--tertiary-beige-300);

  &.alt {
    color: var(--tertiary-beige-200);
  }
}
.blue {
  --darker-shade: var(--primary-blue-700);
  background-color: var(--primary-blue-300);

  &.alt {
    color: var(--primary-blue-200);
  }
}
.blueAccent {
  --darker-shade: var(--accent-blue-600);
  background-color: var(--accent-blue-300);

  &.alt {
    color: var(--accent-blue-200);
  }
}
.gold {
  --darker-shade: var(--secondary-gold-600);
  background-color: var(--secondary-gold-300);

  &.alt {
    color: var(--secondary-gold-200);
  }
}
.green {
  --darker-shade: var(--accent-green-600);
  background-color: var(--accent-green-300);

  &.alt {
    color: var(--accent-green-200);
  }
}
.grey {
  --darker-shade: var(--grey-600);
  background-color: var(--grey-300);

  &.alt {
    color: var(--grey-200);
  }
}
.red {
  --darker-shade: var(--accent-red-600);
  background-color: var(--accent-red-300);

  &.alt {
    color: var(--accent-red-200);
  }
}
