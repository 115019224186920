@import "../shared/styles/sg.scss";
@import "../shared/styles/mq.scss";


.CartPage .CartPanel > .inner {
  padding-top: var(--sg-spacing-normal);
  padding-bottom: var(--sg-spacing-normal);
}

.CartPage .discount-banner {
  max-width: 1155px;
  margin-bottom: var(--sg-spacing-normal);
}

.CartPage .CartPanel > .inner {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0px auto;
}
.mq-sm .CartPage .CartPanel > .inner {
  display: block;
}

.CartPage .CartPanel .labels {
  padding-right: 25px;
}

.mq-not-xl .CartPage .CartPanel .labels {
  display: none;
}

.mq-not-sm .CartPage .CartPanel > .inner > .info {
  flex: 1 1 100%;
  margin-right: 25px;
  max-width: 730px;
}

.CartPage .item > .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CartPage .item > .inner .media {
  flex: 0 0 230px;
  height: 170px;

  border-radius: 4px;
  display: flex;
  align-items: center;

  &.useBackground {
    align-items: center;
    background-image: url("/assets/woodtable-bg.jpg");
    background-size: cover;
    background-position-y: bottom;

    .BackgroundImage {
      margin-top: var(--sg-spacing-tiny);
      height: 150px !important;
    }
  }
}

@mixin sectionTitle {
  text-transform: uppercase;
  @include sgFontBold;
  font-size: 11px;
  letter-spacing: 1.33px;
  line-height: normal;
}

.CartPanel .section {
  border-radius: 4px;

  .header {
    padding: 6px 25px 2px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: var(--white);

    .sectionTitle {
      @include sectionTitle;

      @include mq_narrow {
        text-align: center;
      }
    }
  }

  .items {
    padding: 0 var(--sg-spacing-normal);

    .item + .item {
      border-top: 1px solid;
    }
  }

  .itemsFooter {
    border-top: 1px solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: var(--sg-spacing-normal);

    button {
      width: 100%;
    }
  }
}

.CartPanel .section.subscriptionSection {
  background-color: var(--primary-blue-100);

  .header {
    background-color: var(--primary-blue-700);
  }

  .items .item + .item {
    border-top-color: var(--primary-blue-300);
  }

  .itemsFooter {
    border-color: var(--primary-blue-200);
  }
}

.CartPanel .section.oneTimeOrders {
  background-color: var(--grey-100);

  .header {
    background-color: var(--grey-700);
  }

  .items {
    border-color: var(--grey-400);

    .item {
      .inner .info .title {
        color: var(--grey-900);
      }
      
      & + .item {
        border-top-color: var(--grey-300);
      }
    }
  }

  .itemsFooter {
    border-color: var(--grey-200);
  }
}

.CartPanel .section + .section {
  margin-top: var(--sg-spacing-small);
}

.CartPanel .items .item .inner {
  .media {
    order: 2;
  }
  .info {
    order: 1;
    width: 100%;

    .title {
      @include sgTextLarge;
      color: var(--primary-blue-700);
      margin-bottom: var(--sg-spacing-small);
    }

    table {
      width: 100%;
      text-align: left;
      margin-top: var(--sg-spacing-smaller);
      
      thead {
        @include sgFontBold;
        text-transform: uppercase;
        color: var(--grey-400);
        font-size: 11px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.33px;
      }

      tbody td {
        @include sgFontBold;
      }
    }

    button + button {
      margin-left: var(--sg-spacing-small);
    }

    button {
      margin-top: var(--sg-spacing-smaller);

      display: inline-block;
      width: unset;

      @include mediaQueryMaxWidth(400px) {
        margin-left: 0 !important;
        margin-top: var(--sg-spacing-small);
        display: block;
        width: 100%;
      }
    }
  }
}

.CartPanel .subscriptionSection .items .item .info {
  margin: 50px 0 60px 0;
}
.CartPanel .oneTimeOrders .items .item .info {
  margin: 30px 0 40px 0;
}

.CartPanel .CartTotal {
  margin-bottom: var(--sg-spacing-small);

  @include mq_narrow {
    margin-bottom: var(--sg-spacing-normal);
    text-align: center;
  }

  &.mobile {
    @include mq_notNarrow {
      display: none;
    }
  }
  &.desktop {
    @include mq_narrow {
      display: none;
    }
  }

  .title {
    @include sgTextLarge;
    color: var(--primary-blue-700);
    margin-bottom: var(--sg-spacing-tiny);
  }
  .subtitle {
    @include sgTextNormal;
    @include sgFontBold;
    color: var(--grey-900);
    margin-bottom: var(--sg-spacing-tiny);
  }
  .subtotal {
    @include sgTextLarge;
    color: var(--grey-900);
    margin-bottom: 0;
  }
  .freeShipping {
    @include sgFontBold;
    color: var(--secondary-gold-600);
  }
}

.CartPanel .sideBar {
  width: 100%;
  @include mq_notNarrow {
    max-width: 350px;
  }

  .fixedDesktop {
    @include mq_notNarrow {
      position: sticky;
      top: 0px;
    }
  }

  button {
    display: block;
    margin-bottom: var(--sg-spacing-small);
  }

  .helptext {
    @include mq_narrow {
      text-align: center;
    }

    .charge {
      @include sgTextSmall;
      margin-bottom: var(--sg-spacing-small);
      color: var(--grey-400);
    }

    .disclaimer {
      @include sectionTitle;
      margin-bottom: var(--sg-spacing-small);
      color: var(--grey-900);
    }
  }
}

// Display the items in a more mobile like way from 1000px downward
@include mediaQueryMaxWidth(1100px) {
  .CartPanel .items .item {
    padding: var(--sg-spacing-normal) 0;

    .inner {
      display: block;
      text-align: center;

      .media {
        max-width: 230px;
        margin: 0 auto;
        margin-bottom: var(--sg-spacing-normal);
      }

      .info {
        margin: 0;

        table {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
  
        .itemButtons {
          white-space: nowrap;
        }
      }
    }
  }
}
