@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";
@import "../shared/Stripe.scss";

.module .verified {
  display: inline-block;
  margin-left: 0.5em;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  font-family: "AbelPro-Bold";
  letter-spacing: 0.5px;
  padding: 2px 2px 1px 2px;
  border: 1px solid var(--accent-green-600);
  color: var(--accent-green-600);
  vertical-align: top;
}

.stripeBackground {
  @include StripeIcon;
  padding-bottom: 40px;
}

.taxData {
  @include sgFontBold;
  @include sgTextSmall;
  color: var(--grey-700);

  @include mq_narrow {
    margin: 0;
  }

  span {
    vertical-align: bottom;
  }
}

.taxButton {
  @include mq_notNarrow {
    margin-top: -10px;
  }
}

// Negative margins so the card text aligns more with the subtitle
.cardCard {
  margin-top: -10px;
  max-width: 265px;

  @include mq_narrow {
    margin-top: 0;
    max-width: unset;
  }
}