@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";


.bgImage {
  @include sgBackgroundWholePage;

  &.bgImageLoaded {
    @include sgBackgroundWholePageLoaded;
  }
}

.module {

  &.darkBackground {
    --color: var(--white);
    background-color: var(--primary-blue-700);
    color: var(--color);
  }
  &.lightBackground {
    --color: var(--black);
    background-color: var(--primary-blue-200);
    color: var(--color);
  }
}

.container {
  position: relative;
  z-index: 0;
  width: 450px;
  padding: 90px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-bottom: var(--sg-spacing-big);
  }
}