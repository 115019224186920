
// ============================================================================
// ==== Style guide for common links
// ==

// ----------
// LINK LIGHT
// (with mixin for default link styling in styles.scss

@mixin sgLinkLight {
  // Too dangerous to set the font and text as it breaks some old links
  // @include sgFontNormal;
  // @include sgTextNormal;
  text-decoration: underline;
  color: var(--primary-blue-700);
  cursor: pointer;
}
@mixin sgLinkLightHover {
  color: var(--primary-blue-600);
}
@mixin sgLinkLightActive {
  color: var(--primary-blue-800);
}

.sg-link {
  @include sgLinkLight;

  &:hover {
    @include sgLinkLightHover;
  }
  &:active {
    @include sgLinkLightActive;
  }
}

// ---------------
// LINK DARK COLOR

.sg-link-dark-color {
  text-decoration: underline;
  color: var(--grey-900);
  cursor: pointer;

  &:hover {
    color: var(--grey-400);
  }
  &:active {
    color: var(--grey-600);
  }
}

// --------------------
// LINK DARK BACKGROUND

.sg-link-dark-background {
  text-decoration: underline;
  color: var(--white);
  cursor: pointer;
  
  &:hover {
    color: var(--secondary-gold-600);
  }
  &:active {
    color: var(--grey-600);
  }
}

// ============================================================================
// ==== Style guide for links in the footer and menu

// ----------
// MENU LIGHT

@mixin sgMenuLinkLight {
  text-decoration: none;
  color: var(--grey-900);
  fill: var(--grey-900);
  cursor: pointer;
}
@mixin sgMenuLinkLightHover {
  color: var(--primary-blue-700);
  fill: var(--primary-blue-700);
}
@mixin sgMenuLinkLightActive {
  color: var(--primary-blue-800);
  fill: var(--primary-blue-800);
}

// ---------
// MENU DARK

@mixin sgMenuLinkDark {
  text-decoration: none;
  color: var(--white);
  fill: var(--white);
  cursor: pointer;
}
@mixin sgMenuLinkDarkHover {
  color: var(--secondary-gold-600);
  fill: var(--secondary-gold-600);
}
@mixin sgMenuLinkDarkActive {
  color: var(--grey-600);
  fill: var(--grey-600);
}

// ------
// FOOTER

@mixin sgFooterLink {
  text-decoration: none;
  color: var(--grey-900);
  cursor: pointer;
}
@mixin sgFooterLinkHover {
  color: var(--grey-400);
}
@mixin sgFooterLinkActive {
  color: var(--grey-600);
}
