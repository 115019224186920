
@import "./sgForm.scss";

:root {
  --color-normal: var(--grey-200);
  --color-focused: var(--primary-blue-700);
}

.module {
  @include sgMarginBottomNormal;
}

.label {
  text-align: left;
  @include sgFontBold;
  @include sgTextTiny;
}

