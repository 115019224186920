@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.module {
  background-color: var(--white);
  width: 100%;
  height: 100%;
  padding: 0px var(--sg-spacing-normal);
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  @include mq_narrow {
    justify-content: space-between;
  }

  p {
    color: var(--grey-900);
    @include sgFontBold;
    margin: 0;
    margin-right: 17px;
    @include mq_narrow {
      margin-right: 11px;
    }
    margin-top: 2px;
    font-size: 11px;
    letter-spacing: 1.33px;
    line-height: normal;

    display: flex;
    align-items: center;
    
    svg {
      margin-right: 14px;
      margin-bottom: 3px;

      @include mq_narrow {
        margin-right: 8px;
      }
    }

    height: 100%;
    span {
      display: block;
      max-height: 100%;
      overflow: hidden;
    }
  }

  @include mq_narrow {
    button {
      flex-basis: 114px;
    }
  }

  @include mediaQueryMaxWidth(250px) {
    // Really no space for text
    p {
      display: none;
    }
    button {
      flex-basis: 100%;
    }
  }
}
