@import "./shared/styles/sg.scss";
@import "./shared/styles/mq.scss";

html {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;  
}

*, *:before, *:after {
  box-sizing: inherit;
}

*::selection, *:before::selection, *:after::selection {
    color: #fff;
    background-color: #f00;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;

  // Default text style
  @include sgDefaultTextStyle;
}

#root {
  width: 100%;
  height: 100%;
}

strong {
  @include sgFontBold;
}

// No browser default outline on focus
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
[contenteditable="true"]:focus {
  outline: none;
}
// For safari inner shadow...textarea,
input:matches(
  [type="email"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="url"]
) {
  -webkit-appearance: none;
}
// Hide credential icon for safari only for password input
input[type=password]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
}