@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";

.SubPage h1 {
  text-align: center;
  line-height: 1.2em;
}
.SubPage .lead {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 1em;
  line-height: 1.2em;
}

.SubPage {
  h1 {
    @include sgTextLarge;
    color: var(--primary-blue-700);
    margin: 0 !important; // Never keep margins for this page
  }
}

// Default footer
.SubPage > .footer {
  // @include shopFooter;
}

.SubPage.chooseSubName {
  & > .inner {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      width: 400px;
      @include mq_narrow {
        width: 100%;
      }

      p, h1 {
        text-align: center;
        margin: 0;
      }
  
      p {
        color: var(--grey-900);
      }
  
      label {
        display: none;
      }
      input {
        margin-top: 31px;
        width: 100%;
      }
    }
  }

  @include mq_narrow {
    .footer {
      p {
        display: none;
      }
      button {
        flex-basis: 100%;
      }
    }
  }
}

.SubPage.chooseProducts {
  // Products centering for bigger screens
  & > .inner > div {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.SubPage.chooseDispenser {
  .intro {
    padding: var(--sg-spacing-normal) var(--sg-spacing-small);
    border-radius: 4px;
    background-color: var(--primary-blue-100);
    text-align: center;
    margin-bottom: var(--sg-spacing-small);

    p {
      margin-top: var(--sg-spacing-tiny);
      margin-bottom: 0;
      max-width: 930px;
      margin: 0 auto;
    }
  }

  .dispensers {
    display: grid;
    grid-gap: var(--sg-spacing-small);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include mediaQueryMaxWidth(2000px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include mediaQueryMaxWidth(1300px) {
      grid-template-columns: 1fr 1fr;
    }
    @include mq_narrow {
      grid-template-columns: 1fr;
    }

    .dispenser {
      background-color: var(--grey-100);
      
      .inner > .media {
        background-color: var(--grey-200);
      }
    }
  }

  .footer {
    button {
      flex-basis: 236px;

      @include mq_narrow {
        flex-basis: 100%;
      }
    }
  }
}

.SubPage .WarningMessage {
  max-width: 700px;
  text-align: center;
  margin: auto;
}

.SubPage .product > .inner {
  padding: var(--sg-spacing-normal);
  max-width: 1000px;
  display: flex;
  flex-direction: column;
}

.SubPage .frequencyWeeksSelect > .inner {
  padding: 45px;
  max-width: 1000px;
  margin: 0px auto; 
}

.SubPage .products {
  display: flex;
  flex-wrap: wrap;

  @include mediaQueryMinWidth(1100px) {
    // Wide enough desktops: display 2 / 2

    .product {
      flex: 0 0 calc(50% - 8px);
    }
    .product + .product {
      margin-left: var(--sg-spacing-small);
    }
  }
  @include mediaQueryMaxWidth(1100px) {
    // Mobile and narrow windows

    .product {
      flex-basis: 100%;
    }
    .product + .product {
      margin-top: var(--sg-spacing-normal);
    }
  }

  @include mq_narrow {
    .divider {
      display: none;
    }
  }
}

.SubPage .product .price {
  @include sgFontBold;
}

.SubPage .product .disclaimer {
  font-size: 13px;
  line-height: 1.4em;
}


