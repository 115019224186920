@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";


.module {

  .currentOrders :global(.subpanel) + :global(.subpanel),
  .nextOrders :global(.subpanel) + :global(.subpanel) {
    margin-top: var(--sg-spacing-smaller);
    border-top: none;
    padding-top: 0;
  }
}

.item {
  background-color: var(--grey-100);
  text-align: left;
  margin-bottom: 1em;
  position: relative;

  color: var(--grey-900);
  font-size: 18px;
  line-height: 1.67;

  &.withMargin {
    padding: var(--sg-spacing-tiny);
  }
}
.item:last-child {
  margin-bottom: 0em;
}

.icons {
  margin-bottom: 0.25em;

  & > * {
    display: none;
    margin-right: var(--sg-spacing-tiny);
  }

  .busy {
    position: relative;
  }
  .busy .Loading {
    display: inline-block;
    position: absolute;
    top: -3px;
  }
}

.item .moreInfo {
  cursor: pointer;
  display: none;
  color: #0080FF;
}

.item.uBusy .busy {
  display: inline-block;
}

.item.uBusy .updating {
  display: inline-block;
}

.item.uProcessed .processed {
  display: inline-block;
}
.item.uProcessed .moreInfo {
  display: block;
}

.item.uCurrent .current {
  display: inline-block;
}

.item.uNext .next {
  display: inline-block;
}

.item.uCancelled .cancelled {
  display: inline-block;
}

.item.uCancelled .nextDelivery {
  display: none;
}

.item.uCancelled {
  display: none;
}

.item .quantity {
  @include sgFontBold;
}