@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.module {
  @include sgTextSmall;
  margin: 0;

  p {
    margin: 0;
  }

  &.cancellable {
    display: flex;
    gap: var(--sg-spacing-small);
    & > div {
      flex-grow: 1;
    }
    align-items: center;
    text-align: center;

    .closeIcon {
      flex: 0 0 24px;
    }
  }
}

.golden {
  padding: var(--sg-spacing-small);
  @include mq_notNarrow {
    padding: var(--sg-spacing-small) var(--sg-spacing-normal) var(--sg-spacing-small);
  }
  background-color: var(--secondary-gold-600);
  color: var(--white);
  // Needed when header is transparent
  z-index: 200;

  @include sgTextNormal;
  margin: 0;
  & > div:global(.Tip) {
    // overwrite tip defaults
    padding: 0 !important;
    margin: 0 !important;
  }

  @include mq_narrow {
    @include sgTextSmall;
    margin: 0;
    
    & > div:global(.Tip) {
      // overwrite tip defaults
      text-align: left;
    }

    svg {
      align-self: baseline;
      margin-top: 3px;
    }
  }

  .closeIcon path {
    fill: var(--white) !important;
  }

  a {
    // overwrite default white button theme
    &:hover {
      color: var(--grey-200) !important;
    }
    &:active {
      color: var(--grey-600) !important;
    }
  }
}