
@import "./sgForm.scss";

.module {
  @include sgMarginBottomNormal;
}

.module .label {
  text-align: left;
  @include sgFontBold;
  @include sgTextTiny;
}
