

:root {
  --white: #ffffff;
  --black: #000000;
  --grey-900: #141414;
  --grey-800: #242424;
  --grey-700: #333333;
  --grey-600: #5c5c5c;
  --grey-500: #707070;
  --grey-400: #999999;
  --grey-300: #c2c2c2;
  --grey-200: #ebebeb;
  --grey-100: #f6f6f6;
  --tertiary-beige-900: #3d3b36;
  --tertiary-beige-800: #66625a;
  --tertiary-beige-700: #8f897d;
  --tertiary-beige-600: #b8b0a1;
  --tertiary-beige-500: #dbd6ca;
  --tertiary-beige-400: #e6e2d9;
  --tertiary-beige-300: #f0ede8;
  --tertiary-beige-200: #f5f3f0;
  --tertiary-beige-100: #f6f5f3;
  --primary-blue-900: #05223c;
  --primary-blue-800: #083b69;
  --primary-blue-700: #005083;
  --primary-blue-600: #3d77ab;
  --primary-blue-500: #6d99c0;
  --primary-blue-400: #9ebbd5;
  --primary-blue-300: #b6cce0;
  --primary-blue-200: #e7eef5;
  --primary-blue-100: #f3f6f8;
  --accent-blue-900: #00005f;
  --accent-blue-800: #0000a7;
  --accent-blue-700: #0000ee;
  --accent-blue-600: #3333f1;
  --accent-blue-500: #6666f5;
  --accent-blue-400: #9999f8;
  --accent-blue-300: #b3b3fa;
  --accent-blue-200: #e6e6fd;
  --accent-blue-100: #f1f1fb;
  --secondary-gold-900: #49422a;
  --secondary-gold-800: #6e643f;
  --secondary-gold-700: #928554;
  --secondary-gold-600: #b7a669;
  --secondary-gold-500: #c5b887;
  --secondary-gold-400: #d4caa5;
  --secondary-gold-300: #e9e4d2;
  --secondary-gold-200: #f1ede1;
  --secondary-gold-100: #f8f6f0;
  --accent-green-900: #183817;
  --accent-green-800: #245323;
  --accent-green-700: #306f2e;
  --accent-green-600: #3c8b3a;
  --accent-green-500: #63a261;
  --accent-green-400: #9ec59d;
  --accent-green-300: #c5dcc4;
  --accent-green-200: #ecf3eb;
  --accent-green-100: #fafffa;
  --accent-red-100: #fff6f4;
  --accent-red-200: #ffe9e6;
  --accent-red-300: #f5a79d;
  --accent-red-400: #ee7363;
  --accent-red-500: #e04936;
  --accent-red-600: #d42b2b;
  --accent-red-700: #aa2222;
  --accent-red-800: #7f1a1a;
  --accent-red-900: #551111;
}

.sg-white {
  color: var(--white) !important;
}
.sg-black {
  color: var(--black) !important;
}
.sg-grey-900 {
  color: var(--grey-900) !important;
}
.sg-grey-800 {
  color: var(--grey-800) !important;
}
.sg-grey-700 {
  color: var(--grey-700) !important;
}
.sg-grey-600 {
  color: var(--grey-600) !important;
}
.sg-grey-500 {
  color: var(--grey-500) !important;
}
.sg-grey-400 {
  color: var(--grey-400) !important;
}
.sg-grey-300 {
  color: var(--grey-300) !important;
}
.sg-grey-200 {
  color: var(--grey-200) !important;
}
.sg-grey-100 {
  color: var(--grey-100) !important;
}
.sg-tertiary-beige-900 {
  color: var(--tertiary-beige-900) !important;
}
.sg-tertiary-beige-800 {
  color: var(--tertiary-beige-800) !important;
}
.sg-tertiary-beige-700 {
  color: var(--tertiary-beige-700) !important;
}
.sg-tertiary-beige-600 {
  color: var(--tertiary-beige-600) !important;
}
.sg-tertiary-beige-500 {
  color: var(--tertiary-beige-500) !important;
}
.sg-tertiary-beige-400 {
  color: var(--tertiary-beige-400) !important;
}
.sg-tertiary-beige-300 {
  color: var(--tertiary-beige-300) !important;
}
.sg-tertiary-beige-200 {
  color: var(--tertiary-beige-200) !important;
}
.sg-tertiary-beige-100 {
  color: var(--tertiary-beige-100) !important;
}
.sg-primary-blue-900 {
  color: var(--primary-blue-900) !important;
}
.sg-primary-blue-800 {
  color: var(--primary-blue-800) !important;
}
.sg-primary-blue-700 {
  color: var(--primary-blue-700) !important;
}
.sg-primary-blue-600 {
  color: var(--primary-blue-600) !important;
}
.sg-primary-blue-500 {
  color: var(--primary-blue-500) !important;
}
.sg-primary-blue-400 {
  color: var(--primary-blue-400) !important;
}
.sg-primary-blue-300 {
  color: var(--primary-blue-300) !important;
}
.sg-primary-blue-200 {
  color: var(--primary-blue-200) !important;
}
.sg-primary-blue-100 {
  color: var(--primary-blue-100) !important;
}
.sg-accent-blue-900 {
  color: var(--accent-blue-900) !important;
}
.sg-accent-blue-800 {
  color: var(--accent-blue-800) !important;
}
.sg-accent-blue-700 {
  color: var(--accent-blue-700) !important;
}
.sg-accent-blue-600 {
  color: var(--accent-blue-600) !important;
}
.sg-accent-blue-500 {
  color: var(--accent-blue-500) !important;
}
.sg-accent-blue-400 {
  color: var(--accent-blue-400) !important;
}
.sg-accent-blue-300 {
  color: var(--accent-blue-300) !important;
}
.sg-accent-blue-200 {
  color: var(--accent-blue-200) !important;
}
.sg-accent-blue-100 {
  color: var(--accent-blue-100) !important;
}
.sg-secondary-gold-900 {
  color: var(--secondary-gold-900) !important;
}
.sg-secondary-gold-800 {
  color: var(--secondary-gold-800) !important;
}
.sg-secondary-gold-700 {
  color: var(--secondary-gold-700) !important;
}
.sg-secondary-gold-600 {
  color: var(--secondary-gold-600) !important;
}
.sg-secondary-gold-500 {
  color: var(--secondary-gold-500) !important;
}
.sg-secondary-gold-400 {
  color: var(--secondary-gold-400) !important;
}
.sg-secondary-gold-300 {
  color: var(--secondary-gold-300) !important;
}
.sg-secondary-gold-200 {
  color: var(--secondary-gold-200) !important;
}
.sg-secondary-gold-100 {
  color: var(--secondary-gold-100) !important;
}
.sg-accent-green-900 {
  color: var(--accent-green-900) !important;
}
.sg-accent-green-800 {
  color: var(--accent-green-800) !important;
}
.sg-accent-green-700 {
  color: var(--accent-green-700) !important;
}
.sg-accent-green-600 {
  color: var(--accent-green-600) !important;
}
.sg-accent-green-500 {
  color: var(--accent-green-500) !important;
}
.sg-accent-green-400 {
  color: var(--accent-green-400) !important;
}
.sg-accent-green-300 {
  color: var(--accent-green-300) !important;
}
.sg-accent-green-200 {
  color: var(--accent-green-200) !important;
}
.sg-accent-green-100 {
  color: var(--accent-green-100) !important;
}
.sg-accent-red-100 {
  color: var(--accent-red-100) !important;
}
.sg-accent-red-200 {
  color: var(--accent-red-200) !important;
}
.sg-accent-red-300 {
  color: var(--accent-red-300) !important;
}
.sg-accent-red-400 {
  color: var(--accent-red-400) !important;
}
.sg-accent-red-500 {
  color: var(--accent-red-500) !important;
}
.sg-accent-red-600 {
  color: var(--accent-red-600) !important;
}
.sg-accent-red-700 {
  color: var(--accent-red-700) !important;
}
.sg-accent-red-800 {
  color: var(--accent-red-800) !important;
}
.sg-accent-red-900 {
  color: var(--accent-red-900) !important;
}
