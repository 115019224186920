.ProductWaitingListForm h5 {
  /*font-family: "AbelPro-Bold";*/
  font-size: 20px;
  margin-bottom: 1em;
}

.ProductWaitingListForm p {
  line-height: 1.25em;
  font-size: 16px;
}

.ProductWaitingListForm p .productTitle {
  font-family: "AbelPro-Bold";
}