@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.module {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
  padding: var(--sg-spacing-normal);

  :global(.mq-sm) & {
    min-height: calc(100vh - var(--margins-total));
  }
}

.inner {
  display: block !important;
  text-align: center;
  max-width: 370px;

  h1 {
    margin: 0;
  }
  h1 + p {
    margin: var(--sg-spacing-small) 0 20px 0;
  }

  @include mq_notNarrow {
    button {
      width: 220px;
    }
  }
}
