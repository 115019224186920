@import "../shared/styles/sg.scss";

.module {
  position: relative;
  border: 1px solid var(--grey-200);
  border-radius: 4px;
  padding: 16px 16px 8px;
  box-shadow: 0 0 0 1px var(--primary-blue-700), 0 0 0 4px var(--primary-blue-200);

  &.expand {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .main {
      margin-bottom: 7px;
    }
    .additionalInfo {
      padding-top: 3px;
    }
  }
}

.main {
  @include sgFontBold;
  @include sgTextNormal;
  margin-bottom: 15px;
}
.card {
  display: inline-block;
  height: 25px;
  width: 40px;
  white-space: nowrap;

  margin-right: 8px;
  vertical-align: middle;

  border-radius: 4px;

  // Unknown brand:
  background-color: #EBEBEB;
  background-image: url("/assets/payment/creditcard-placeholder.svg");
}

.visa {
  background-color: #0e4595;
  background-image: url("/assets/payment/creditcard-visa.svg");
}
.mastercard {
  background-color: #F4F4F4;
  background-image: url("/assets/payment/creditcard-mastercard.svg");
}
.amex {
  background-color: #016FD0;
  background-image: url("/assets/payment/creditcard-americanexpress.svg");
}

.additionalInfo {
  color: var(--grey-400);

  display: flex;
  justify-content: space-between;

  span + img {
    margin-left: 4px;
  }
}

.delete {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  background-color: var(--grey-200);

  display: flex;
  fill: red;
  align-items: center;
  justify-content: center;
}
