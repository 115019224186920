/*.SupportDoc {
  font-size: 14px;
  line-height: 1.4em;
}*/
.SupportDoc h1 {
  font-size: 24px;
  font-family: "AbelPro-Bold";
}

.SupportDoc h2 {
  font-size: 18px;
  font-family: "AbelPro-Bold";
}

.SupportDoc h6 {
  font-size: 16px;
  line-height: 16px;
  color: var(--grey-600);
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
}

.SupportDoc hr {
  border-color: var(--grey-200);
  border-style: solid;
  margin: 2em 0px;
}

.SupportDoc p {
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-900);
}

.SupportDoc a {
  color: var(--primary-blue-700);
}

.SupportDoc img {
  width: 368px;
}

.separator {
    border-bottom: 1px solid var(--grey-200);
    margin-top: 16px;
    position: absolute;
    width: 100%;
    left: 0px;
}

.SupportDocError {
  text-align: center;
  padding: 50px 25px;
  background-color: #FDFDF0;
}

.SupportDocError .title {
  font-family: "AbelPro-Bold";
}

.SupportDocError p {
  max-width: 200px;
  margin: 1em auto;
}


.SupportDoc .title {
  color: var(--grey-900);
  font-family: "AbelPro-Bold";
  font-size: 16px;
  line-height: 24px;
  margin-top: 4px;
}

.SupportDoc .backArrow {
  margin-top: -2px;
  float: right;
}