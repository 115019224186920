@import "../../shared/styles/mq.scss";

.QuantityAssistant .steppers {
  display: flex;
  @include mq_narrow {
    flex-wrap: wrap;
  }
  
  .stepper {
    width: 50%;

    @include mq_narrow {
      width: 100%;
    }
  }

  .stepper + .stepper {
    @include mq_notNarrow {
      margin-left: 17px;
    }
    @include mq_narrow {
      margin-top: var(--sg-spacing-normal);
    }
  }
}
