@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.module {
  --discount-code-banner-margin: var(--sg-spacing-small);
  margin-top: var(--discount-code-banner-margin);
  margin-bottom: var(--discount-code-banner-margin);
  margin-inline: auto;

  .logoParent {
    position: relative;

    & > div {
      background-position-x: left;
    }
  }

  .textParent {
    p {
      @include sgTextSmall(false);
      margin: 0;

      & + p {
        margin-top: 6px;
      }
    }
  }

  &.moreVerticalSpace {
    --discount-code-banner-margin: var(--sg-spacing-normal);
  }
}