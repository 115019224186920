@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";

.module {
  display: flex;
  align-items: center;

  color: var(--primary-blue-700);

  svg {
    margin-top: -4px;
  }

  .text {
    flex-grow: 1;
    margin-left: var(--sg-spacing-smaller);
    @include sgFontBold;
  }
}