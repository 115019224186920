@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";


.breadcrumb {
  display: flex;
  gap: var(--sg-spacing-tiny);
  padding-left: 0;

  li.step {
    list-style-type: none;

    button {
      @include unbuttonize;

      @include sgFontBold;
      letter-spacing: 1.33px;
      font-size: 11px;
      text-decoration: none;
      text-transform: uppercase;
      outline: none;
      border: none;
      color: var(--grey-400);
    }

    &.active button {
      color: var(--primary-blue-700);
      cursor: pointer;
    }
    &.active.pending button {
      color: var(--grey-400);
    }
    &.current button {
      color: var(--grey-800);
    }
  }
}