.SuccessForm {
  text-align: center;
}
.SuccessForm .media {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SuccessForm .title {
  font-size: 20px;
  margin-bottom: 1em;
  font-weight: normal;
}

.SuccessForm p {
  line-height: 1.25em;
}
