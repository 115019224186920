@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";

.addressContainer {
  border: 1px solid var(--grey-200);
  padding: 16px 14px;
  border-radius: 4px;
}

.Address-inline {
  display: inline;
}

.Address-inline .addressLine2 {
  display: none;
}

.Address-inline.u-addressLine2 .addressLine2 {
  display: inline;
}

.Address .extras {
  margin-top: 0.5em;
}

.Address .phoneNr .phone {
  display: inline-block;
}

// Address should be displayed on 2 lines whether in normal or tight mode
.Address .address {
  text-overflow: ellipsis;
  overflow: hidden;

  & > div {
    display: inline;

    & + div::before {
      content: ", ";
    }

    &.addressLine3::before {
      // Force line break before post code
      content: '\A';
      white-space: pre;
    }
  }
}

.Address.displayModeNormal .extras > div {
  margin-top: var(--sg-spacing-tiny);
}

.Address.displayModeTight {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--sg-spacing-smaller);

  color: var(--grey-500);

  .extras {
    color: var(--grey-900);
  
    margin-top: 0;
    word-wrap: break-word;
    word-wrap: anywhere; // Better for us but not as widely supported

    .label {
      display: none;
    }
    div, span {
      white-space: pre-wrap;
    }
  }

  // On smaller screens, the extras info shall be below the address
  @include mediaQueryMaxWidth(500px) {
    grid-template-columns: 1fr;
    .extras {
      margin-top: var(--sg-spacing-tiny);
    }
  }
  // Same problem on desktop that is too narrow
  @include mediaQueryBetween(700px, 900px) {
    grid-template-columns: 1fr;
    .extras {
      margin-top: var(--sg-spacing-tiny);
    }
  }
}
.Address.displayModeTight.noExtras {
  grid-template-columns: 100%;
}