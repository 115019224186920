@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";

.module {
  display: flex;
}

.left {
  flex: 2;
  display: flex;
  align-items: center;

  margin-right: 30px;
}

.right {
  flex: 1 0;
  display: flex;
  align-items: center;

  @include mq_narrow {
    flex: 0 0; // Completely to the right
  }
}