@import "../styles/mq.scss";
@import "../styles/sg.scss";


.module {
  display: flex;
  flex-direction: column;
  @include putInCenter;
  border-radius: 4px;
  
  background-color: var(--grey-100);
  height: 60vh;

  p {
    text-align: center;
  }
}