@import "../../shared/styles/sg.scss";

.module {
  display: flex;
  width: 100%;
  align-items: center;
}

.radiobox {
  position: relative;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 24px;
  border: 1px solid var(--grey-400);
  margin-right: var(--sg-spacing-small);

  .tick {
    display: none;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 14px;
    height: 14px;
    border-radius: 10px;
    background-color: var(--primary-blue-700);
  }
}

.clickable {
  .radiobox {
    cursor: pointer;
  }
}

.uSelected .tick {
  display: block;
}

.right {
  @include sgFontBold;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  button {
    width: unset;
  }
}