@import "../../shared/styles/sg.scss";

.uActive .labels .status {
  font-size: 14px;
  color: #1AB276;
  font-family: "AbelPro-Bold";
  text-transform: uppercase;
}

.section {
  .name, .frequency, .email, .phone {
    @include sgFontBold;

    span {
      @include sgFontNormal;
    }
    div {
      display: inline-block;
    }
  }

  .memberSince {
    margin-top: var(--sg-spacing-smaller);
    @include sgSubpanelTitle;
  }
}

.notifications {
  
  .item {
    display: flex;
    align-items: center;

    .label {
      height: 18px;
      line-height: 22px;
    }

    .explanation {
      margin-bottom: 0.25em;
    }

    .ToggleButton {
      display: inline-block;
      margin-right: 0.5em;
    }
  }
  .item + .item {
    margin-top: 1em;
  }

  .disabled {
    color: #ccc;
    font-family: "AbelPro"; 
  }
  .enabled {
    color: var(--accent-green-600);
    font-family: "AbelPro"; 
  }
}

