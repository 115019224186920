.module {
  align-items: center;
  padding: 16px 14px;
  cursor: pointer;

  margin-bottom: var(--sg-spacing-small);

  border: 1px solid var(--grey-200);
  border-radius: 4px;

  &.uSelected {
    border-color: var(--primary-blue-700);
    box-shadow: 0 0 0 1px var(--primary-blue-700), 0 0 0 4px var(--primary-blue-200);
  }

  &.disabled {
    cursor: unset;
    opacity: 0.6;
    background-color: var(--grey-200);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.hasChildren .header {
  margin-bottom: var(--sg-spacing-small);
}

.displayTight.hasChildren {
  padding-bottom: var(--sg-spacing-tiny);
  
  .header {
    margin-bottom: var(--sg-spacing-tiny);

    & > div > div:first-child {
      margin-right: var(--sg-spacing-tiny);
    }
  }
}

.headerRight {
  margin-left: auto;
  display: flex;

  svg + svg,
  img + svg {
    margin-left: var(--sg-spacing-smaller);
  }
}