
$narrow-cutoff: 700px;
@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin backgroundImageWithRetina($baseUrl, $extension) {
  background-image: url($baseUrl + "." + $extension);
  @include retina {
    background-image: url($baseUrl + "@2x." + $extension);
  }
}

@mixin mediaQueryMaxWidth($maxWidth) {
  @media screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin mediaQueryMaxHeight($maxHeight) {
  @media screen and (max-height: $maxHeight) {
    @content;
  }
}

@mixin mediaQueryMinWidth($minWidth) {
  @media screen and (min-width: $minWidth) {
    @content;
  }
}

@mixin mediaQueryMinHeight($minHeight) {
  @media screen and (min-height: $minHeight) {
    @content;
  }
}

@mixin mediaQueryBetween($minWidth, $maxWidth) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin mq_narrow() {
  @media screen and (max-width: $narrow-cutoff) {
    @content;
  }
}
@mixin mq_notNarrow() {
  @media screen and (min-width: $narrow-cutoff) {
    @content;
  }
}

@mixin backgroundImageWithRetinaAndMobile($baseUrl, $extension) {
  @include backgroundImageWithRetina($baseUrl, $extension);
  
  @include mq_narrow {
    @include backgroundImageWithRetina($baseUrl + "-mobile", $extension);
  }
}