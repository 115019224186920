@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";

.module {
  padding: var(--sg-spacing-small);
  @include mq_notNarrow {
    padding: var(--sg-spacing-normal);
  }
  @include mq_narrow {
    padding-top: var(--sg-spacing-big);
  }

  text-align: center;

  @include putInCenter;
  flex-direction: column;

  @include mq_notNarrow {
    max-width: 500px;
    margin: auto;
  }

  .inner {
    @include mq_notNarrow {
      max-width: 500px;
      margin: auto;
    }

    h1 {
      @include sgTextLarge;
      color: var(--primary-blue-700);
      margin: var(--sg-spacing-small) 0 var(--sg-spacing-tiny);
    }

    p {
      @include sgTextNormal;
      color: var(--grey-900);
    }
  }

  .buttons {
    width: 100%;
    margin-top: var(--sg-spacing-small);

    @include mq_notNarrow {
      margin-top: 32px;

      button {
        width: 100%;
      }
    }
  }

  .top, .bottom {
    flex-grow: 1;
  }
}

.default {
  @include mq_narrow {
    width: 100vw;
    min-height: 100vh;
  }
}

.fullpage {
  width: 100vw;
  min-height: 100vh;

  // Mobile mode: button should be at the bottom
  @include mq_narrow {
    .inner {
      flex-grow: 1;
    }
    .bottom {
      display: none;
    }
  }
}
