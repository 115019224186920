@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.module {
  --flag-height: 24px;

  position: relative;
  width: 214px;
  white-space: nowrap;

  &.mobile {
    width: 100%;
  }
}

.desktop .outside {
  @include mq_notNarrow {
    width: 100vw;
    height: 100vh;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

.main {
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--sg-spacing-tiny);
  border-radius: 4px;
  border: solid 1px var(--grey-200);
  cursor: pointer;
  margin-top: 1px;
  height: 40px;

  @include mq_narrow {
    padding: var(--sg-spacing-small);
  }
}

.flagWrapper {
  margin-right: 8px;

  width: 34px;
  height: var(--flag-height);
}

.title {
  @include sgFontBold;
  font-size: 16px;
  margin-top: 2px;

  flex-grow: 1;

  // For long account names:
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  margin-left: var(--sg-spacing-tiny);
  height: var(--flag-height);
}

.menu {
  background-color: var(--white);

  .buttons * {
    width: 100%;
  }

  .whyMultiple {
    display: block !important;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    color: var(--grey-400);
    @include sgFontBold;
    font-size: 11px;
    letter-spacing: 1.33px;
    margin-top: var(--sg-spacing-smaller);
  }
}

.desktop .menu {
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(36, 36, 36, 0.16);
  border: solid 1px var(--grey-200);
  right: 0;
  top: 54px;
  width: 370px;
  max-height: 80vh;
  overflow: auto;

  .accounts {
    padding: var(--sg-spacing-normal);
    padding-bottom: 0;
  }
  .newAccount {
    padding: 0 var(--sg-spacing-normal);
  }
  .signOut {
    padding: var(--sg-spacing-normal);
    padding-top: 0;
  }
}

.mobile .menu {
  margin-top: var(--sg-spacing-normal);
}