@import "../../styles/sg.scss";

.module {
  margin-bottom: 16px;
  user-select: none;

  text-align: start;
  @include sgFontNormal;
  font-weight: 600;
  @include sgTextTiny;

  outline: none;
}
.module:focus {
  &.checked .box {
    background-color: var(--primary-blue-500);
    border: 1px solid var(--primary-blue-700);
  }
  .box {
    background-color: var(--primary-blue-100);
    border: 1px solid var(--primary-blue-700);
  }
}

.inner {
  display: flex;
  align-items: center;
}

.box {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background-color: var(--white);
  border: 1px solid var(--grey-400);
  margin-right: 8px;
  border-radius: 5px;
}
.module:global(.blue) .box {
  border-color: var(--primary-blue-400);
}

.box:hover {
  background-color: var(--primary-blue-100);
  border: 1px solid var(--primary-blue-700);
}
.checked .box:hover {
  background-color: var(--primary-blue-500);
  border: 1px solid var(--primary-blue-700);
}

.checked .box {
  background-color: var(--primary-blue-700);
  border: 1px solid var(--primary-blue-700);
  background-image: url(/assets/icons/check-line.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 17px;
}

.focused .box {
  background-color: green !important;
}

.inactive .box {
  background-color: var(--grey-100);
  border: 1px solid var(--grey-200);
}

.label {
  @include sgTextTiny;
  margin-bottom: 0;
  font-weight: 500;

  span, button {
    white-space: normal;
    text-align: left;
  }
}

.fieldError {
  display: block;
  margin-left: 27px;
}

.darkBackground {
  .box {
    border: none;
  }
}

.submitting {
  cursor: wait;
  opacity: 0.9;
}