
@import "../../styles/sg.scss";

.module {
  color: var(--accent-red-600);
  @include sgFontNormal;

  margin-top: var(--sg-spacing-normal);
  background-color: var(--accent-red-100);
  color: var(--accent-red-500);
  padding: 18px 16px 13px 16px;

  p {
    @include sgTextSmall;
    margin: 0;
  }
  p:only-child {
    margin: 0 !important;
  }
}

.darkBackground {
  background-color: var(--accent-red-600);
  color: var(--white);

  border-radius: 4px;
  @include sgMarginBottomTiny;
}