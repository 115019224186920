@import "../../../shared/styles/sg.scss";
@import "../../../shared/styles/mq.scss";

.item + .item {
  margin-top: var(--sg-spacing-small);
}

.inner {
  display: block !important;
}

.inner > .labels {
  display: block !important;
}

.item > .header {
  cursor: pointer;
  display: flex;

  border-radius: 4px;
  border: 1px solid var(--grey-200);
}

.item .info {
  padding: 10px 5px;
  flex: 1 1 100%;
}
.item .info .amount {
  min-width: 72px;
}

.item .expander {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 35px;
  border-left: 1px solid #eee;
}

.item .expander .icon {
  width: 10px;
  height: 10px;
  border-right: 2px solid var(--grey-900);
  border-bottom: 2px solid var(--grey-900);
  transform: rotate(45deg);
  transition: transform 0.5s ease-out;
}
.item.selected .expander .icon {
  transform: rotate(225deg);
}


.item > .header .amount {
  @include sgFontBold;
  display: inline-block;
  margin-right: 0.5em;
}

.item > .header .date {
  display: inline-block;
}

.item > .header .status {
  display: inline-block;
  font-family: "AbelPro-Bold";
  margin-right: 0.5em;
}

.item > .header .invoiceNr {
  color: var(--grey-400);
}
.item > .header .invoiceNr::before {
  content: "#";
}

.item.success > .header .status {
  color: var(--accent-green-600);
} 

.item.error > .header .status {
  color: #a00;
}

.item.error .header {
  border-bottom: 1px solid #eee;
}

.item.error .errorRow {
  margin-top: var(--sg-spacing-small);
}

.item > .body {
  display: none;  
  user-select: none;

  button {
    width: 100%;
  }
}

.item.selected > .body {
  display: block;
  width: 100%;
}
.item.selected > .body .Button {
  width: 100%;
  margin-left: 0;
}

.item > .body .footer {
  font-size: 12px;
  color: #aaa;
  border-top: 1px solid #eee;
  padding: 5px 5px;
  background-color: #f5f5f5;
}


.Loading {
  margin: 2em auto;
}

.invoiceTable {
  display: block;
  border-collapse: collapse;
  text-align: right;
  width: 100%;
  padding-top: var(--sg-spacing-small);
  @include sgTextSmall;
  margin-bottom: var(--sg-spacing-tiny);

  @include mq_narrow {
    font-size: 14px;
  }
  
  thead {
    @include sgTextFootnote;
    color: var(--grey-400);
  }

  tr {
    border-bottom: 1px solid var(--grey-200);

    td:first-child,
    th:first-child {
      text-align: left;
      white-space: normal;
      padding-right: var(--sg-spacing-small);
    }
  }
  
  tbody tr td,
  tbody tr th {
    padding: 10px 0;
    white-space: nowrap;
    width: 100%;
    color: var(--grey-900);
  }

  .products tr {
    border-bottom: 1px solid var(--grey-200);

    .deliveryTag {
      margin-left: var(--sg-spacing-tiny);
    }
  }
  .taxes tr:last-child {
    border-bottom: 1px solid var(--grey-200);
  }
  .total tr {
    border-bottom: none;
    
    .amount {
      @include sgFontBold;
    }
  }
}

.Button.moreButton {
  margin-top: var(--sg-spacing-tiny);
  margin-left: 0px;
}