@import "../styles/mq.scss";
@import "../styles/sg.scss";

.CommandWindow {
  width: 100%;
}
.CommandWindow > .inner {
  width: 450px;
  margin: 0px auto;
  font-size: 14px;

  /* We sometime need to absolutely position children to the window */
  position: relative;
}

/* On mobile, make it take 100% of height */
.mq-sm .CommandWindow {  
  min-height: 100%;
  & > .inner {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    & > .content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      // Default for forms with "fields" and "buttons" is for fields to push
      // the buttons all the way down.
      form, .form {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        p {
          text-align: center;
        }

        & > .fields {
          flex-grow: 1;
        }
      }
    }
  }
}

.mq-not-sm .CommandWindow > .inner {
  border-radius: 4px;
  box-shadow: 0 2px 30px 0 #EBEBEB;
  border: solid 1px var(--grey-200);
  background-color: var(--white);
}

.mq-sm .CommandWindow > .inner {
  width: 100%;
  font-size: 16px;
}

.CommandWindow.s-active {
  pointer-events: all;
}

.CommandWindow.s-inactive {
  opacity: 0.5;
}

.CommandWindow.s-headless > .inner > .header {
  display: none;
}

.CommandWindow.s-invisible {
  visibility: hidden;
  pointer-events: none;
}

.CommandWindow > .inner > .content {
  width: 100%;
  padding: 0px var(--sg-spacing-small) var(--sg-spacing-small) var(--sg-spacing-small);
  @include mq_notNarrow {
    padding: 0px 48px 48px 48px;
  }

  form, .form {
    p {
      text-align: center;
    }
    
    & > p:first-child {
      margin-top: 0;
    }
  }
}

.mq-sm .CommandWindow.s-headless {
  min-height: 100vh;
}

.CommandWindow.s-headless > .inner > .content {
  padding-top: var(--sg-spacing-big);
}

.CommandWindow form {
  width: 100%;
}

.CommandWindow .fields p:first-child {
  margin-top: 0px;
}
.CommandWindow .fields > p {
  text-align: center;
}

.CommandWindow form > .buttons,
.CommandWindow .form > .buttons {
  margin-top: var(--sg-spacing-normal);
  display: flex;
  justify-content: space-between;
  column-gap: var(--sg-spacing-small);
  
  // If there isn't enough horizontal space
  flex-wrap: wrap;
  row-gap: var(--sg-spacing-small);

  & * {
    flex: 1 1;
  }
}

/* On mobile, buttons should appear on top of each other with some margin */
.mq-sm .CommandWindow form > .buttons,
.mq-sm .CommandWindow .form > .buttons {
  flex-wrap: wrap;

  & > * {
    flex-basis: 100%;
  }
}

.CommandWindow > .inner > .content > .status {
  text-align: center;
}
.CommandWindow > .inner > .content > .status .Loading {
  margin: 0px auto;
}

.CommandWindow .status h4 {
  font-size: 1.25em;
  margin-bottom: 0.25em;
}
.CommandWindow .status p {
  margin: 0px auto 1em;
}

// Centering window content is only useful in mobile as desktop will
// display the window in a centered modal
@include mq_narrow {
  .CommandWindow.centeredContent .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}