@import "../../shared/styles/mq.scss";
@import "../../shared/components/forms/sgForm.scss";

.module .creditCardElement {
  @include sgInput;
  padding: 17px 16px 15px 16px;

  &.stripeFocused {
    @include sgInputAccentBorder;
    @include sgInputAccentOutline;
    @include sgInputAccentColor;
  }
  &.stripeErrored {
    @include sgInputErrorBorder;
  }

  &.stripeFocused.stripeErrored {
    @include sgInputErrorOutline;
  }
}

.module {
  p:last-of-type {
    margin-bottom: var(--sg-spacing-tiny);
  }

  .stripeElement {
    min-height: 300px;
  }

  :global(.FormError) {
    margin-top: var(--sg-spacing-tiny);
  }
}

// StripeElement
// StripeElement--invalid
// StripeElement--focus
// StripeElement--empty
