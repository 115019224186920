@import "../shared/styles/mq.scss";

.outOfStockContainer {
  background-color: var(--grey-100);
  border-radius: 4px;

  & > div {
    padding: 12px 30px 10px;
    @include mq_notNarrow {
      padding: 12px 50px 10px;
    }

    color: var(--grey-400);

    & + div {
      border-top: 1px solid var(--grey-200);
    }
  }
}