@import "../../styles/sg.scss";

.module {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  color: var(--primary-blue-700);
  background-color: var(--white);
  border-color: var(--primary-blue-700);
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  user-select: none;
}

.number {
  position: relative;
  width: 57%;
  height: 100%;
  text-align: center;

  .nr {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    padding-top: 8px;
    outline: none;
    border: none;
    background: none;
    @include sgFontNormal;
    @include sgTextLarge;
    margin: 0;

    @include putInCenter;
  }

  .additionnalInfo {
    position: absolute;
    bottom: var(--sg-spacing-tiny);
    width: 100%;

    font-size: 12px;
    line-height: 12px;
  }
}
