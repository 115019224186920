/*
.item.sales-cart-list-item {
  height: auto;
  padding: 10px 15px;
  margin-bottom: 5px;
  display: flex;
}
*/

.sales-cart-list-item .sku {
  font-family: 'Roboto Mono', monospace;
  white-space: nowrap;
  font-size: 11px;
  display: block;
  color: #555;
}
.sales-cart-list-item .subscription, 
.sales-cart-list-item .period {
  display: none;
}

.sales-cart-list-item.u-subscription .subscription {
  display: inline-block;
  font-weight: bold;
  margin-right: 0.5em;
} 
.sales-cart-list-item.u-subscription .period {
  display: inline-block;
}

.sales-cart-list-item .f-subtotal {
  width: 100px;
}

.sales-cart-list-item .f-price {
  width: 100px;
}

.sales-cart-list-item .f-quantity {
  width: 100px;
  text-align: center;
}


/*
.sales-cart-list-item .f-quantity {
  display: block;
  white-space: nowrap;  
}

.sales-cart-list-item .f-subtotal {
  margin-left: 1em;
}

.sales-cart-list-item .f-subtotal::before {
  content: "≫";
  padding-right: 0.5em;
}



.sales-cart-list-item .currency {
  color: #777;
  margin-left: 0.5em;
}
*/