@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";

.module {
  --sep-color: #e9e9e9;

  padding-bottom: var(--sg-spacing-normal);

  display: block;
  @include mq_notNarrow {
    display: flex;

    & > .info {
      flex: 1 1 100%;
      margin-right: 25px;
      max-width: 730px;
    }
  }
  justify-content: space-around;
  max-width: 1200px;
  margin: 0px auto;
}

.item > .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item > .inner .media {
  position: relative;
  flex: 0 0 160px;
  max-width: 160px;
  height: 160px;

  border-radius: 4px;
  display: flex;
  align-items: center;

  &.useBackground {
    align-items: center;
    background-image: url("/assets/woodtable-bg.jpg");
    background-size: cover;
    background-position-y: bottom;

    .BackgroundImage {
      margin-top: var(--sg-spacing-tiny);
      height: 150px !important;
    }
  }
}

.errorOverlay {
  margin: var(--sg-spacing-big);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  p {
    color: var(--white);
    margin: 0;
  }
  button {
    max-width: 200px;
  }
}


@mixin sectionTitle {
  text-transform: uppercase;
  @include sgFontBold;
  font-size: 11px;
  letter-spacing: 1.33px;
  color: var(--grey-400);
}

.section {
  border-radius: 4px;
  padding: var(--sg-spacing-small);
  @include mq_notNarrow {
    padding: var(--sg-spacing-normal);
  }

  .header {
    .sectionTitle {
      @include sectionTitle;
      margin-bottom: var(--sg-spacing-small);

      @include mq_narrow {
        text-align: center;
      }
    }
  }

  .items {
    .item {
      border-top: 1px solid var(--sep-color);
      padding: var(--sg-spacing-normal) 0;

      &.withOverlay {
        padding: var(--sg-spacing-tiny) 0;
      }

      .hideWhenInvalid {
        visibility: hidden; // retain element's space
      }
    }
  }

  .itemsFooter {
    border-top: 1px solid var(--sep-color);

    padding-top: var(--sg-spacing-small);
    @include mq_notNarrow {
      padding-top: var(--sg-spacing-normal);
    }

    button {
      width: 100%;
    }
  }
}

.section.subscriptionSection {
  background-color: var(--grey-100);
}

.section.oneTimeOrders {
  background-color: var(--grey-100);

  .items {
    .item {
      .inner .info .title {
        color: var(--grey-900);
      }
    }
  }
}

.section + .section {
  margin-top: var(--sg-spacing-small);
}

.items .item .inner {
  .media {
    order: 2;
  }
  .info {
    order: 1;
    width: 100%;

    .title {
      @include sgTextLead;
      color: var(--grey-900);
      margin-bottom: var(--sg-spacing-small);
    }

    table {
      width: 100%;
      text-align: left;
      margin-top: var(--sg-spacing-smaller);
      
      thead {
        @include sgFontBold;
        text-transform: uppercase;
        color: var(--grey-400);
        font-size: 11px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.33px;
      }

      tbody td {
        @include sgFontBold;
      }
    }

    .itemButtons {
      display: flex;
      margin-top: var(--sg-spacing-small);

      .incrDescrButtons {
        display: inline-block;

        button {
          width: 32px;
          padding: 0;

          span {
            @include putInCenter;
          }
        }
        
        button:first-child {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        button:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      & > .incrDescrButtons + button,
      & > button + button {
        margin-left: var(--sg-spacing-small);
      }

      button {
        display: inline-block;
        width: unset;
        @include mq_narrow {
          width: 100%;
        }
      }
    }
  }
}

.subscriptionSection .items .item {
  @include mq_notNarrow {
    // Smaller padding to compensate images with transparent bg
    padding: 10px 0;
  }
}

.cartTotal {
  margin-bottom: var(--sg-spacing-small);

  @include mq_narrow {
    margin-top: 30px;
    margin-bottom: var(--sg-spacing-normal);
    text-align: center;
  }

  &.mobile {
    @include mq_notNarrow {
      display: none;
    }
  }
  &.desktop {
    @include mq_narrow {
      display: none;
    }
  }

  .title {
    @include sgTextLarge;
    color: var(--grey-900);
    margin-bottom: var(--sg-spacing-tiny);
  }
  .subtitle {
    @include sgTextSmall;
    @include sgFontNormal;
    text-transform: uppercase;
    color: var(--grey-400);
    margin-bottom: var(--sg-spacing-tiny);
  }
  .subtotal {
    @include sgTextLarge;
    color: var(--grey-900);
    margin-bottom: 0;
  }
  .freeShipping {
    @include sgFontNormal;
    color: var(--secondary-gold-600);
    font-size: 18px;
    line-height: 1.67;
  }
}

.sideBar {
  width: 100%;
  @include mq_notNarrow {
    max-width: 350px;
  }

  .fixedDesktop {
    @include mq_notNarrow {
      position: sticky;
      top: 0px;
    }
  }

  button {
    display: block !important;
    margin-bottom: var(--sg-spacing-small);
  }

  .helptext {
    @include mq_narrow {
      text-align: center;
    }

    .charge {
      @include sgTextSmall;
      margin-bottom: var(--sg-spacing-small);
      color: var(--grey-400);
    }
  }

  .disclaimer {
    @include sectionTitle;
    line-height: normal;
    margin-bottom: var(--sg-spacing-small);
    color: var(--grey-400);
    padding: 12px 0;
    border-top: 1px solid var(--grey-100);
    border-bottom: 1px solid var(--grey-100);
  }
}

// Display the items in a more mobile like way from 1000px downward
@include mediaQueryMaxWidth(1100px) {
  .items .item {
    .inner {
      display: block;
      text-align: center;

      .media {
        margin: 0 auto;
        margin-bottom: var(--sg-spacing-normal);
      }

      .info {
        margin: 0;

        table {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
  
        .itemButtons {
          white-space: nowrap;
        }
      }
    }
  }
}
