.WindowContainer {
  position: absolute;
  z-index: 100;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.WindowContainer > .top {
  width: 100%;
  min-height: 25px;
  height: 25px;
  flex: 1 0 25px;
}

.WindowContainer > .bottom {
  width: 100%;
  min-height: 25px;
  height: 25px;
  flex: 1 0 25px;
}

/* No top/bottom in mobile as it always takes all the vertical space */
.mq-sm .WindowContainer > .top, 
.mq-sm .WindowContainer > .bottom {
  display: none;
}

.WindowContainer .CommandWindow {
  flex: 0 0 auto;
}

.WindowContainer.s-active {
  pointer-events: all;
}

.WindowContainer.s-active {
  position: fixed;
  z-index: 499;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #ffffffc7;
  pointer-events: all;  
  transition: background-color 0.3s ease-out;
}

.mq-sm .WindowContainer {
  position: sticky;  
  -webkit-overflow-scrolling: touch;
}

.mq-sm .WindowContainer.s-active {
  background-color: #fff;
  left: unset;
  top: unset;
  right: unset;
  bottom: unset;
}

.WindowContainerFooter {
  margin-top: var(--sg-spacing-normal);
}
.mq-sm .WindowContainerFooter {
  margin-top: 0;
  padding: var(--sg-spacing-small);
  padding-top: 0;
}
.mq-sm .WindowContainerSeparator {
  border-top: 1px solid var(--grey-100);
  margin-bottom: var(--sg-spacing-small);
}