@import "../styles/sg.scss";

.themeBlue {
  color: var(--primary-blue-700);

  .photo {
    border-color: var(--primary-blue-700);
  }
}
.themeBlack {
  color: var(--grey-900);

  .photo {
    border-color: var(--grey-900);
  }
}
.themeSliding {
  .photo {
    width: 180px;
    height: 180px;
    border-radius: 180px;
  }
  .quote {
    @include sgTextLead;
    margin-top: 2em;
  }
  .name {
    @include sgTextNormal;
    margin-bottom: 0;
  }
  .title {
    max-width: none;
  }
}


.photo {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  background: #222;
  margin: auto;
  border: 8px solid;
}

.stars {
  margin-top: 1em;
}

.quote:before {
  content: '"';
}
.quote:after {
  content: '"';
}


.name {
  display: block;
  @include sgFontBold;
  @include sgTextLead;
  margin-bottom: 0;
}
.title {
  display: inline-block;
  @include sgTextSmall;
  max-width: 300px;
  color: var(--grey-700);
}

// Changes specifics to the white theme used in signup layout
.themeWhite {
  color: var(--white);

  .photo {
    border-color: var(--white);
    border-width: 6px;
    width: 136px;
    height: 136px;
  }

  .name {
    font-size: 20px;
  }

  .title {
    color: var(--white);
    @include sgTextNormal;

    max-width: 350px;
  }
}