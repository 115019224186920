@import "../../../shared/styles/sg.scss";
@import "../../../shared/styles/mq.scss";

.module {
  &.disabled {
    pointer-events: none;
    opacity: 0.2;

    .instructions {
      display: none;
    }
  }

  p {
    margin-bottom: var(--sg-spacing-small);
  }

  .header {
    display: flex;
    margin-top: 56px;
    border-bottom: 1px solid var(--grey-200);
    margin-bottom: 10px;

    .title {
      flex-grow: 1;
      @include sgTextLead;
      color: var(--grey-900);
      padding-bottom: 4px;
      margin: 0;
    }
    .icon {
      flex: 0 0;
    }
  }

  :global(.helptext) {
    color: var(--grey-500);
  }

  .instructions {
    display: none; // Get rid of it?

    margin-top: 20px;
    background-color: #B09F59;
    color: #fff;
    padding: 10px 60px;

    @include mq_narrow {
      padding: 10px 30px;
    }

    .InfoIcon {
      display: inline-block;
      margin-right: 0.5em;
      width: 18px;
      height: 18px;
      border: 1px solid #fff;
      border-radius: 18px;
      position: relative;
      vertical-align: text-bottom;

      .InfoIcon1 {
        position: absolute;
        width: 2px;
        height: 6px;
        background: #fff;
        left: 50%;
        margin-left: -1px;
        top: 3px;
      }
      .InfoIcon2 {
        position: absolute;
        width: 2px;
        height: 2px;
        background: #fff;
        left: 50%;
        margin-left: -1px;
        top: 11px;  
      }
    }
  }
}