@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.module {
}

.item {
  position: relative;
  min-height: 56px;

  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px var(--grey-200);

  margin-bottom: var(--sg-spacing-normal);

  &.selected {
    border: solid 1px var(--primary-blue-300);
    background-color: var(--primary-blue-200);
  }
}
.item.clickable {
  cursor: pointer;
}
.settings .item {
  @include mq_narrow {
    padding: 16px;

    .actions {
      min-height: 72px;
    }
  }
  @include mq_notNarrow {
    display: flex;
    align-items: center;

    padding: 24px;

    .innerWithoutLeftFlag {
      flex-grow: 1;
    }
  }
}

.item .innerWithoutLeftFlag {
  display: flex;
  align-items: center;
}

.disabled {
  cursor: wait;
}
.disabled .flags {
  opacity: 0.5;
}

.item .texts {
  flex: 1 1 100%;
  width: calc(100% - 35px);

  .title {
    width: 100%;
    @include sgTextNormal;
    @include sgFontBold;
    margin: -2px 0;

    // For long account names:
    overflow: clip;
    text-overflow: ellipsis;
    max-width: 245px;
  }
  .subtitle {
    width: 100%;
    color: var(--grey-400);
    letter-spacing: 1.33px;
    font-size: 11px;
    line-height: 11px;
    @include sgFontBold;

    // For long account names:
    overflow-inline: clip;

    & > * {
      display: inline-block; // So they wrap in their container
      margin-top: -3px; // For alignment with text
      // margin-bottom: 4px; // Usefull when they wrap
      margin-right: 4px;
    }

    .label {
      text-transform: uppercase;
    }

    margin-bottom: -4px; // Undo the wrapping margins of children
  }
}
.settings .item .texts {
  align-self: flex-end;
}

.item .flags {
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;

  img {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 1;
  }
}

.settings .item .flags {
  @include mq_notNarrow {
    margin-right: 20px;
  }
  @include mq_narrow {
    position: absolute;
  }
}

.item .actions {
  font-size: 1px;
  flex-basis: 24px;
  
  @include mq_notNarrow() {
    flex-basis: 100px; // 2 icons + space between
    flex-shrink: 0;

    div {
      float:right;

      & > svg + svg {
        margin-left: var(--sg-spacing-normal);
      }
    }
  }
  @include mq_narrow {
    div > svg + svg {
      margin-top: var(--sg-spacing-normal);
    }
  }
}