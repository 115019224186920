@import "../../styles/sg.scss";

.module {
  // How some breathing space around
  margin: var(--sg-spacing-normal);
  z-index: 1;
  
  &.center {
    @include putInCenterOfViewport;

    margin: 0;
  }
}