.ChargesPanel .Table {
  table-layout: fixed;
  margin: auto;
}
.ChargesPanel .Table thead tr td {
  letter-spacing: 1px;
  border: none;
  width: 20%;
}
.ChargesPanel .Table.mode-table td {
  padding: 5px 10px;
}

.ChargesPanel .Button.moreButton {
  margin-top: var(--sg-spacing-small);
}

.ChargesPanel .f-result {
  white-space: nowrap;
  width: 10%;
}
.ChargesPanel .f-charge {
  font-family: "AbelPro-Bold";
  text-align: right;
  white-space: nowrap;
}
.ChargesPanel .f-charge .money {
  display: inline-block;
  text-align: right;
}
.ChargesPanel .f-currency {
  text-align: center;
  white-space: nowrap;
}

.ChargesPanel .item.u-success .f-result {
  font-family: "AbelPro-Bold";
  color: var(--accent-green-600);
}
.ChargesPanel .item .f-result {
  font-family: "AbelPro-Bold";
  color: #FB3504;
}

.ChargesPanel .Table thead .f-description {
  width: 100%;
}

.ChargesPanel .f-card,
.ChargesPanel .f-date {
  white-space: nowrap;
}
.ChargesPanel .f-date {
  text-align: right;
}

.ChargesPanel .f-card {
  text-align: center;
}

.ChargesPanel .f-order {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ChargesPanel .expander {
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 0.5em;
  display: inline-block;
  vertical-align: text-top;
}

.ChargesPanel .expander .icon {
  position: absolute;
  top: 0px;
  width: 10px;
  height: 10px;
  border-right: 2px solid #777;
  border-bottom: 2px solid #777;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.ChargesPanel .u-expanded .expander .icon {
  top: 5px;
  transform: rotate(-135deg);
}
.ChargesPanel .content.u-expanded {
  border-bottom: 1px solid var(--grey-200);
}
.ChargesPanel .content.u-expanded .Button {
  margin-top: var(--sg-spacing-smaller);
  width: 100%;
}

.ChargesPanel .billing-charge-list-item.u-expanded {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ChargesPanel .billing-charge-list-item.u-expanded td {
  border-bottom: 1px solid #eee;
}

.ChargesPanel .Table .content {
  background-color: #fefefe;
  padding: 0px;
}

.ChargesPanel .Table .content {
  display: none;
}

.ChargesPanel .Table .content.u-expanded {
  display: table-row;
}

.ChargesPanel .Table .content .innerContent div.orderId {
  color: #999;
  padding-bottom: 0px;
  font-size: 12px;
}

.ChargesPanel .Table .innerContent {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: 1em;
}

.ChargesPanel .Table .item td {
  padding: 22px 10px 15px 10px !important;
  color: var(--grey-700);
  font-size: 16px;
}

.ChargesPanel .Table .content td {
  padding: 0px;
  border-bottom: none;
}

.ChargesPanel .Table.mode-table .item-error td {
  padding: 0;
}
.ChargesPanel .Table.mode-table .item-error td > div {
  margin: 10px 0;
}