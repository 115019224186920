
@import "../../shared/styles/sg.scss";
@import "../../shared/styles/links.scss";

:root {
  --header-height-desktop: 72px;
  --header-submenu-height: 58px;
  --account-header-height-desktop: 130px;
}

.relativeWrapper {
  height: var(--header-height-desktop);
  margin-bottom: var(--sg-spacing-normal);
}
.absoluteWrapper {
  --header-height-desktop: 72px;
  height: 0px;
}

.module {
  --header-submenu-height: 58px;
  --account-header-height-desktop: 130px;
  --header-border-color: var(--grey-100);
  --space-between-elements: 56px;

  width: 100%;
  max-width: var(--sg-desktop-max-width);
  // Center horizontally:
  margin: auto;

  background-color: var(--white);
  line-height: initial;

  .logo {
    width: 116px; // Fixed so the menu doesn't jump after the image loads
    margin-top: 5px;
    cursor: pointer;
  }

  // MENU LINKS COLOR
  .navigation :global(.Button), .userSpecifics path:global(.svg-shape) {
    @include sgFontNormal;
    @include sgTextSmall;
    margin: 0;
    text-transform: uppercase;
    @include sgMenuLinkLight;
    white-space: nowrap;
    
    &:hover{
      @include sgMenuLinkLightHover;
    }
    &:active{
      @include sgMenuLinkLightActive;
    }

    border: 0;
    border-bottom: 1px solid transparent;
    &.currentPage {
      border-bottom-color: var(--grey-900);
    }
  }
  // MENU LINKS POSITION
  .navigation {
    height: 100%;
    padding-top: 27px;
    flex-grow: 1; // Take all the horizontal space
    margin-bottom: -1px; // So buttons margin bottom overlaps

    :global(.Button) {
      display: inline-block;
      margin-left: var(--space-between-elements);
      
      // a get a border bottom when they are the current page
      padding-bottom: 19px; // So the borders coincide with the header border
      border-radius: 0;
    }
  }

  .userSpecifics {
    margin-left: var(--sg-spacing-small);
    @include putInCenter;

    & > div {
      @include putInCenter;

      & + div {
        margin-left: var(--sg-spacing-small);
      }
    }
  }

  .divider {
    --length: 40px;
    --half-length: calc(var(--length) / 2);
    --margin-left: calc(var(--space-between-elements) - var(--half-length));
    --margin-right: calc(var(--half-length) * -1);

    display: inline-block;
    margin-left: var(--space-between-elements);
    width: var(--length);
    height: 1px;
    margin: 0px var(--margin-right) 8px var(--margin-left);
    transform: rotate(-270deg);
    background-color: var(--header-border-color);
  }
}

// For use on dark backgrounds, when the header is not sticky (so: top of the page).
.module:global(.headerOnDarkBackground) {
  // grey-100 with 0.12 opacity
  --header-border-color: rgba(246, 246, 246, 0.12);

  width: 100%;
  line-height: normal;
  color: var(--white);
  z-index: 100;
  position: absolute;
  background-color: transparent;
  transition: background-color 0.5s ease-in-out;

  // MENU LINKS COLOR
  .navigation :global(.Button), .userSpecifics path:global(.svg-shape) {
    @include sgMenuLinkDark;

    &:hover {
      @include sgMenuLinkDarkHover;
    }
    &:active {
      @include sgMenuLinkDarkActive;
    }
  }
  .navigation :global(.Button).currentPage {
    // Special color on current page
    border-bottom-color: var(--white);
  }
  .userSpecifics button {
    // Button becomes white when on dark bg
    background-color: var(--white);
    border-color: var(--white);
    color: var(--primary-blue-700);
  }
}

// When user scrolls up and the header appears
.stickyModule {
  background-color: var(--white);
  color: #444;
}

.inner {
  height: var(--header-height-desktop);
  width: 100%;
  display: flex;
  align-items: center;
  max-width: var(--sg-desktop-max-width);
  margin: 0 auto;
  padding: 0 var(--sg-spacing-normal);

  border-bottom: 1px solid var(--header-border-color);
}

.SelectedAccount {
  margin-left: auto;
  cursor: pointer;
}

.cartSummary {
  @include putInCenter;
  margin-right: var(--sg-spacing-normal);
}
