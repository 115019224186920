@import "./mq.scss";

:root {
  --sg-desktop-max-width: 1920px;

  // Variables related to style guidelines.
  --sg-spacing-biggest: 80px;
  --sg-spacing-bigger: 56px;
  --sg-spacing-big: 40px;
  --sg-spacing-normal: 24px;
  --sg-spacing-small: 16px;
  --sg-spacing-smaller: 12px;
  --sg-spacing-tiny: 8px;
}

/**
 * Reusable mixins
 */

@function if-important($important){
  @return #{if($important, '!important', '')};
}

// Mixin to use in combination with component LoadBackgroundImage
@mixin sgBackgroundWholePage {
  position: absolute;
  z-index: 0; // Stay behind
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0s; // no transition when removing the "loaded" class
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @supports (-webkit-overflow-scrolling: touch) {
    // IOS doesn't recognise bg-size cover with fixed bg attachment
    background-attachment: scroll;
  }
  background-size: cover;
}
@mixin sgBackgroundWholePageLoaded {
  opacity: 1;
  transition: opacity 0.5s ease-out; // transition when image is loaded
}

@mixin sgTextLarge($withMargin: true) {
  font-size: 36px;
  font-weight: 200;
  line-height: 43px;
  letter-spacing: -1.16px;
  @if $withMargin {
    margin-bottom: var(--sg-spacing-normal);
  }
  font-stretch: normal;
  font-style: normal;
}
@mixin sgTextLead($withMargin: true) {
  font-size: 26px;
  line-height: 35px;
  @if $withMargin {
    margin-bottom: var(--sg-spacing-normal);
  }
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
@mixin sgTextNormal($withMargin: true) {
  font-size: 18px;
  line-height: 30px;
  @if $withMargin {
    margin-bottom: var(--sg-spacing-normal);
  }
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
@mixin sgTextSmall($withMargin: true) {
  font-size: 16px;
  line-height: 24px;
  @if $withMargin {
    margin-bottom: var(--sg-spacing-normal);
  }
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
@mixin sgTextTiny($withMargin: true) {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin sgTextFootnote {
  font-size: 11px;
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.33px;

  @include sgFontBold;
  text-transform: uppercase;
}

@mixin sgFontBold($important: false) {
  font-family: AbelPro-Bold if-important($important);
  font-weight: lighter if-important($important);
}
@mixin sgFontNormal($important: false) {
  font-family: AbelPro if-important($important);
  font-weight: normal if-important($important);
}

@mixin sgDefaultTextStyle {
  color: var(--grey-700);
  @include sgFontNormal;
  @include sgTextSmall;
  margin: 0;
}

@mixin sgMarginBottomNormal {
  margin-bottom: 28px !important;
}
@mixin sgMarginBottomTiny {
  margin-bottom: 8px !important;
}

@mixin sgEllipsis($visible-lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $visible-lines;
  -webkit-box-orient: vertical;
}

@mixin sgSubpanelTitle {
  // Keeps the same line-height as the one defined in the panel .title
  text-transform: uppercase;
  color: var(--grey-400);
  font-size: 11px;
  @include sgFontBold;
  letter-spacing: 1.33px;
}

@mixin sgGuideHeaderTitle {
  @include sgTextLarge;
  margin: 6px auto 8px auto;
  text-align: center;
  color: var(--primary-blue-700);
}

@mixin putInCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin putInCenterOfViewport {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  @include putInCenter;
}

@mixin sgTitle1 {
  @include sgFontNormal;
  font-size: 90px;
  line-height: 80px;
  letter-spacing: -1.86px;
  margin: 0;
  
  @include mq_narrow {
    font-size: 62px;
    line-height: 60px;
    letter-spacing: -1.8px;
  }

  @include mediaQueryMaxWidth(320px) {
    font-size: 56px;
    line-height: 60px;
    letter-spacing: -1.8px;
  }
}
@mixin sgTitle2 {
  @include sgFontNormal;
  font-size: 80px;
  line-height: 69px;
  letter-spacing: -0.57px;
  margin: 0;

  @include mq_narrow {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -1.24px;
  }

  @include mediaQueryMaxWidth(320px) {
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -1.24px;
  }
}
@mixin sgTitle3 {
  @include sgFontNormal;
  font-size: 50px;
  line-height: 54px;
  letter-spacing: -0.45px;
  margin: 0;
  
  @include mq_narrow {
    font-size: 42px;
    line-height: 48px;
    letter-spacing: -0.38px;
  }

  @include mediaQueryMaxWidth(380px) {
    font-size: 38px;
    line-height: 42px;
  }
}
@mixin sgTitle4 {
  @include sgFontNormal;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.3px;
}
@mixin sgTitle5 {
  @include sgFontNormal;
  font-size: 28px;
  line-height: 30px;
  letter-spacing: 4.67px;

  @include mq_narrow {
    font-size: 24px;
    line-height: 25px;
    letter-spacing: 4px;
  }
}
@mixin sgTitle6 {
  @include sgFontNormal;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 4.1px;

  @include mq_narrow {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 3.42px;
  }
}

// Horizontal scroll with no overflow, used in submenu and in mobile in general
// where horizontal space is lacking.
@mixin horizontalScroll {
  // Side by side with an overflow to the right
  overflow-x: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  white-space: nowrap;
}

@mixin unbuttonize {
  // This removes styles added by default to button elements.
  // For when something should semantically be a button,
  // but isn't buttony in appearance.
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none; // Just in case we missed anything.
}