@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";

.MultiSubscriptionForm {
  /*text-align: center;*/
}

.MultiSubscriptionForm .fieldError {
  text-align: center;
  font-size: 1em;
  background-color: blue;
  color: #fff;
  padding: 5px;
  max-width: 400px;
  margin: auto;
}

.MultiSubscriptionForm .frequencyWeeksSelect {
  display: none;
  margin-bottom: 2em;
  border-bottom: 1px solid #eee;  
}
.MultiSubscriptionForm .frequencyWeeksSelectSimple {
  margin-top: var(--sg-spacing-small);
}

.MultiSubscriptionForm.u-frequency-select .frequencyWeeksSelect {
  display: block;
  text-align: center;
}
.MultiSubscriptionForm.u-frequency-select .frequencyWeeksSelect > .inner {
  max-width: 400px;
  margin: auto;
}

.MultiSubscriptionForm.u-frequency-select .frequencyWeeksSelect .label {
  margin-bottom: 0.5em;
}
.MultiSubscriptionForm .productSummary {
  border-radius: 4px;
  background-color: var(--grey-100);
  padding: 16px 16px 12px;

  font-size: 16px;
  line-height: 1.5;
  color: var(--grey-400);
}

.MultiSubscriptionForm .products {
  .product {
    border-radius: 4px;
    background-color: var(--primary-blue-100);
  }
}

.MultiSubscriptionForm .totalInfo {
  color: var(--grey-700);
}

.MultiSubscriptionForm .surcharge {
  @include sgTextFootnote;
  color: var(--grey-400);
}

.MultiSubscriptionForm .disclaimer {
  margin-top: var(--sg-spacing-tiny);
}


.MultiSubscriptionForm .inner > .info {
  display: flex;
  
  @include mq_narrow {
    flex-direction: column-reverse;
  }

  .title {
    @include sgTextLarge;
    color: var(--primary-blue-700);
    margin-bottom: 8px;
  }

  .assistant {
    width: 168px;
    // Width not enough as ancester is flexible
    min-width: 168px;
    max-width: 168px;

    @include mq_narrow {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  
    .NumberStepperField {
      width: 100%;
      color: var(--primary-blue-700);
      background-color: var(--white);
  
      .NumberStepper {
        width: 100%;
        height: 75px;
      }
    }
  
    button {
      width: 100%;
      margin-top: 12px;
      background-color: unset !important;
    }
  
    p {
      text-transform: uppercase;
      @include sgFontBold;
  
      font-size: 11px;
      line-height: normal;
      letter-spacing: 1.33px;
      color: var(--primary-blue-700);
      margin: 0;
      margin-top: 18px;
    }
  }

  .assistant + .texts {
    @include mq_notNarrow {
      margin-left: 40px;
    }
    
    .info {
      @include sgTextNormal;
      margin-bottom: 8px;
      color: var(--grey-900);
    }
    .description p {
      @include sgTextSmall;
      margin: 0;
      color: var(--grey-700);
    }
  }
}
