@import "../styles/sg.scss";

.module {

  & > div {
    // Select
    width: 72px;

    :global(.select-value) {
      color: var(--grey-900) !important;
      text-transform: uppercase;
    }
    svg path:global(.svg-shape) {
      fill: var(--grey-900) !important;
    }
  }
}

.withIcon {
  // Used in header

  // lower a bit to compenssate uppercase letters
  :global(.select-value) {
    margin-top: 1px;
  }

  :global(.select-value),
  :global(.select-option) {
    @include sgFontBold;
  }
}

.module.withIcon.darkBackground {
  // Override Select's darkBackground
  & > div {
    border-color: var(--white) !important;
    background-color: transparent !important;

    &:hover {
      border-color: transparent !important;
      background-color: rgba(255,255,255,0.2) !important;
    }

    // White text and icon by default
    :global(.select-value),
    :global(.select-option) {
      color: var(--white) !important;
    }
    svg path:global(.svg-shape) {
      fill: var(--white) !important;
    }

    // Revert to blue text and icon when deploying the options
    &:global(.select-selecting) {
      background-color: var(--white) !important;
      border-color: var(--primary-blue-700) !important;
      
      :global(.select-value),
      :global(.select-option) {
        color: var(--primary-blue-700) !important;
      }
      svg path:global(.svg-shape) {
        fill: var(--primary-blue-700) !important;
      }
    }
  }
}

.textual {
  // Used in footer
  display: flex;
  align-items: center;
  gap: 5px;

  & > div {
    width: 90px; // Just wide enough for English and Deutsch
  }
}
