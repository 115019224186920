@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";


@include mediaQueryMaxWidth(310px) {
  p.info {
    display: none !important;
  }
  button.cart {
    flex-basis: 100% !important;
  }
}