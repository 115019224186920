
.module {
  & > div {
    display: inline-flex;

    &::before {
      // By default, the lib component uses the same colour with an opacity of
      // 0.1, but we want a specific colour here.
      background-color: var(--background-color);
      opacity: 1;
    }
  }
}

span + .module {
  margin-left: var(--sg-spacing-tiny);
}


// In a form, if we are a direct child we can assume data is loading for the 
// whole form and this loader should be centered horizontally.
// Same for command window inner content with no form
:global(.form) > .module > div,
:global(.CommandWindow) > :global(.inner) > :global(.content) > .module > div {
  display: flex;
  margin: auto;
}