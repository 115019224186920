@import "./mq.scss";
@import "./colors.scss";
@import "./sg.scss";
// Global button styling
@import "../components/Button.scss";

// Generic
.sg-bold {
  @include sgFontBold;
  font-weight: lighter;
}

.expand {
  width: 100%;
}

.u-centered {
  display: block;
  text-align: center;
}

.centeredWithinViewport {
  @include putInCenterOfViewport;
}

.u-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.u-limitWidth {
  max-width: 500px;
  margin: auto;
}

.u-limitTextWidth {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.u-no-break {
  white-space: nowrap;
}

.u-medium {
  max-width: 800px;
}

.u-short {
  max-width: 600px;
}
.u-shorter {
  max-width: 400px;
}
.u-shortest {
  max-width: 270px;
}
.u-auto-margins {
  margin: auto !important;
}

.u-max-width {
  max-width: 1440px;
  margin: auto;
}

.u-max-inner-width {
  max-width: 1200px;
  margin: auto;
}

.u-whitespace {
  margin-top: 5em;
  margin-bottom: 5em;
}
.u-whitespace-small {
  margin-top: 1.5em;
}

.u-padded {
  padding: 20px;
}
.u-inner-padded {
  padding: 20px;
}
.u-no-pad-top {
  padding-top: 0px !important;
}

.u-no-margin {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.u-rounded {
  border-radius: 4px;
}

.u-bottom-margin {
  margin-bottom: 16px !important;
}

.u-bottom-margin-short {
  margin-bottom: 8px !important;
}
.u-bottom-margin-shorter {
  margin-bottom: 4px !important;
}

.u-bottom-margin-large {
  margin-bottom: 20px !important;
}

.u-underline {
  text-decoration: underline;
}

.sg-title-1 {
  @include sgTitle1;
}
.sg-title-2 {
  @include sgTitle2;
}
.sg-title-3 {
  @include sgTitle3;
}
.sg-title-4 {
  @include sgTitle4;
}
.sg-title-5 {
  @include sgTitle5;
}
.sg-title-6 {
  @include sgTitle6;
}

.sg-text-large {
  @include sgTextLarge;
}
.sg-text-lead {
  @include sgTextLead;
}
.sg-text-normal {
  @include sgTextNormal;
}
.sg-text-small {
  @include sgTextSmall;
}
.sg-text-tiny {
  @include sgTextTiny;
}

.sg-font-bold {
  @include sgFontBold(true);
}
.sg-font-normal {
  @include sgFontNormal(true);
}

.sg-footnote {
  @include sgTextFootnote;
}

.sg-guide-header-title {
  @include sgGuideHeaderTitle;
}

.sg-mb-normal {
  @include sgMarginBottomNormal;
}
.sg-mb-tiny {
  @include sgMarginBottomTiny;
}

// Paragraphs
p {
  @include sgTextNormal;
}
p.sg-lead {
  @include sgTextLead;
}
p.sg-small {
  @include sgTextSmall;
}
p.sg-large {
  @include sgTextLarge;
}

// Images
img.u-auto-margins {
  display: block;
}

.mobileOnly {
  display: none;
  @include mq_narrow {
    display: inherit;
  }
}
.desktopOnly {
  @include mq_narrow {
    display: none;
  }
}

.invisible {
  visibility: hidden;
}
.noDisplay {
  display: none !important;
}

.clickable {
  cursor: pointer;
}

// Background images styles used by LoadBackgroundImage
.bgImageCentered {
  position: absolute;
  width: 100%;
  height: 100%;
  
  pointer-events: none;
  opacity: 0;
  transition: opacity 0s; // no transition when removing the "loaded" class
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: contain;

  // Almost all images have this border radius:
  border-radius: 4px;
}
.bgImageCover {
  background-size: cover;
}
.bgNoBorderRadius {
  border-radius: 0;
}
.bgImageLoaded {
  opacity: 1 !important;
  transition: opacity 0.5s ease-out !important; // transition when image is loaded
}