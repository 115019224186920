@import "./styles/mq.scss";
@import "./styles/sg.scss";

.module {
  position: fixed;
  z-index: 600; // Modal windows stack starts with a z-index of 500 (cf. MODAL_ZINDEX_START)
  
  bottom: calc(var(--shop-footer-height) + var(--sg-spacing-normal));
  width: 100%;
  @include mq_notNarrow {
    max-width: 500px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0px var(--sg-spacing-normal);
  pointer-events: none;
}

.notification + .notification {
  margin-top: var(--sg-spacing-tiny);
}

.notification {
  pointer-events: auto;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--grey-900);
  background-color: var(--white);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  
  &.simple {
    max-width: 430px;
    padding: var(--sg-spacing-smaller) var(--sg-spacing-small);
    gap: var(--sg-spacing-smaller);
  }

  &.withPhoto {
    position: relative;
    max-width: 330px;
    min-height: 83px;
    padding: var(--sg-spacing-tiny);
    gap: 15px;
  }
}

.message {
  color: var(--grey-700);

  @include sgFontNormal;
  @include sgTextSmall;
  margin: 0px;
  flex-grow: 1;

  & > span {
    display: block;
    line-height: 15px;

    &:first-child {
      @include sgTextNormal;
      @include sgFontBold;
      line-height: 18px;
      // margin: 0;
      margin-bottom: 5px;
    }
  }
}

.icon {
  width: var(--icon-side);
  height: var(--icon-side);

  svg {
    height: var(--icon-side);
    width: var(--icon-side);
  }
}

.simple {

  .icon {
    --icon-side: 28px;
  }

}

.withPhoto {

  .photo {
    width: 67px;
    height: 67px;

    padding: var(--sg-spacing-tiny);
    border-radius: 8px;
    border: solid 1px var(--grey-200);

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
  }

  .icon {
    --icon-side: 20px;
    position: absolute;
    z-index: 10;

    right: var(--sg-spacing-tiny);
    top: var(--sg-spacing-tiny);
    border-radius: 50px;
  }
  .message {
    color: var(--primary-blue-900);
    padding: 0;
  }
}