@import "../../../shared/styles/sg.scss";

.module {
  --space-between-columns: 10px;
  :global(.Button) {
    margin-top: var(--sg-spacing-small);
  }

  table thead tr td {
    padding-right: var(--space-between-columns);

    &:last-child {
      padding-right: 0;
    }
  }

  // Specifics
  .fStatus {
    @include sgFontBold;
    white-space: nowrap;
  }

  .fPackages {
    text-align: center;
    width: 30%;
    padding-right: 0;
  }

  .fRecipient {
    width: 100%;
  }

  .fType {
    text-align: right;
    padding-right: var(--space-between-columns);
  }

  .fDate {
    white-space: nowrap;
    text-align: right;
  }

  tbody .fRecipient,
  tbody .fDate {
    @include sgTextTiny;
    margin: 0;
  }
}

.main {
  @include sgTextSmall;

  & > td {
    // padding: 22px 10px 15px 10px; // Charges panel padding
    padding-top: 8px;
  }
}

.content {
  display: none;
  padding: 0px;
  font-size: 11px;
  letter-spacing: 1.33px;

  .innerContent {
    background: var(--white);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    
    div {
      border-bottom: 1px solid var(--grey-200);

      color: var(--grey-700);
      @include sgTextSmall;
      font-size: 14px;
      margin-bottom: unset;
      line-height: 39px;

      &.orderId {
        @include sgFontBold;
        color: var(--secondary-gold-600);

        padding-bottom: 0px;
      }
    }
  }
}

.content.uExpanded {
  display: table-row;
}

.item td {
  padding: 10px;
}

.content td {
  padding: 0px;
  border-bottom: none !important;
}

// TODO use svg
.expander {
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 0.5em;
  display: inline-block;
  vertical-align: text-top;
}

.expander .icon {
  position: absolute;
  top: 2px;
  width: 8px;
  height: 8px;
  border-right: 2px solid var(--grey-900);
  border-bottom: 2px solid var(--grey-900);
  transform: rotate(45deg); 
  transition: all 0.2s ease-out;
}

.uExpanded .expander .icon {
  top: 5px;
  transform: rotate(-135deg);
}