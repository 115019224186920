@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";


.module {
  position: relative;
  width: 32px;

  @include putInCenter;

  :global(svg.side-24px) {
    margin-left: 8px;
    margin-top: 4px;
  }

  .rounded {
    --cart-rounded-tag-size: 16px;

    position: absolute;
    top: calc(var(--cart-rounded-tag-size) / -2);
    right: calc(var(--cart-rounded-tag-size) / -2);

    width: var(--cart-rounded-tag-size);
    height: var(--cart-rounded-tag-size);
    background-color: var(--accent-red-600);
    border-radius: var(--cart-rounded-tag-size);
    text-align: center;
    padding-top: 1px;
    padding-left: 1px;

    @include sgFontBold;
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.33px;
    color: var(--white);

    &.bigger {
      --cart-rounded-tag-size: 19px;
      padding-top: 2px;
    }
  }
}