@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";


.choice {
  --border: 1px solid var(--grey-200);
  border-radius: 4px;
  border: var(--border);
  background-color: var(--white);
  cursor: pointer;

  .items {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .box {
      text-align: center;

      .image {
        height: 100px;
        @include mq_notNarrow {
          height: 120px;
        }
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &.pack6 {
          background-image: url("/assets/8packsEOL/6pack.jpg");
        }
        &.pack8 {
          background-image: url("/assets/8packsEOL/8pack.jpg");
        }
      }
      .image + .text {
        margin-top: 5px;
      }
    }
    .arrow {
      margin-bottom: 35px;
    }
  }

  .button {
    border-top: var(--border);
    padding: var(--sg-spacing-small);

    display: flex;
    flex-direction: row;

    @include sgTextNormal;
    margin: 0;
    @include sgFontBold;
    color: var(--primary-blue-700);

    & > div > div:last-child {
      flex-direction: column;
      align-items: start;
      margin-top: -10px;
      margin-bottom: -10px;

      span {
        @include sgFontNormal;
        @include sgTextTiny;
        margin: 0;
        margin-top: -5px;
        color: var(--grey-600);
      }
    }
  }

  & + .choice {
    margin-top: 14px;
  }
}