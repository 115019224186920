@import "../../styles/sg.scss";

.buttons {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  border-width: 1px;
  border-color: var(--primary-blue-700);

  &.vertical {
    border-left-style: solid;
    width: 43%;
  }
  &.horizontal {
    border-radius: 4px;
    border-style: solid;
    width: 100%;
  }
}

.incButton {
  cursor: pointer;
  position: absolute;
  border-width: 1px;

  @include putInCenter;

  .vertical & {
    top: 0;
    width: 100%;
    height: 50%;
    border-bottom-style: solid;
  }
  .horizontal & {
    width: 50%;
    height: 100%;
    border-right-style: solid;
    left: 0;
  }
}

.decButton {
  cursor: pointer;
  position: absolute;
  border-width: 1px;

  @include putInCenter;
  
  .vertical & {
    bottom: 0;
    width: 100%;
    height: 50%;
  }
  .horizontal & {
    width: 50%;
    height: 100%;
    right: 0;
  }
}
