
@import "../../styles/sg.scss";

.module {
  white-space: normal; // Make sure the text wraps if necessary
  text-align: left;
  color: var(--accent-red-600);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7em;
  letter-spacing: normal;

  @include sgFontNormal;
  // Small margin between input and error
  margin-top: 8px;

  &::first-letter {
    // Useful for server side messages which lack capitalization
    text-transform: capitalize;
  }
}

.darkBackground {
  background-color: var(--accent-red-600);
  padding: 3px 12px;
  color: var(--white);
  border-radius: 4px;
  @include sgMarginBottomTiny;
}

.globalFormError {
  margin-top: var(--sg-spacing-small);
  padding: var(--sg-spacing-small);
  padding-bottom: 14px;
  border-radius: 4px;
  background-color: var(--accent-red-200);

  text-transform: uppercase;
  @include sgFontBold;
  font-size: 14px;
  line-height: 1.7em;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.33px;
  color: var(--accent-red-600);

  display: flex;
  align-items: center;

  svg {
    margin-right: var(--sg-spacing-smaller);

    path {
      fill: var(--accent-red-600);
    }
  }
}