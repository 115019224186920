.CustomerSupport {
  position: fixed;
  z-index: 80;
  left: 0px;
  top: 0px;
  width: 100%;
  max-width: 400px;
  bottom: 0px;
  pointer-events: none;
}

.CustomerSupport.u-active {
  border-right: 1px solid #eee;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.32);
  pointer-events: all;
  background-color: #fff
}

.CustomerSupport > .inner {
  display: none;
  pointer-events: none;
}

.CustomerSupport.u-active > .inner {
  display: block;
  pointer-events: all;  
}


.CustomerSupport > .inner > .header {
  border-bottom: 1px solid #eee;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-between;
}

.CustomerSupport > .inner > .header .Button {
  margin-bottom: 0px;
}

.CustomerSupport > .inner > .header .title {
  font-size: 18px;
  font-family: "AbelPro-Bold";
  color: var(--grey-900);
}

.CustomerSupport > .inner > .header .icon {
  background-image: url(/assets/icons/question-line.svg);
  background-size: 20px;
  width: 20px;
  height: 20px;
}
.CustomerSupport > .inner > .header .buttons {
  height: 24px;
}

.CustomerSupport > .inner > .body {
  position: absolute;
  left: 0px;
  top: 50px;
  bottom: 0px;
  right: 0px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.CustomerSupport > .inner > .body > .SupportDoc > .body {
  margin-top: 32px;
}

.CustomerSupport .SupportDoc {
  padding: 16px;
}

.CustomerSupport.u-active .expander {
  display: none;
}

.CustomerSupport .expander {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: "AbelPro-Bold";
  cursor: pointer;
  position: absolute;
  z-index: 81;
  width: 30px;
  height: 100px;
  left: 0px;
  top: 50%;
  margin-top: -50px;
  background-color: var(--primary-blue-700);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.32);
  pointer-events: all;
}

.CustomerSupport .expander > .inner {
  transform: rotate(90deg);
}

.mq-sm .CustomerSupport .expander {
  display: none !important;
}

.CustomerSupport .TextSlice {
  margin-bottom:  1em;
}

.CustomerSupport .DividerSlice {
  margin: 2em 0px;
  width: 100%;
  height: 1px;
  background-color: #eee;
}

.CustomerSupport .NoteSlice {
  padding: 13px;
  background-color: var(--grey-100);
  border-radius: 4px;
}

.CustomerSupport .NoteSlice * {
  color: var(--grey-400);
  font-size: 16px;
  text-align: left;
  line-height: 1.5em;
  margin-bottom: 0px;
  margin-top: 0px;
}

.CustomerSupport .NoteSlice * a {
  color: var(--primary-blue-700);
  font-size: 16px;
  text-align: left;
  line-height: 1.5em;
  margin-bottom: 0px;
  margin-top: 0px;
}