@import "../../../shared/styles/sg.scss";

.module {
  vertical-align: top;

  .noTrackingNotice, .giftNotice, .complimentaryNotice {
    span {
      @include sgSubpanelTitle;
      line-height: 34px;
      padding-top: 5px;
    }
  }
}

.fContent a {
  display: block;
}

.uTracking .noTrackingNotice {
  display: none;
}

.complimentaryNotice,
.giftNotice {
  display: none;
}

.uComplimentary .complimentaryNotice,
.uGift .giftNotice {
  display: block;
}

div.refNumber {
  --color: var(--secondary-gold-600);

  span {
    @include sgSubpanelTitle;
    color: var(--color);
    margin-right: var(--sg-spacing-tiny);
  }

  svg {
    vertical-align: middle;

    path {
      fill: var(--color);
    }
  }
}
