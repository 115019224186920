.TextAreaInputField .TextAreaInput {
  /* Rectangle 8: */
  background-color: #FFFFF9;
  border: 1px solid #ccc;
  border-radius: 5px; 
  height: 25px;
  outline: none;
  padding: 8px 8px;
  width: 100%;
  color: #000;
  margin-bottom: 5px;
  line-height: 1.25em;
  font-size: 16px;
}

.TextAreaInputField .TextAreaInput.s-focused {
  border-color: var(--primary-blue-700);
}

.size-large .TextAreaInputField .TextAreaInput {
  min-height: 100px;
  background-color: #fff;
}

.TextAreaInputField .TextAreaInput.s-inactive {
  background: transparent;
  opacity: 0.5;
}

.TextAreaInputField .TextAreaInput::-webkit-input-placeholder { /* Chrome */
  color: #aaa;
}
.TextAreaInputField .TextAreaInput :-ms-input-placeholder { /* IE 10+ */
  color: #aaa;
}
.TextAreaInputField .TextAreaInput ::-moz-placeholder { /* Firefox 19+ */
  color: #aaa;
  opacity: 1;
}
.TextAreaInputField .TextAreaInput :-moz-placeholder { /* Firefox 4 - 18 */
  color: #aaa;
  opacity: 1;
}