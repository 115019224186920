@import "../styles/sg.scss";
@import "../styles/mq.scss";

.module {
  button {
    width: 100%;
    margin-bottom: 0 !important;
  }
}

:global(.productWidestMode) .module,
:global(.productWideMode) .module {
  .inputs {
    display: flex;

    .numberStepper {
      flex: 0 0 190px;
      margin-right: 19px;
      height: 128px;
    }
    .options {
      flex-grow: 1;
    }
  }
}

// Difference between thin and medium is just that the thin mode
// has its price below the option title
:global(.productThinMode) .module,
:global(.productMediumMode) .module,
:global(.productWiderMode) .module {
  .inputs {
    display: flex;
    flex-wrap: wrap;

    .options {
      flex-basis: 100%;
      order: 1;
    }

    .numberStepper {
      flex-basis: 100%;
      order: 2;
      margin-bottom: var(--sg-spacing-small);
      height: 85px;
    }
  }
}

.option {
  height: 56px;
  padding: 0 10px 0 14px;
  border-radius: 4px;
  margin-bottom: var(--sg-spacing-small);

  border: solid 1px var(--grey-200);
  &.selected {
    border-color: var(--primary-blue-700);
  }
  background-color: var(--white);

  .optionInner {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;

    .description {
      flex-grow: 1;
      white-space: wrap;

      .title {
        @include sgFontBold;
        @include sgTextNormal;
        line-height: 17px;
        margin: 8px 0 0 0;
        color: var(--primary-blue-700);
      }
      .subtitle {
        margin: 5px 0;
        @include sgSubpanelTitle;
        line-height: 15px;
      }
    }

    .price {
      flex: 0 1;
      white-space: nowrap;
      @include sgFontBold;
      @include sgTextNormal;
      margin: 0;
      color: var(--grey-900);

      text-align: right;

      :global(.priceWithSurcharge) {
        :global(.surcharge) {
          @include sgTextTiny;
          @include sgFontNormal;
          margin-top: -3px;
          margin-bottom: 0;
          color: var(--grey-400);
        }
      }
    }
  }
}
// Specifics for thin mode
:global(.productThinMode) .module .option {
  height: auto;
  padding: var(--sg-spacing-tiny) var(--sg-spacing-small);
  cursor: pointer;

  .optionInner {
    height: auto;
    flex-wrap: wrap;

    .description {
      width: 100%;
    }
    .price {
      margin-top: -5px;
      text-align: left;
    }
  }
}
