@import "../styles/mq.scss";

.module {
  text-align: center;
  // Height should be defined by containing module
  
  width: 100%;
  overflow: hidden;
  margin-bottom: 2em;
}

.inner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  position: absolute;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: center;
}

.innerItem {
  max-width: 780px;
  padding: 20px;
}

.bullets {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 2px;
}

.timeline {
  position: absolute;
  bottom: 20px;
  width: 50px;
  left: 50%;
  margin-left: -25px;
  height: 1px;
  background-color: #eee;
}

.timelineAnim {
  width: 0px;
  height: 1px;
  background-color: var(--primary-blue-700);
}

.themeSlidingWhite {
  .timeline {
    width: 56px;
    height: 2px;
    background-color: var(--grey-400);
  }
  .timelineAnim {
    height: 2px;
    background-color: var(--white);
  }
}