@import "../../styles/mq.scss";
@import "../../styles/sg.scss";

// Mixins pertaining to tables and inputs elements.
//
// NB: outlines do not respect the border radius
// on chrome so we use box shadows

@mixin sgformTable {
  border-collapse:separate; 
  border-spacing: 0.75em;
}

// ------------------------------------
// Basic inputs
//
@mixin sgInputPadding {
  padding: 20px 16px 16px 16px;
}
@mixin sgInputLineHeight {
  line-height: 20px;
}
@mixin sgInput {
  background-color: #fff;
  color: var(--grey-900);
  width: 100%;

  @include sgInputPadding;
  border: 1px solid var(--grey-400);
  border-radius: 4px;
  // No margin bottom as this would be added to the input's base margin bottom

  @include sgFontNormal;
  font-size: 18px;
  font-weight: normal;
  @include sgInputLineHeight;
  height: 56px; // Specify as the lineheight is not respected
}
@mixin sgInputTransparent {
  @include sgInput;
  border-color: transparent;
}

@mixin sgInputPlaceholder {
  color: var(--grey-300);
  opacity: 1; // Firefox lowers the opacity by default
}

@mixin sgInputAccentBorder {
  border: 1px solid var(--primary-blue-700) !important;
}
@mixin sgInputAccentOutline {
  outline: none; // Otherwise FF adds an outline by default when active
  box-shadow: 0 0 0 5px var(--primary-blue-200);
}
@mixin sgInputAccentOutlineTransparent {
  outline: none; // Otherwise FF adds an outline by default when active
  box-shadow: 0 0 0 5px rgba(231, 238, 245, 0.5);
}
@mixin sgInputAccentColor {
  color: var(--primary-blue-700);
}

@mixin sgInputErrorBorder {
  border: 1px solid var(--accent-red-600) !important;
  box-shadow: 0 0 0 5px rgba(255, 233, 230, 0.7);
}
@mixin sgInputErrorOutline {
  outline: none; // Otherwise FF adds an outline by default when active
  box-shadow: 0 0 0 5px var(--primary-blue-200);
}
@mixin sgInputErrorOutlineTransparent {
  outline: none; // Otherwise FF adds an outline by default when active
  box-shadow: 0 0 0 5px rgba(231, 238, 245, 0.5);
}

@mixin sgInputDisabled {
  border: 1px solid var(--grey-200);
  box-shadow: 0 0 0 5px var(--grey-100);
}

// ------------------------------------
// Select input specifics
//
