.module {
  display: flex;
}

.bullet {
  position: relative;
  width: 10px;
  height: 10px;
  margin: 0px 5px;
  cursor: pointer;
}

.themeBlue {
  .bulletActive .icon {
    background-color: var(--primary-blue-700);
  }
}

.icon {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 10px;
  height: 2px;
  background-color: #ccc;  
  transition: all 0.3s ease-in-out;
}

.bullet:hover .icon {
  top: 0px;
  height: 10px;
}

// White theme (used in signup for example) changes the icons to
// be rounded too.
.themeWhite {
  .icon {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--grey-400);
  }

  .bullet {
    margin: 0px 6px;
  }

  .bulletActive .icon {
    background-color: var(--white);
  }

  .bullet:hover .icon {
    height: 8px;
    // background-color: var(--white);
  }
}
