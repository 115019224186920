@import "../../shared/styles/sg.scss";

.module {
  margin-top: 6px;
  color: var(--grey-700);
  @include sgTextNormal;
  text-align: left;

  .prefix {
    margin-right: 6px;
  }

  .code {
    background-color: var(--primary-blue-200);
    color: var(--primary-blue-800);
    @include sgFontBold;
    @include sgTextSmall(false);
    text-transform: uppercase;

    padding: 5px 12px 2px 12px;
    border-radius: 6px;
  }

  &.noBottomMargin {
    margin-bottom: 0;
  }
}