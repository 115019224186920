@import "../styles/sg.scss";

.module {
  position: relative;
  border-radius: 4px;
}

.inner {
  --text-max-width: 100%;
  position: relative;
  padding: var(--sg-spacing-bigger) var(--sg-spacing-big);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: inherit; // Necessary to get proper height as parent can have a min-height
  
  &.positionCenter {
    justify-content: center;
  }
  &.positionTop {
    justify-content: start;
  }

  :global(.text) {
    max-width: var(--text-max-width);
    margin-inline: auto
  }

  text-align: center;

  h2 {
    @include sgTitle2;
  }
  h3 {
    @include sgTitle3;
  }
  h4 {
    @include sgTitle4;
  }

  h4, p {
    margin: 0;
    color: inherit;
  }
  h4 + p {
    margin-top: var(--sg-spacing-tiny);

    & + p {
      margin-top: var(--sg-spacing-smaller);
    }
  }

  @include mq_narrow {
    padding: var(--sg-spacing-big) var(--sg-spacing-small) var(--sg-spacing-normal) var(--sg-spacing-small);
    width: 100%;
  }

  :global(.footer) {
    --padding: var(--sg-spacing-normal);

    position: absolute;
    bottom: var(--padding);
    left: var(--padding);
    right: var(--padding);
  }
}
