.TableOfContents .intro {
  text-align: center;
  padding: 16px 16px;
}

.TableOfContents .item .title {
  color: var(--grey-900);
  font-family: "AbelPro-Bold";
  font-size: 16px;
  line-height: 24px;
  margin-top: 4px;
  margin-left: 8px;
}

.TableOfContents .item .title:hover {
  color: var(--grey-400);
}

.TableOfContents .item {
  cursor: pointer;
  padding: 15px 10px;
  border-bottom: 1px solid var(--grey-200);
}

.TableOfContents .item .icon {
  width: 6px;
  height: 6px;
  border-right: 2px solid var(--grey-900);
  border-bottom: 2px solid var(--grey-900);
  transform: rotate(-45deg);
  display: inline-block;
  margin-right: 1em;
  float: right;
  margin-top: 12px;
}

.TableOfContents .item .icon:hover {
  border-right: 2px solid var(--grey-400);
  border-bottom: 2px solid var(--grey-400);
}

.TableOfContents .item .title {
  display: inline-block;
}
