@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.AddressList .AddressWithSelection {
  border: none;
}

.AddressList .AddressWithSelection {
  align-items: flex-start;
}

.AddressList .errorMessage {
  background-color: var(--accent-red-100);
  border-radius: 4px;
  padding: 4px 5px;
  display: flex;

  div {
    @include sgFontNormal;
    @include sgTextSmall;
    margin: 0;
    margin-top: 1px;
  }
  svg + div {
    margin-left: 6px;
  }
  svg path {
    fill: var(--accent-red-600);
  }

  margin-top: -7px;
  margin-bottom: 9px;
}