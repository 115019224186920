@import "../../shared/styles/sg.scss";
@import "../../shared/styles/mq.scss";

.module {
  --items-per-line: 3; // Updated by the component

  width: 100%;
  display: grid;
  gap: 48px;
  grid-template-columns: repeat(var(--items-per-line), minmax(0, 1fr));

  & > div {
    border-radius: 4px;
  }

  .essential, .optional {
    min-height: 645px;

    :global(.bgImageLoaded) {
      background-position-y: bottom;
    }
  }
}

.loading {
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
}

.noSubscriptionsBanner {
  // Custom margins..
  margin-bottom: -40px;

  @include mq_narrow {
    margin-bottom: var(--sg-spacing-normal);
  }
}