@import "../shared/styles/sg.scss";
@import "../shared/styles/mq.scss";

.module {
}

// Header is used only in mobile
.header {
  // On mobile: more header space for Hallstein logo on top left
  @include mq_narrow {
    visibility: visible;
    background-image: url("/assets/LogoSimple-Black.svg");
    background-size: 89px 37px;
    background-repeat: no-repeat;
    background-position: 16px 13px;

    width: 100%;
    height: 65px;

    border-bottom: 1px solid var(--grey-100);
  }
}

.inner {
  padding: 50px 24px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mq_narrow {
    padding-top: 24px;
  }
}

.title {
  @include sgGuideHeaderTitle;
}

.step {
  text-transform: uppercase;
  color: #ccc;
  margin-top: 10px;
}
