@import "../styles/mq.scss";
@import "./forms/sgForm.scss";
@import "../styles/sg.scss";


@mixin defaults {
  cursor: pointer;
  @include sgFontNormal;
  text-decoration: none;
  // No margin here
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  // Never wrap a button
  white-space: nowrap;

  a {
    user-select: none;
    text-decoration: none;
    
    &:focus-visible {
      outline: none;
    }
  }

  span {
    user-select: none;
  }
}
button.Button {
  background-color: unset;

  &:focus-visible {
    outline: none;
  }
}


// -----------------------------------
// Defaults for button and link themes
@mixin defaultsButton {
  @include defaults;

  border-style: solid;
  border-width: 1px;
  text-decoration: none;
}
@mixin defaultsLink {
  @include defaults;

  border: none;
  padding: 0;
  text-decoration: underline;
  font-size: inherit; // Necessary to prevent browser custom shenanigans
  white-space: normal;

  color: var(--primary-blue-700);
  outline: none; // remove default outline in safari mobile

  a {
    text-decoration: underline;
    
    &:focus-visible {
      outline: none;
    }
  }

  &:hover,
  &:focus-visible {
    color: var(--primary-blue-600);
  }
  &:active {
    color: var(--primary-blue-800);
  }
  &.inactive {
    opacity: 60%;
  }
}


// -----
// Sizes

// NB: the buttonThemeColourSize come from prismic, where we can only set one
// "label" for a piece of Richtext.

// Richtext links with labels are rendered as a span with class and an a link inside in some browsers
.buttonThemeBlueMedium a,
.buttonThemeBlackMedium a,
.buttonThemeGoldMedium a,
.buttonThemeDarkBlueMedium a,
.buttonThemeBlueOutlineMedium a,
.buttonThemeGreyOutlineMedium a,
.buttonThemeWhiteMedium a,
.buttonThemeWhiteOutlineMedium a,
// Richtext links with labels are rendered as a span with the class inside an a link in some browsers
a span.buttonThemeBlueMedium,
a span.buttonThemeBlackMedium,
a span.buttonThemeGoldMedium,
a span.buttonThemeDarkBlueMedium,
a span.buttonThemeBlueOutlineMedium,
a span.buttonThemeGreyOutlineMedium,
a span.buttonThemeWhiteMedium,
a span.buttonThemeWhiteOutlineMedium,
// Button theme medium
.Button.themeButton.medium {
  @include defaultsButton;
  @include sgTextSmall(false);
  @include sgFontBold;
  padding: 0 56px;
  line-height: 56px;
  height: 56px;

  @include mq_narrow {
    display: inline-block;
    width: 100%;
  }
}

// Richtext links with labels are rendered as a span with class and an a link inside in some browsers
.buttonThemeBlueSmall a,
.buttonThemeBlackSmall a,
.buttonThemeGoldSmall a,
.buttonThemeDarkBlueSmall a,
.buttonThemeBlueOutlineSmall a,
.buttonThemeGreyOutlineSmall a,
.buttonThemeWhiteSmall a,
.buttonThemeWhiteOutlineSmall a,
// Richtext links with labels are rendered as a span with the class inside an a link in some browsers
a span.buttonThemeBlueSmall,
a span.buttonThemeBlackSmall,
a span.buttonThemeGoldSmall,
a span.buttonThemeDarkBlueSmall,
a span.buttonThemeBlueOutlineSmall,
a span.buttonThemeGreyOutlineSmall,
a span.buttonThemeWhiteSmall,
a span.buttonThemeWhiteOutlineSmall,
// Button theme small
.Button.themeButton.small {
  @include defaultsButton;
  @include sgTextTiny(false);
  @include sgFontBold;
  padding: 0 28px;
  line-height: 40px;
  height: 40px;

  &.green {
    // -12px to compensate for icon and margin
    padding-left: 16px;
    padding-right: 16px;
  }

  @include mq_narrow {
    display: inline-block;
    width: 100%;
  }
}

.Button.themeButton.mini {
  @include defaultsButton;
  padding: 9px 20px 7px 20px;
  height: 32px;
  font-size: 14px;
  line-height: 14px;

  @include mq_narrow {
    display: inline-block;
    width: 100%;
  }
}


// ---------------
// Theme specifics

// NB: the buttonThemeColourSize come from prismic, where we can only set one
// "label" for a piece of Richtext.

@mixin colorAndBorder($color) {
  color: $color;
  border-color: $color;
}
@mixin backgroundAndBorder($color) {
  background-color: $color;
  border-color: $color;
}

// BLUE
.buttonThemeBlueSmall a,
.buttonThemeBlueMedium a,
a span.buttonThemeBlueSmall,
a span.buttonThemeBlueMedium,
.themeButton.blue {
  @include backgroundAndBorder(var(--primary-blue-700));
  color: var(--white);
  
  &:hover,
  &:focus-visible {
    @include backgroundAndBorder(var(--primary-blue-600));
    color: var(--white);
  }
  &:active {
    @include backgroundAndBorder(var(--primary-blue-900));
    color: var(--white);
  }
  &:disabled {
    cursor: unset;
    @include backgroundAndBorder(var(--primary-blue-200));
    color: var(--white);
  }
}
.linkThemeBlue,
.linkThemeDarkBlue,
.linkThemeBlue a,
.linkThemeDarkBlue a,
a, // Default
.themeLink.blue,
.themeLink.dark-blue {
  @include defaultsLink;

  color: var(--primary-blue-700);

  &:hover {
    color: var(--secondary-gold-600);
  }
  &:active {
    color: var(--secondary-gold-800);
  }
}

// BLUE OUTLINE
.buttonThemeBlueOutlineSmall a,
.buttonThemeBlueOutlineMedium a,
a span.buttonThemeBlueOutlineSmall,
a span.buttonThemeBlueOutlineMedium,
.themeButton.blue-outline {
  @include colorAndBorder(var(--primary-blue-700));
  background-color: var(--white);

  &:hover,
  &:focus-visible {
    @include colorAndBorder(var(--primary-blue-500));
    background-color: var(--primary-blue-100);
  }
  &:active {
    @include colorAndBorder(var(--primary-blue-800));
    background-color: var(--primary-blue-400);
  }
  &:disabled {
    cursor: unset;
    @include colorAndBorder(var(--primary-blue-200));
    background-color: var(--white);
  }
}

// DARK BLUE
.buttonThemeDarkBlueSmall a,
.buttonThemeDarkBlueMedium a,
a span.buttonThemeDarkBlueSmall,
a span.buttonThemeDarkBlueMedium,
.themeButton.dark-blue {
  @include backgroundAndBorder(var(--primary-blue-800));
  color: var(--white) !important;

  &:hover,
  &:focus-visible {
    @include backgroundAndBorder(var(--primary-blue-700));
    color: var(--white) !important;
  }
  &:active {
    @include backgroundAndBorder(var(--primary-blue-900));
    color: var(--white) !important;
  }
  &:disabled {
    cursor: unset;
    @include backgroundAndBorder(var(--grey-200));
    color: var(--white) !important;
  }
}

// BLACK
.linkThemeBlack,
.linkThemeBlack a,
.themeLink.black {
  @include defaultsLink;

  color: var(--grey-900);

  &:hover {
    color: var(--grey-400);
  }
  &:active {
    color: var(--grey-600);
  }
}

// GOLD
.buttonThemeGoldSmall a,
.buttonThemeGoldMedium a,
a span.buttonThemeGoldSmall,
a span.buttonThemeGoldMedium,
.themeButton.gold {
  @include backgroundAndBorder(var(--secondary-gold-600));
  color: var(--white);

  &:hover,
  &:focus-visible {
    @include backgroundAndBorder(var(--secondary-gold-500));
    color: var(--white);
  }
  &:active {
    @include backgroundAndBorder(var(--secondary-gold-800));
    color: var(--white);
  }
  &:disabled {
    cursor: unset;
    @include backgroundAndBorder(var(--secondary-gold-200));
    color: var(--white);
  }
}
.linkThemeGold,
.linkThemeGold a,
.themeLink.gold {
  @include defaultsLink;

  color: var(--secondary-gold-600);

  &:hover {
    color: var(--secondary-gold-500);
  }
  &:active {
    color: var(--secondary-gold-800);
  }
}

// GREY OUTLINE
.buttonThemeGreyOutlineSmall a,
.buttonThemeGreyOutlineMedium a,
a span.buttonThemeGreyOutlineSmall,
a span.buttonThemeGreyOutlineMedium,
.themeButton.grey-outline {
  @include colorAndBorder(var(--grey-400));
  background-color: var(--white);

  &:hover,
  &:focus-visible {
    @include colorAndBorder(var(--grey-300));
    background-color: var(--grey-100);
  }
  &:active {
    @include colorAndBorder(var(--grey-500));
    background-color: var(--grey-200);
  }
  &:disabled {
    cursor: unset;
    @include colorAndBorder(var(--grey-200));
    background-color: var(--white);
  }
}
.linkThemeGreyOutline,
.linkThemeGreyOutline a,
.themeLink.grey-outline {
  @include defaultsLink;

  color: var(--grey-400);

  &:hover {
    color: var(--grey-300);
  }
  &:active {
    color: var(--grey-600);
  }
}

// WHITE
.buttonThemeWhiteSmall a,
.buttonThemeWhiteMedium a,
a span.buttonThemeWhiteSmall,
a span.buttonThemeWhiteMedium,
.themeButton.white {
  border-color: transparent;
  color: var(--grey-400);
  background-color: var(--white);

  &:hover {
    background-color: var(--grey-200);
    color: var(--grey-400);
  }
  &:focus-visible {
    background-color: var(--grey-200);
    color: var(--grey-400);
  }
  &:active {
    background-color: var(--grey-300);
    color: var(--grey-500);
  }
  &:disabled {
    cursor: unset;
    background-color: var(--grey-200);
    color: var(--grey-100);
  }
}
.linkThemeWhite,
.linkThemeWhite a,
.themeLink.white {
  @include defaultsLink;

  color: var(--white);

  &:hover {
    color: var(--secondary-gold-600);
  }
  &:active {
    color: var(--grey-600);
  }
}

// WHITE OUTLINE
.buttonThemeWhiteOutlineSmall a,
.buttonThemeWhiteOutlineMedium a,
a span.buttonThemeWhiteOutlineSmall,
a span.buttonThemeWhiteOutlineMedium,
.themeButton.white-outline {
  @include colorAndBorder(var(--white));

  &:hover,
  &:focus-visible {
    @include colorAndBorder(var(--secondary-gold-400));
  }
  &:active {
    @include colorAndBorder(var(--secondary-gold-900));
  }
  &:disabled {
    cursor: unset;
    opacity: 0.3;
  }
}

// GREEN
.themeButton.green {
  @include backgroundAndBorder(var(--accent-green-600));
  color: var(--white);

  // Green icons always have a checked icon (for now..)
  svg {
    vertical-align: sub;
    margin-right: var(--sg-spacing-tiny);
  }

  &:hover {
    @include backgroundAndBorder(var(--accent-green-500));
  }
  &:focus-visible {
    border-color: var(--accent-green-700);
    background-color: var(--accent-green-600);
  }
  &:active {
    @include backgroundAndBorder(var(--accent-green-700));
  }
  &:disabled {
    cursor: unset;
    @include backgroundAndBorder(var(--accent-green-200));
  }
}


// Special cancel button always at top right of relative parent
// Used in modal windows to show an X in the top right for cancellation.
.cancelButton {
  visibility: visible;
  cursor: pointer;
  pointer-events: all;
  position: absolute;
  top: 13px;
  right: 13px;
  color: var(--grey-700);
  margin: 0 !important;

  img {
    width: 24px;
  }

  @include mq_narrow {
    top: 16px;
    right: 16px;

    img {
      width: 32px;
    }
  }
}