@import "../styles/mq.scss";

.module {
  border-radius: 4px;

  padding: 4px 16px;
  @include mq_narrow {
    padding: 16px;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq_narrow {
    flex-flow: column;
    align-items: baseline;
  }

  .text {
    width: 100%;
  }
}

.icon {
  line-height: 24px;
  width: 24px;
  height: 24px;
  
  background-repeat: no-repeat;
  margin-right: 0.5em;
  flex: 0 0 28px;
}

// Coloring
.warning {
  color: var(--accent-red-600);
  background-color: var(--accent-red-100);

  path {
    fill: var(--accent-red-600);
  }
}
.alert {
  color: var(--secondary-gold-600);
  background-color: var(--secondary-gold-200);

  path {
    fill: var(--secondary-gold-600);
  }
}
.positive {
  color: var(--accent-green-600);
  background-color: var(--accent-green-200);
  
  path {
    fill: var(--accent-green-600);
  }
}
.neutral {
  color: var(--primary-blue-700);
  background-color: var(--primary-blue-100);

  path {
    fill: var(--primary-blue-700);
  }
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: var(--primary-blue-700);
  white-space: nowrap;  
}