
@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";

.module {
  width: 100%;
  min-height: 100vh;

  @include putInCenter;
}
