@import "../styles/sg.scss";

.module {
  cursor: pointer;
  @include putInCenter;
}

.transparentModule {
  cursor: pointer;
}
.transparentModule .lines {
  stroke: var(--white);
}
.fixed .lines {
  stroke: var(--grey-900);
}

.lines {
  stroke: var(--grey-900);
  transition: stroke 0.3s ease-out;
}

.fixed {
  position: fixed;
  z-index: 201;
  top: 12px;
  right: 10px;
}
