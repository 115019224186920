@import "../../../shared/styles/mq.scss";
@import "../../../shared/styles/sg.scss";
@import "../../shared/Stripe.scss";

.summary {
  flex: 0 0 40%;
  @include sgTextSmall;
  margin-bottom: 0;
  background-color: var(--grey-100);
  border-left: 1px solid var(--grey-200);
  
  .fixedDesktop {
    @include mq_notNarrow {
      padding: 40px 64px;
      position: sticky;
      top: 0px;
    }
    @include mediaQueryMaxWidth(1100px) {
      padding: 40px var(--sg-spacing-normal);
    }
  }

  .logo {
    margin-bottom: var(--sg-spacing-normal);
  }

  .divider + .notice {
    margin-top: -10px;
    @include sgTextSmall;
    margin-bottom: var(--sg-spacing-tiny);
    @include mq_narrow {
      text-align: center;
    }
  }

  .buttons button {
    width: 100%;
    & + button {
      margin-top: var(--sg-spacing-small);
    }
  }

  .more {
    margin-bottom: 0;
    p {
      @include sgTextSmall;
    }

    .stripeElement {
      margin: var(--sg-spacing-smaller) 0;
      line-height: 33px;

      span.text {
        margin-right: var(--sg-spacing-tiny);
      }

      span.icon {
        @include StripeIcon;
        vertical-align: middle;
        padding-bottom: 0;
        
        display: inline-block;
        width: 115px;
        height: 33px;
      }
    }
  }
}

.divider {
  margin-top: 5px;
  border-top: 1px solid var(--grey-200);
  margin-bottom: 15px;
}

.mobile .summary {
  .more {
    text-align: center;

    .stripeElement {
      margin-top: var(--flag-height-small);
    }

    p + p {
      margin-top: 40px;
    }
  }
}

.OrderSummary {
  width: 100%;
  margin-bottom: 2em;
}

.OrderSummary .title {
  font-size: 24px;
}


@include mq_narrow {
  .logo {
    display: none;
  }

  .summary {
    border-top: 1px solid var(--grey-200);
  }

  .more {
    margin-top: var(--sg-spacing-normal);
    text-align: center;

    p {
      margin-bottom: 0;
    }

    p + p {
      margin-top: var(--sg-spacing-big);
    }
  }
}

.PromoComponents {
  margin-top: var(--sg-spacing-normal);
  margin-bottom: var(--sg-spacing-normal);
}

.module :global(.buttons) button:global(.Button) {
  @include mediaQueryMaxWidth(900px) {
    // Buttons on top of each other
    width: 100%;
    & + button {
      margin-top: var(--sg-spacing-small);
    }
  }

  @include mediaQueryMinWidth(900px) {
    // Buttons next to each other
    & + button {
      margin-left: var(--sg-spacing-small) !important;
    }
  }
}