.ad {
  width: 84px; height: 60px;
  background-position: -2px -2px;
}

.ae {
  width: 84px; height: 60px;
  background-position: -2px -66px;
}

.af {
  width: 84px; height: 60px;
  background-position: -2px -130px;
}

.ag {
  width: 84px; height: 60px;
  background-position: -2px -194px;
}

.ai {
  width: 84px; height: 60px;
  background-position: -2px -258px;
}

.al {
  width: 84px; height: 60px;
  background-position: -2px -322px;
}

.am {
  width: 84px; height: 60px;
  background-position: -2px -386px;
}

.ao {
  width: 84px; height: 60px;
  background-position: -2px -450px;
}

.ar {
  width: 84px; height: 60px;
  background-position: -2px -514px;
}

.as {
  width: 84px; height: 60px;
  background-position: -2px -578px;
}

.at {
  width: 84px; height: 60px;
  background-position: -2px -642px;
}

.au {
  width: 84px; height: 60px;
  background-position: -2px -706px;
}

.ax {
  width: 84px; height: 60px;
  background-position: -2px -770px;
}

.aw {
  width: 84px; height: 60px;
  background-position: -2px -834px;
}

.az {
  width: 84px; height: 60px;
  background-position: -2px -898px;
}

.ba {
  width: 84px; height: 60px;
  background-position: -2px -962px;
}

.bb {
  width: 84px; height: 60px;
  background-position: -2px -1026px;
}

.bd {
  width: 84px; height: 60px;
  background-position: -2px -1090px;
}

.be {
  width: 84px; height: 60px;
  background-position: -2px -1154px;
}

.bf {
  width: 84px; height: 60px;
  background-position: -2px -1218px;
}

.bg {
  width: 84px; height: 60px;
  background-position: -2px -1282px;
}

.bh {
  width: 84px; height: 60px;
  background-position: -2px -1346px;
}

.bi {
  width: 84px; height: 60px;
  background-position: -2px -1410px;
}

.bj {
  width: 84px; height: 60px;
  background-position: -2px -1474px;
}

.bl {
  width: 84px; height: 60px;
  background-position: -2px -1538px;
}

.bm {
  width: 84px; height: 60px;
  background-position: -2px -1602px;
}

.bn {
  width: 84px; height: 60px;
  background-position: -2px -1666px;
}

.bo {
  width: 84px; height: 60px;
  background-position: -2px -1730px;
}

.bq {
  width: 84px; height: 60px;
  background-position: -2px -1794px;
}

.bs {
  width: 84px; height: 60px;
  background-position: -2px -1858px;
}

.br {
  width: 84px; height: 60px;
  background-position: -2px -1922px;
}

.bt {
  width: 84px; height: 60px;
  background-position: -2px -1986px;
}

.bw {
  width: 84px; height: 60px;
  background-position: -2px -2050px;
}

.by {
  width: 84px; height: 60px;
  background-position: -2px -2114px;
}

.bz {
  width: 84px; height: 60px;
  background-position: -2px -2178px;
}

.ca {
  width: 84px; height: 60px;
  background-position: -2px -2242px;
}

.cc {
  width: 84px; height: 60px;
  background-position: -2px -2306px;
}

.cd {
  width: 84px; height: 60px;
  background-position: -2px -2370px;
}

.cf {
  width: 84px; height: 60px;
  background-position: -2px -2434px;
}

.cg {
  width: 84px; height: 60px;
  background-position: -2px -2498px;
}

.ch {
  width: 84px; height: 60px;
  background-position: -2px -2562px;
}

.ci {
  width: 84px; height: 60px;
  background-position: -2px -2626px;
}

.ck {
  width: 84px; height: 60px;
  background-position: -2px -2690px;
}

.cl {
  width: 84px; height: 60px;
  background-position: -2px -2754px;
}

.cm {
  width: 84px; height: 60px;
  background-position: -2px -2818px;
}

.cn {
  width: 84px; height: 60px;
  background-position: -2px -2882px;
}

.co {
  width: 84px; height: 60px;
  background-position: -2px -2946px;
}

.cr {
  width: 84px; height: 60px;
  background-position: -2px -3010px;
}

.cu {
  width: 84px; height: 60px;
  background-position: -2px -3074px;
}

.cv {
  width: 84px; height: 60px;
  background-position: -2px -3138px;
}

.cw {
  width: 84px; height: 60px;
  background-position: -2px -3202px;
}

.cx {
  width: 84px; height: 60px;
  background-position: -2px -3266px;
}

.cz {
  width: 84px; height: 60px;
  background-position: -2px -3330px;
}

.de {
  width: 84px; height: 60px;
  background-position: -2px -3394px;
}

.dj {
  width: 84px; height: 60px;
  background-position: -2px -3458px;
}

.dm {
  width: 84px; height: 60px;
  background-position: -2px -3522px;
}

.dk {
  width: 84px; height: 60px;
  background-position: -2px -3586px;
}

.cy {
  width: 84px; height: 60px;
  background-position: -2px -3650px;
}

.do {
  width: 84px; height: 60px;
  background-position: -2px -3714px;
}

.dz {
  width: 84px; height: 60px;
  background-position: -2px -3778px;
}

.ee {
  width: 84px; height: 60px;
  background-position: -2px -3842px;
}

.ec {
  width: 84px; height: 60px;
  background-position: -2px -3906px;
}

.eg {
  width: 84px; height: 60px;
  background-position: -2px -3970px;
}

.er {
  width: 84px; height: 60px;
  background-position: -2px -4034px;
}

.et {
  width: 84px; height: 60px;
  background-position: -2px -4098px;
}

.es {
  width: 84px; height: 60px;
  background-position: -2px -4162px;
}

.eu {
  width: 84px; height: 60px;
  background-position: -2px -4226px;
}

.fi {
  width: 84px; height: 60px;
  background-position: -2px -4290px;
}

.fk {
  width: 84px; height: 60px;
  background-position: -2px -4354px;
}

.fj {
  width: 84px; height: 60px;
  background-position: -2px -4418px;
}

.fm {
  width: 84px; height: 60px;
  background-position: -2px -4482px;
}

.fo {
  width: 84px; height: 60px;
  background-position: -2px -4546px;
}

.fr {
  width: 84px; height: 60px;
  background-position: -2px -4610px;
}

.ga {
  width: 84px; height: 60px;
  background-position: -2px -4674px;
}

.bg-GB_ENG {
  width: 84px; height: 60px;
  background-position: -2px -4738px;
}

.bg-GB_NIR {
  width: 84px; height: 60px;
  background-position: -2px -4802px;
}

.bg-GB_SCT {
  width: 84px; height: 60px;
  background-position: -2px -4866px;
}

.bg-GB_WLS {
  width: 84px; height: 60px;
  background-position: -2px -4930px;
}

.bg-GB_ZET {
  width: 84px; height: 60px;
  background-position: -2px -4994px;
}

.gd {
  width: 84px; height: 60px;
  background-position: -2px -5058px;
}

.gb {
  width: 84px; height: 60px;
  background-position: -2px -5122px;
}

.ge {
  width: 84px; height: 60px;
  background-position: -2px -5186px;
}

.gf {
  width: 84px; height: 60px;
  background-position: -2px -5250px;
}

.gg {
  width: 84px; height: 60px;
  background-position: -2px -5314px;
}

.gh {
  width: 84px; height: 60px;
  background-position: -2px -5378px;
}

.gi {
  width: 84px; height: 60px;
  background-position: -2px -5442px;
}

.gl {
  width: 84px; height: 60px;
  background-position: -2px -5506px;
}

.gm {
  width: 84px; height: 60px;
  background-position: -2px -5570px;
}

.gn {
  width: 84px; height: 60px;
  background-position: -2px -5634px;
}

.gp {
  width: 84px; height: 60px;
  background-position: -2px -5698px;
}

.gq {
  width: 84px; height: 60px;
  background-position: -2px -5762px;
}

.gr {
  width: 84px; height: 60px;
  background-position: -2px -5826px;
}

.gs {
  width: 84px; height: 60px;
  background-position: -2px -5890px;
}

.gt {
  width: 84px; height: 60px;
  background-position: -2px -5954px;
}

.gu {
  width: 84px; height: 60px;
  background-position: -2px -6018px;
}

.gw {
  width: 84px; height: 60px;
  background-position: -2px -6082px;
}

.gy {
  width: 84px; height: 60px;
  background-position: -2px -6146px;
}

.hk {
  width: 84px; height: 60px;
  background-position: -2px -6210px;
}

.hn {
  width: 84px; height: 60px;
  background-position: -2px -6274px;
}

.hr {
  width: 84px; height: 60px;
  background-position: -2px -6338px;
}

.ht {
  width: 84px; height: 60px;
  background-position: -2px -6402px;
}

.hu {
  width: 84px; height: 60px;
  background-position: -2px -6466px;
}

.id {
  width: 84px; height: 60px;
  background-position: -2px -6530px;
}

.ie {
  width: 84px; height: 60px;
  background-position: -2px -6594px;
}

.il {
  width: 84px; height: 60px;
  background-position: -2px -6658px;
}

.im {
  width: 84px; height: 60px;
  background-position: -2px -6722px;
}

.in {
  width: 84px; height: 60px;
  background-position: -2px -6786px;
}

.io {
  width: 84px; height: 60px;
  background-position: -2px -6850px;
}

.iq {
  width: 84px; height: 60px;
  background-position: -2px -6914px;
}

.ir {
  width: 84px; height: 60px;
  background-position: -2px -6978px;
}

.is {
  width: 84px; height: 60px;
  background-position: -2px -7042px;
}

.it {
  width: 84px; height: 60px;
  background-position: -2px -7106px;
}

.je {
  width: 84px; height: 60px;
  background-position: -2px -7170px;
}

.jm {
  width: 84px; height: 60px;
  background-position: -2px -7234px;
}

.jo {
  width: 84px; height: 60px;
  background-position: -2px -7298px;
}

.jp {
  width: 84px; height: 60px;
  background-position: -2px -7362px;
}

.ke {
  width: 84px; height: 60px;
  background-position: -2px -7426px;
}

.kg {
  width: 84px; height: 60px;
  background-position: -2px -7490px;
}

.kh {
  width: 84px; height: 60px;
  background-position: -2px -7554px;
}

.ki {
  width: 84px; height: 60px;
  background-position: -2px -7618px;
}

.km {
  width: 84px; height: 60px;
  background-position: -2px -7682px;
}

.kn {
  width: 84px; height: 60px;
  background-position: -2px -7746px;
}

.kp {
  width: 84px; height: 60px;
  background-position: -2px -7810px;
}

.kr {
  width: 84px; height: 60px;
  background-position: -2px -7874px;
}

.kw {
  width: 84px; height: 60px;
  background-position: -2px -7938px;
}

.ky {
  width: 84px; height: 60px;
  background-position: -2px -8002px;
}

.kz {
  width: 84px; height: 60px;
  background-position: -2px -8066px;
}

.la {
  width: 84px; height: 60px;
  background-position: -2px -8130px;
}

.lb {
  width: 84px; height: 60px;
  background-position: -2px -8194px;
}

.lc {
  width: 84px; height: 60px;
  background-position: -2px -8258px;
}

.li {
  width: 84px; height: 60px;
  background-position: -2px -8322px;
}

.lk {
  width: 84px; height: 60px;
  background-position: -2px -8386px;
}

.lr {
  width: 84px; height: 60px;
  background-position: -2px -8450px;
}

.ls {
  width: 84px; height: 60px;
  background-position: -2px -8514px;
}

.lt {
  width: 84px; height: 60px;
  background-position: -2px -8578px;
}

.lu {
  width: 84px; height: 60px;
  background-position: -2px -8642px;
}

.lv {
  width: 84px; height: 60px;
  background-position: -2px -8706px;
}

.ly {
  width: 84px; height: 60px;
  background-position: -2px -8770px;
}

.ma {
  width: 84px; height: 60px;
  background-position: -2px -8834px;
}

.mc {
  width: 84px; height: 60px;
  background-position: -2px -8898px;
}

.md {
  width: 84px; height: 60px;
  background-position: -2px -8962px;
}

.me {
  width: 84px; height: 60px;
  background-position: -2px -9026px;
}

.mf {
  width: 84px; height: 60px;
  background-position: -2px -9090px;
}

.mg {
  width: 84px; height: 60px;
  background-position: -2px -9154px;
}

.mh {
  width: 84px; height: 60px;
  background-position: -2px -9218px;
}

.mk {
  width: 84px; height: 60px;
  background-position: -2px -9282px;
}

.mm {
  width: 84px; height: 60px;
  background-position: -2px -9346px;
}

.ml {
  width: 84px; height: 60px;
  background-position: -2px -9410px;
}

.mn {
  width: 84px; height: 60px;
  background-position: -2px -9474px;
}

.mo {
  width: 84px; height: 60px;
  background-position: -2px -9538px;
}

.mp {
  width: 84px; height: 60px;
  background-position: -2px -9602px;
}

.mq {
  width: 84px; height: 60px;
  background-position: -2px -9666px;
}

.mr {
  width: 84px; height: 60px;
  background-position: -2px -9730px;
}

.ms {
  width: 84px; height: 60px;
  background-position: -2px -9794px;
}

.mt {
  width: 84px; height: 60px;
  background-position: -2px -9858px;
}

.mu {
  width: 84px; height: 60px;
  background-position: -2px -9922px;
}

.mv {
  width: 84px; height: 60px;
  background-position: -2px -9986px;
}

.mw {
  width: 84px; height: 60px;
  background-position: -2px -10050px;
}

.mx {
  width: 84px; height: 60px;
  background-position: -2px -10114px;
}

.my {
  width: 84px; height: 60px;
  background-position: -2px -10178px;
}

.mz {
  width: 84px; height: 60px;
  background-position: -2px -10242px;
}

.na {
  width: 84px; height: 60px;
  background-position: -2px -10306px;
}

.nc {
  width: 84px; height: 60px;
  background-position: -2px -10370px;
}

.ne {
  width: 84px; height: 60px;
  background-position: -2px -10434px;
}

.nf {
  width: 84px; height: 60px;
  background-position: -2px -10498px;
}

.ng {
  width: 84px; height: 60px;
  background-position: -2px -10562px;
}

.ni {
  width: 84px; height: 60px;
  background-position: -2px -10626px;
}

.nl {
  width: 84px; height: 60px;
  background-position: -2px -10690px;
}

.no {
  width: 84px; height: 60px;
  background-position: -2px -10754px;
}

.np {
  width: 84px; height: 60px;
  background-position: -2px -10818px;
}

.nr {
  width: 84px; height: 60px;
  background-position: -2px -10882px;
}

.nu {
  width: 84px; height: 60px;
  background-position: -2px -10946px;
}

.nz {
  width: 84px; height: 60px;
  background-position: -2px -11010px;
}

.om {
  width: 84px; height: 60px;
  background-position: -2px -11074px;
}

.pa {
  width: 84px; height: 60px;
  background-position: -2px -11138px;
}

.pe {
  width: 84px; height: 60px;
  background-position: -2px -11202px;
}

.pf {
  width: 84px; height: 60px;
  background-position: -2px -11266px;
}

.pg {
  width: 84px; height: 60px;
  background-position: -2px -11330px;
}

.ph {
  width: 84px; height: 60px;
  background-position: -2px -11394px;
}

.pk {
  width: 84px; height: 60px;
  background-position: -2px -11458px;
}

.pl {
  width: 84px; height: 60px;
  background-position: -2px -11522px;
}

.pm {
  width: 84px; height: 60px;
  background-position: -2px -11586px;
}

.pn {
  width: 84px; height: 60px;
  background-position: -2px -11650px;
}

.pr {
  width: 84px; height: 60px;
  background-position: -2px -11714px;
}

.ps {
  width: 84px; height: 60px;
  background-position: -2px -11778px;
}

.pt {
  width: 84px; height: 60px;
  background-position: -2px -11842px;
}

.pw {
  width: 84px; height: 60px;
  background-position: -2px -11906px;
}

.py {
  width: 84px; height: 60px;
  background-position: -2px -11970px;
}

.qa {
  width: 84px; height: 60px;
  background-position: -2px -12034px;
}

.re {
  width: 84px; height: 60px;
  background-position: -2px -12098px;
}

.ro {
  width: 84px; height: 60px;
  background-position: -2px -12162px;
}

.rs {
  width: 84px; height: 60px;
  background-position: -2px -12226px;
}

.ru {
  width: 84px; height: 60px;
  background-position: -2px -12290px;
}

.rw {
  width: 84px; height: 60px;
  background-position: -2px -12354px;
}

.sa {
  width: 84px; height: 60px;
  background-position: -2px -12418px;
}

.sb {
  width: 84px; height: 60px;
  background-position: -2px -12482px;
}

.sc {
  width: 84px; height: 60px;
  background-position: -2px -12546px;
}

.sd {
  width: 84px; height: 60px;
  background-position: -2px -12610px;
}

.se {
  width: 84px; height: 60px;
  background-position: -2px -12674px;
}

.sg {
  width: 84px; height: 60px;
  background-position: -2px -12738px;
}

.sh {
  width: 84px; height: 60px;
  background-position: -2px -12802px;
}

.si {
  width: 84px; height: 60px;
  background-position: -2px -12866px;
}

.sk {
  width: 84px; height: 60px;
  background-position: -2px -12930px;
}

.sl {
  width: 84px; height: 60px;
  background-position: -2px -12994px;
}

.sm {
  width: 84px; height: 60px;
  background-position: -2px -13058px;
}

.sn {
  width: 84px; height: 60px;
  background-position: -2px -13122px;
}

.so {
  width: 84px; height: 60px;
  background-position: -2px -13186px;
}

.sr {
  width: 84px; height: 60px;
  background-position: -2px -13250px;
}

.ss {
  width: 84px; height: 60px;
  background-position: -2px -13314px;
}

.st {
  width: 84px; height: 60px;
  background-position: -2px -13378px;
}

.sv {
  width: 84px; height: 60px;
  background-position: -2px -13442px;
}

.sx {
  width: 84px; height: 60px;
  background-position: -2px -13506px;
}

.sy {
  width: 84px; height: 60px;
  background-position: -2px -13570px;
}

.sz {
  width: 84px; height: 60px;
  background-position: -2px -13634px;
}

.tc {
  width: 84px; height: 60px;
  background-position: -2px -13698px;
}

.td {
  width: 84px; height: 60px;
  background-position: -2px -13762px;
}

.tf {
  width: 84px; height: 60px;
  background-position: -2px -13826px;
}

.tg {
  width: 84px; height: 60px;
  background-position: -2px -13890px;
}

.th {
  width: 84px; height: 60px;
  background-position: -2px -13954px;
}

.tj {
  width: 84px; height: 60px;
  background-position: -2px -14018px;
}

.tk {
  width: 84px; height: 60px;
  background-position: -2px -14082px;
}

.tl {
  width: 84px; height: 60px;
  background-position: -2px -14146px;
}

.tm {
  width: 84px; height: 60px;
  background-position: -2px -14210px;
}

.tn {
  width: 84px; height: 60px;
  background-position: -2px -14274px;
}

.to {
  width: 84px; height: 60px;
  background-position: -2px -14338px;
}

.tr {
  width: 84px; height: 60px;
  background-position: -2px -14402px;
}

.tt {
  width: 84px; height: 60px;
  background-position: -2px -14466px;
}

.tv {
  width: 84px; height: 60px;
  background-position: -2px -14530px;
}

.tw {
  width: 84px; height: 60px;
  background-position: -2px -14594px;
}

.tz {
  width: 84px; height: 60px;
  background-position: -2px -14658px;
}

.ua {
  width: 84px; height: 60px;
  background-position: -2px -14722px;
}

.ug {
  width: 84px; height: 60px;
  background-position: -2px -14786px;
}

.um {
  width: 84px; height: 60px;
  background-position: -2px -14850px;
}

.bg-US_CA {
  width: 84px; height: 60px;
  background-position: -2px -14914px;
}

.us {
  width: 84px; height: 60px;
  background-position: -2px -14978px;
}

.uy {
  width: 84px; height: 60px;
  background-position: -2px -15042px;
}

.uz {
  width: 84px; height: 60px;
  background-position: -2px -15106px;
}

.va {
  width: 84px; height: 60px;
  background-position: -2px -15170px;
}

.vc {
  width: 84px; height: 60px;
  background-position: -2px -15234px;
}

.ve {
  width: 84px; height: 60px;
  background-position: -2px -15298px;
}

.vg {
  width: 84px; height: 60px;
  background-position: -2px -15362px;
}

.vi {
  width: 84px; height: 60px;
  background-position: -2px -15426px;
}

.vn {
  width: 84px; height: 60px;
  background-position: -2px -15490px;
}

.vu {
  width: 84px; height: 60px;
  background-position: -2px -15554px;
}

.wf {
  width: 84px; height: 60px;
  background-position: -2px -15618px;
}

.ws {
  width: 84px; height: 60px;
  background-position: -2px -15682px;
}

.xk {
  width: 84px; height: 60px;
  background-position: -2px -15746px;
}

.ye {
  width: 84px; height: 60px;
  background-position: -2px -15810px;
}

.yt {
  width: 84px; height: 60px;
  background-position: -2px -15874px;
}

.za {
  width: 84px; height: 60px;
  background-position: -2px -15938px;
}

.zm {
  width: 84px; height: 60px;
  background-position: -2px -16002px;
}

.zw {
  width: 84px; height: 60px;
  background-position: -2px -16066px;
}