.module {

  .logo {
    display: block;
    margin: auto;
    margin-bottom: var(--sg-spacing-normal);
  }
}

.module p {
  margin: 0;

  & + p {
    margin-top: 8px;
  }
}