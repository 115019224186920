@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";
@import "./shared/Stripe.scss";

.module {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  // No "overflow-y: auto;" as it prevents the summary from being sticky
  -webkit-overflow-scrolling: touch;
  color: var(--grey-900);

  .content {
    min-height: 100vh;
    display: flex;
    justify-content: right;
  }
}

.module :global(.buttons) button:global(.Button) {
  @include mediaQueryMaxWidth(900px) {
    // Buttons on top of each other
    width: 100%;
    & + button {
      margin-top: var(--sg-spacing-small);
    }
  }

  @include mediaQueryMinWidth(900px) {
    // Buttons next to each other
    & + button {
      margin-left: var(--sg-spacing-small) !important;
    }
  }
}

@include mq_narrow {
  .module .content {
    display: block;
    
    .sections {
      padding-bottom: var(--sg-spacing-big);
    }
  }
}

.module > .inner {
  flex: 0 1 auto;
}

.sections {
  h1 {
    color: var(--primary-blue-700);
    @include sgTextLarge;
    margin: 0;
  }

  padding: 55px var(--sg-spacing-normal);
  @include mq_notNarrow {
    padding: 55px var(--sg-spacing-big);
  }
}

.sections {
  flex: 1 1 auto;
  position: relative;

  @include mq_notNarrow {
    .sectionsInner {
      max-width: 586px;
      margin: 0 auto;
    }
  }
}

.divider {
  margin-top: 5px;
  border-top: 1px solid var(--grey-200);
  margin-bottom: 15px;
}


.module > .actions {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  flex: 0 0 40px;
}

.module > .actions a:hover {
  color: var(--primary-blue-700);
}

.module > .actions a {
  text-decoration: none;
  font-family: "AbelPro-Bold";
  color: #888;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px;
}


.ProductTable .f-quantity {
  text-align: center;
  width: 75px;
}

.ProductTable .f-price {
  text-align: right;
  width: 100px;
}

.SelectShippingAddress .Address {
  margin-bottom: 1em;
}

.SelectShippingAddress .Loading {
  display: inline-block;
  margin-left: 10px;
}


.BillingAddress .verified {
  display: inline-block;
  margin-left: 0.5em;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  font-family: "AbelPro-Bold";
  letter-spacing: 0.5px;
  padding: 2px 2px 1px 2px;
  border: 1px solid var(--accent-green-600);
  color: var(--accent-green-600);
  vertical-align: top;
}

.nowrap {
  white-space: nowrap;
}
.reloadPage {
  cursor: pointer;
  text-decoration: underline;
  color: var(--primary-blue-700);
}
