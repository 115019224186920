@import "../styles/sg.scss";
@import "../styles/mq.scss";

.products {
  --flex-basis-water: calc((100% - 16px) / 2); // 2 per row
  --flex-basis-dispensers: calc((100% - 32px)/3); // 3 per row
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--sg-spacing-small);

  // Prevent too much padding even when one item has lots of space
  & > div:global(.productWidestMode),
  & > div:global(.productWiderMode) {
    padding-top: var(--sg-spacing-big);
    padding-bottom: var(--sg-spacing-big);
  }
  
  :global(.waterTag) > :global(.productImages) {
    // Hack to reduce margin for water bottles in product listing (but not when
    // they are listed as the mainProduct in the product pages.
    margin: -10px 0 -20px 0;
  }
}

.mainProduct {
  margin-bottom: var(--sg-spacing-normal);
}

.relatedProductsCard {
  align-items: flex-start;
  text-align: left;

  @include mq_notNarrow {
    flex: 0 1 var(--flex-basis-dispensers);
  }
  
  h4 + p {
    margin-top: 5px;
  }
}

// Info displayed when the product is in its own page
.additionnalInfo {
  .legalese {
    margin: 13px 0 13px 0;
    padding: 11px 0 11px 0;
    border-top: 1px solid var(--grey-200);
    border-bottom: 1px solid var(--grey-200);
    @include sgSubpanelTitle;
    line-height: normal;
  }

  a {
    @include sgSubpanelTitle;
    color: var(--secondary-gold-600);

    svg {
      vertical-align: middle;
      margin-bottom: 2px;
      margin-right: 7px;
      :global(.svg-shape) {
        fill: var(--secondary-gold-600);
      }
    }
  }
}
