@import "../styles/mq.scss";
@import "../styles/sg.scss";

// NB: "--image-padding" and "--default-gap" variables are required.

.image {
  position: absolute;
  top: var(--image-padding);
  bottom: var(--image-padding);
  left: var(--image-padding);
  right: var(--image-padding);
}

.images {
  margin: auto;
}

.box {
  position: relative;
  width: 100%;
  border-radius: 8px;
  
  &.main {
    padding-bottom: 100%; // Hack to get height equal to width
    --image-padding: 20px;
  }

  &.withBorder {
    border: 1px solid transparent;
  }

  &.withBackground {
    background-color: var(--white);
  }
}

.main + .thumbnails {
  margin-top: var(--default-gap);
}

.thumbnails {
  // The variable "--number-of-thumbnails-per-line" must be defined
  display: flex;
  flex-wrap: wrap; // wrap when there are more than --number-of-thumbnails-per-line
  gap: var(--default-gap);

  --image-padding: 3px; // Redefine image-padding for all thumbnails
  --number-of-gaps: calc(var(--number-of-thumbnails-per-line) - 1);
  --summed-gaps: calc(var(--number-of-gaps) * var(--default-gap));

  .thumbnail {
    // Square, width width/height dependent on parent width and number of thumbnails
    --computed-width: calc((100% - var(--summed-gaps)) / var(--number-of-thumbnails-per-line));
    width: var(--computed-width);
    padding-bottom: var(--computed-width); // Hack to get height equal to width
  }
  .withBorder.selected {
    border-color: var(--grey-200); 
  }
}
