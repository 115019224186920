@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";

:root {
  --header-height-mobile: 65px;
}

.module {
  width: 100%;
  height: var(--header-height-mobile);
  user-select: none;
  position: relative; // For the buttons which are absolutely positionned

  background-color: var(--white);

  border-bottom: 1px solid var(--grey-100);
}

.logo {
  position: absolute;
  left: 16px;
  top: 13px;
  cursor: pointer;
}

.trigger {
  & > div {
    position: absolute;
    right: 7px;
    top: 7px;

    width: 50px;
    height: 50px;
  }

  & > div.cartSummary {
    right: 50px;
    top: 16px;
    display: block;
  }
}

.expanded .trigger .line {
  background: #0080FF;
}

.navigation {
  display: none;

  .loggedInAs {
    text-align: center;

    .title {
      display: block;
      color: #999;
      @include sgTextFootnote;
    }
    .name {
      display: block;
      @include sgTextNormal;
      @include sgFontBold;
      margin: 0;
    }
  }

  .divider {
    width: 100% !important;
    border-top: 1px solid var(--grey-100);
  }

  .button:global(.Button):global(.themeButton) {
    font-size: 18px;
    text-transform: uppercase;
    color: var(--grey-900);
    background-color: var(--grey-100);

    &:hover {
      background-color: var(--primary-blue-100);
      color: var(--primary-blue-500);
    }
    &:active {
      background-color: var(--primary-blue-800);
      color: var(--white);
    }
  }

  & > * {
    width: calc(100% - 48px) !important;
    margin-top: var(--sg-spacing-small);
  }
}

.expanded .navigation {
  // Takes all available space below the header itself
  position: fixed;
  left: 0;
  right: 0px;
  top: var(--header-height-mobile);
  bottom: 0;
  background-color: #fff;
  box-shadow: 0px 3px 3px rgba(0,0,0,0.2);

  overflow-y: scroll;
  overflow-x: hidden;

  background-color: var(--white);

  display: flex;
  flex-direction: column;
  align-items: center;
}


.slogan {
  text-transform: uppercase;
  font-size: 16px;
  @include sgFontBold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 2.73px;
  text-align: center;
  color: var(--primary-blue-800);
}

.socialButtons {
  margin: 21px auto;
  text-align: center;

  a {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: 24px 48px;
    background-repeat: no-repeat;
    background-position: 0px -24px;  
    margin: 0px var(--sg-spacing-tiny);

    span {
      display: none;
    }
  }

  .facebook {
    @include backgroundImageWithRetina("/assets/icons/social-facebook", "png");
  }

  .instagram {
    @include backgroundImageWithRetina("/assets/icons/social-instagram", "png");
  }

  .linkedin {
    @include backgroundImageWithRetina("/assets/icons/social-linkedin", "png");
  }
}

.copyright {
  @include sgFontNormal;
  @include sgTextSmall;
  text-align: center;
}

.SystemVersion {
  background-color: #FB2804;
  text-align: center;
  padding: 5px 5px 3px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;  
}