@import "./shared/styles/sg.scss";
@import "./shared/styles/mq.scss";

.module {
  min-height: 100vh;
  width: 100%;
  padding: 0 var(--sg-spacing-normal);

  @include putInCenter;

  // Take full screen even if error occurs in smaller component
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--white);
  
  z-index: 1;

  div {
    @include mq_notNarrow {
      max-width: 460px;
    }

    h1 {
      color: var(--primary-blue-700);
    }
  
    p {
      @include sgTextNormal;
      color: var(--grey-900);

      &.debugInfo pre {
        color: var(--accent-red-500);
        overflow: hidden;
        white-space: pre-wrap;
        overflow-wrap: break-word;

        span:after {
          content: ' ';
        }
      }
    }

    button {
      min-width: 233px;
    }
  }
}