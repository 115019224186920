@import "../../../shared/styles/sg.scss";

.OrderSummary {
  font-size: 16px;
  line-height: 1.8em;
}

.OrderSummary .title {
  margin-bottom: 1em;
  font-weight: 500;
}

.OrderSummary table {
  border-collapse: collapse;
  width: 100%;
}

.OrderSummary table hr {
  border: none;
  border-bottom: 1px solid var(--grey-200);
}

.OrderSummary table .large-separator hr {
  border-color: var(--grey-200)
}
.OrderSummary table .order-total {
  @include sgFontBold;
}
.OrderSummary table tr td:last-child {
  text-align: right;
}

.OrderSummary table tr td .Loading {
  display: inline-block;
}

.OrderSummary {
  .title {
    @include sgTextNormal;
    @include sgFontBold;
    margin-bottom: var(--sg-spacing-smaller);
  }

  .header {
    display: none;
  }

  .Table.mode-table {
    thead {
      border-bottom: 1px solid var(--grey-200);

      tr {
        font-size: 11px;
      }
    }
    
    tr.item {
      font-size: 14px;
      background-color: transparent;

      td {
        border: none;
        padding: 7px 0;
      }

      td.f-price.unit .currency {
        display: none;
      }
    }

    &.OneTimeOrderCartList {
      padding-bottom: var(--sg-spacing-normal);
      border-bottom: 1px solid var(--grey-200);
    }
  }
}