@import "../../../shared/styles/sg.scss";

.module {
  color: var(--grey-700);

  :global(.Button) {
    margin-top: var(--sg-spacing-tiny);
  }
}

.inner {
  display: block !important;
}

.labels {
  display: block !important;
}

.item + .item {
  margin-top: var(--sg-spacing-small);
}

.item > .header {
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--grey-200);
}

.item .info {
  padding: 10px 5px;
  flex: 1 1 100%;
  @include sgTextTiny;
  margin: 0;

  color: var(--grey-700);
  line-height: 1.5;
}

.item .expander,
.item .count {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 35px;
  border-left: 1px solid var(--grey-200);
}

.item .expander .icon {
  width: 10px;
  height: 10px;
  border-right: 2px solid var(--grey-900);
  border-bottom: 2px solid var(--grey-900);
  transform: rotate(45deg);
  transition: transform 0.5s ease-out;
}
.item.uSelected .expander .icon {
  transform: rotate(225deg);
}

.item > .header {

  .status {
    display: inline-block;
    font-family: "AbelPro-Bold";
    margin-right: 0.5em;
    margin-bottom: 3px;
  }

  .deliveryTag {
    display: block;
    width: fit-content;
  }

  .name {
    @include sgFontBold;
    @include sgTextTiny;
    margin: 0;
    margin-top: var(--sg-spacing-tiny);
  }

  .date {
    display: inline-block;

    .prefix {
      @include sgSubpanelTitle;
      margin-right: 5px;
    }
  }
}

.item.uComplimentary .complimentaryNotice,
.item.uGift .giftNotice {
  @include sgSubpanelTitle;
  display: flex;
}
.item .complimentaryNotice,
.item .giftNotice {
  display: none;
}

.item > .body .noTrackingNotice {
  @include sgSubpanelTitle;
  display: flex;
}
.item.uTracking > .body .noTrackingNotice {
  display: none;
}

.item > .body {
  display: none;
  user-select: none;

  @include sgTextTiny;

  padding-top: 10px;

  & > div {
    border-top: 1px solid var(--grey-200);
    padding: var(--sg-spacing-tiny) 0;
    min-height: 38px;
    align-items: center;

    &:last-child {
      border-bottom: 1px solid var(--grey-200);
    }

    &.refNumber {
      --color: var(--secondary-gold-600);
      @include sgSubpanelTitle;

      display: flex;
      justify-content: space-between;
      color: var(--color);

      svg {
        margin-top: 2px;
        flex-basis: 40px;

        path {
          fill: var(--color);
        }
      }
    }
  }
}


.item.uSelected > .body {
  display: block;
}

.item > .body .footer {
  font-size: 12px;
  color: #aaa;
  border-top: 1px solid var(--grey-200);
  padding: 5px 5px;
}