@import "../../../shared/styles/sg.scss";
@import "../../../shared/styles/mq.scss";

.refreshAddresses {
  @include mq_notNarrow {
    margin-top: var(--sg-spacing-small);
  }
  @include mq_narrow {
    margin-top: var(--sg-spacing-smaller);
    text-align: center;
  }

  button.refreshLink {
    display: inline-block !important;
    margin: 0 !important;
    min-width: 0 !important;
    width: auto !important;
  }
}

.infoNotCompleted {
  color: var(--accent-red-500);
}

.giftBox {
  background-color: var(--primary-blue-100);

  margin-top: var(--sg-spacing-smaller);
  margin-left: -14px;
  margin-right: -14px;
  margin-bottom: -8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  padding: 20px 16px 13px 16px;

  .question {
    color: var(--primary-blue-700);
  }

  :global(.giftMessageForm) {
    margin-top: var(--sg-spacing-small);
    color: var(--grey-700);

    :global(.label) {
      @include sgTextTiny;
      @include sgFontBold;
    }

    textarea {
      height: 200px;
      @include sgTextNormal;
      @include sgFontNormal;
      line-height: 1.2;
      margin: 0;
      padding: 18px var(--sg-spacing-small);
      background-color: var(--white);
      color: var(--grey-600);
      resize: none;
    }

    :global(.giftMessageInfo) {
      margin-top: var(--sg-spacing-tiny);
      font-size: 12px;
      margin: 0;
    }

    :global(.buttons) {
      margin-top: calc(var(--sg-spacing-tiny) * -1);
      & > * {
        margin-top: var(--sg-spacing-tiny);
      }

      button {
        display: inline-block;
        margin-right: var(--sg-spacing-smaller);
        width: auto;
      }

      :global(.notif) {
        display: inline-block;

        line-height: 32px;
        white-space: nowrap;
        color: var(--accent-green-600);
        
        svg {
          margin-top: -2px;
          margin-right: 6px;
          vertical-align: middle;

          path {
            fill: var(--accent-green-600);
          }
        }
      }
    }
  }
}

.giftWarning {
  border-radius: 4px;
  margin-top: -7px;
  margin-bottom: 9px;
  padding: 4px 5px;

  background-color: var(--secondary-gold-100);
  color: var(--secondary-gold-600);

  display: flex;
  svg + div {
    margin-left: 6px;
  }
  div {
    margin-top: 2px;
  }
  svg path {
    fill: var(--secondary-gold-600);
  }
}
