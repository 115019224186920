.WarningMessage {
  position: relative;
  display: flex;
  align-items: center;
}

.WarningMessage .BackgroundImage {
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
}

.WarningMessage.u-with-icon {
  padding-left: 20px;
}
.WarningMessage.u-with-icon .BackgroundImage {
  display: block;
}

.WarningMessage p {
  margin: 0px;
  line-height: 1.2em;
}

.WarningMessage p .title {
  font-family: "AbelPro-Bold";
}