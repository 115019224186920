.OnboardingPage .centralIcon {
  margin-top: 5px;
}
.OnboardingPage .view > .inner {
  padding: 0 var(--sg-spacing-small);
  color: var(--white);
}
.OnboardingPage .view > .inner h4 {
  margin-top: 20px;
}

.OnboardingPage .preloadGraphics {
  display: none;
}

.OnboardingPage .MonitorRegistration .Loading {
  margin: auto;
}

.OnboardingPage .link {
  color: var(--primary-blue-700);
  pointer-events: all;
  cursor: pointer;
  text-decoration: underline;
}