.ModifyBillingFormContainer .Button {
  display: block;
  text-align: center;
  max-width: 200px;
  margin: 1em auto;
}

.ModifyBillingFormContainer .helptext {
  padding: 1em 20px 1em;
  color: #999;
}