
@import "../shared/styles/mq.scss";
@import "../shared/styles/sg.scss";

:root {
  --shop-footer-height: 56px;

  @include mq_narrow {
    --shop-footer-height: 72px;
  }
}

.module {
  --has-desktop-header: 0;
  --has-mobile-header: 0;
  --header-height: 0px;
  &.layoutMobile {
    --has-mobile-header: 1;
    --header-height: 65px;
  }
  &.layoutDesktop {
    --has-desktop-header: 1;
    --header-height: 72px;
    
    &.withSubmenu {
      --header-height: 130px;
    }
  }

  display: block;
  @include mq_narrow {
    // Hide content when modal is active to prevent scrollbars and see through
    :global(.App):global(.hasActiveModal) & {
      display: none;
    }
  }

  // Invisible banner must compensate layout inner padding
  .invisibleBanner {
    margin: 0 calc(var(--sg-spacing-small) * -1);
    @include mq_notNarrow {
      margin: 0 calc(var(--sg-spacing-normal) * -1);
    }
    visibility: hidden;
  }
}

.header {
  position: fixed;
  z-index: 2; // Otherwise some absolute elements will show through
  top: 0;
  // 100vw so we don't care about the presence of the scroll bar, keeping the
  // position consistent always
  width: 100vw;
}

.inner {
  width: 100%;
  max-width: var(--sg-desktop-max-width);
  margin: auto;

  margin-bottom: var(--shop-footer-height);

  overflow-y: visible; // Necessary for sticky summary to work
  padding: var(--sg-spacing-small);
  @include mq_notNarrow {
    padding: var(--sg-spacing-normal);
  }
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  .layoutMobile & {
    margin-top: var(--header-height-mobile);

    --margins-total: calc(var(--header-height-mobile) + var(--shop-footer-height));
    min-height: calc(100vh - var(--margins-total));
  }
  .layoutDesktop & {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .layoutDesktop.showHeader & {
    top: var(--header-height-desktop);
  }
  .layoutDesktop.showFooter & {
    bottom: var(--shop-footer-height);
    margin-bottom: 0px;
  }
  .withSubmenu & {
    margin-top: var(--account-header-height-desktop);
  }
  .layoutDesktop.withSubmenu & {
    top: var(--account-header-height-desktop);
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .noWhiteSpaceAround & {
    margin: 0;
    padding: 0;
  }

  display: block;
  @include mq_narrow {
    // Hide inner content when menu is active to prevent see through on mobile
    :global(.hasMenuOpen) & {
      display: none;
    }
  }
}


// .showFooter > .inner {
//   bottom: var(--shop-footer-height);
// }

.footer {
  position: fixed;
  width: 100%;
  height: var(--shop-footer-height);
  left: 0px;
  bottom: 0px;
}

.preloadGraphics {
  display: none;
}