@import "../../shared/styles/mq.scss";
@import "../../shared/styles/sg.scss";

.module {
  text-align: center;
  margin: auto;
  width: 100%; // Avoid content being bigger than module

  // For consistency: icon should always be 56px wide
  & > svg {
    width: 56px;
    height: 56px;
    
    margin: var(--sg-spacing-tiny) 0;
  }

  & > h1 {
    margin-top: 0;
    @include sgTextLarge;
    margin: var(--sg-spacing-small) 0 var(--sg-spacing-normal);

    &:global(.sg-guide-header-title) {
      margin: 0 0 var(--sg-spacing-tiny) 0;
    }
  }
  & > p {
    overflow-wrap: break-word;
  }

  & > div:global(.Loading) {
    margin: auto;
    margin-top: var(--sg-spacing-tiny);
    margin-bottom: var(--sg-spacing-small);
  }

  button {
    margin-top: 6px;
    width: 100%;
  }

  @include mq_narrow {
    // Make sure the message takes all the width minus normal mobile padding
    // And it should always be centered according to the viewport
    @include putInCenterOfViewport;
    flex-direction: column;
    width: calc(100vw - (var(--sg-spacing-small) * 2)) !important;
    margin-left: var(--sg-spacing-small);
  }
}

:global(.u-centered) .module {
  // Exception to viewport centering small message when parent is centered
  // as other content might be displayed around it.
  @include mq_narrow {
    position: inherit;
    height: inherit;
    margin: 0;
    width: 100% !important;
  }
}
