@import "../../../shared/styles/mq.scss";
@import "../../../shared/styles/sg.scss";
@import "../../shared/Stripe.scss";

.module {

  :global(.CreditCardPaymentMethod) > div:first-child {
    margin-bottom: var(--sg-spacing-small);
    border-color: var(--primary-blue-700);
  }
}

.refreshPaymentMethods {
  @include mq_notNarrow {
    margin-top: var(--sg-spacing-small);
  }
  @include mq_narrow {
    margin-top: var(--sg-spacing-smaller);
    text-align: center;
  }

  .refreshLink {
    margin: 0;
    min-width: 0;
  }
}

.stripeElement {
  @include sgTextSmall;
  margin-top: var(--sg-spacing-small);

  span.icon {
    margin-top: var(--sg-spacing-smaller);
    @include StripeIcon;
    padding-bottom: 0;
    
    display: block;
    width: 115px;
    height: 33px;
  }

  @include mq_narrow {
    display: none;
  }
}